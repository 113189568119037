import { ImageType } from "@/types";
import { ResponsiveImageType } from "react-datocms";

// DatoCMS GraphQL page records
export const PAGE_RECORDS = {
  home: "PageHomeRecord",
  select: "PageSelectRecord",
  publish: "PagePublishRecord",
  pricing: "PagePricingRecord",
  upgrade: "PageUpgradeRecord",
  download: "PageDownloadRecord",
  changelog: "PageChangelogRecord",
  blog: "PageBlogRecord",
  blogPost: "PageBlogPostRecord",
  lesson: "PageLessonRecord",
  lessonHolder: "PageLessonHolderRecord",
  partnership: "PagePartnershipRecord",
  partnershipHolder: "PagePartnershipHolderRecord",
  about: "PageAboutRecord",
  career: "PageCareerRecord",
  job: "PageJobRecord",
  generic: "PageGenericRecord",
};

export const formatImageData = ({
  image,
  removeBase64 = true,
}: {
  image: ImageType;
  removeBase64?: boolean;
}): ResponsiveImageType =>
  image.responsiveImage
    ? {
        ...image.responsiveImage,
        base64: removeBase64 ? "" : image.responsiveImage.base64,
      }
    : {
        src: image.url,
        aspectRatio: image.width / image.height,
        ...image,
      };
