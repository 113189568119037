import { PlanCard } from "../PlanCard";
import {
  ProductName,
  ProductInterval,
  ProductStatus,
  IProductObject,
  IPlanObject,
  IPlanFeature,
  useProjectsPublishQuery,
} from "@/api";
import { CancelOptionsModal, ConfirmCancelModal } from "@/components";
import {
  PUBLISH_PARKING_PLAN_ID,
  PUBLISH_TRIAL_PLAN_ID,
  ROUTES,
} from "@/config";
import { DataHelper } from "@/helpers";
import { useCreateOrUpdateProduct } from "@/hooks";
import {
  useAccountContext,
  useAlertDispatch,
  useModalDispatch,
} from "@/providers";
import { capitalize, numberToCurrency, isSaleActive } from "@/utils";
import {
  Button,
  Spinner,
  Pricing,
  AlertAction,
  AlertActionType,
  ModalActionType,
} from "@narrative-software/narrative-web-ui";
import moment from "moment";
import Image from "next/image";
import { useRouter } from "next/router";
import { Dispatch } from "react";

const PUBLISH_PLAN_CARD_PROPS_COMMON = {
  planName: ProductName.Publish,
  description:
    "Build beautiful blogs that blend seamlessly into your website, load fast and look great on every device.",
  iconFillClassName: "fill-slowpoke",
} as const;

export interface ICoupon {
  id: string;
  percent_off: number;
}

// Black Friday Coupons
const COUPONS: Record<ProductInterval, ICoupon> = {
  year: {
    id: "bf_2021_publish_50_2",
    percent_off: 0.5,
  },
  month: {
    id: "bf_2021_publish_30_2",
    percent_off: 0.3,
  },
};

const arePostsWithinLimit = (
  publishedPosts: number,
  maxPosts: number | null
) => {
  // If maxPosts === null, it means there’s no post limit
  if (maxPosts === null) return true;
  return publishedPosts <= maxPosts;
};

const triggerPostLimitExceededAlert = (
  alertDispatch: Dispatch<AlertAction>
) => {
  alertDispatch({
    type: AlertActionType.SetContent,
    payload: {
      type: "error",
      title: "Published posts exceed plan limit",
      children: (
        <p>
          You have too many published posts to change to this plan.{" "}
          <a
            href="https://help.narrative.so/en/articles/2079995-publish-you-have-reached-the-maximum-number-of-blogs-you-can-publish-on-your-current-plan"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Learn more
          </a>
        </p>
      ),
    },
  });
};

type Props = {
  plans: IPlanObject[];
  existingProduct?: IProductObject;
};

const PlanCardPublish: React.FC<Props> = ({ plans, existingProduct }) => {
  const router = useRouter();
  const alertDispatch = useAlertDispatch();
  const modalDispatch = useModalDispatch();
  const { accountID } = useAccountContext();
  const [
    doCreateOrUpdateProduct,
    { isLoading: isSendingCreateOrUpdateProduct },
  ] = useCreateOrUpdateProduct();
  const { data: projectsData, isLoading: isLoadingProjects } =
    useProjectsPublishQuery({
      productID: existingProduct?.id || "",
    });

  const productName = ProductName.Publish;
  const existingPlan =
    existingProduct &&
    DataHelper.getPlanById(existingProduct.relationships.plan.data.id, plans);
  const isStatusCanceled =
    existingProduct?.attributes.status === ProductStatus.Canceled;
  const isParkingPlan =
    existingPlan?.id === PUBLISH_PARKING_PLAN_ID && !isStatusCanceled;
  const isTrialPlan =
    existingPlan?.attributes["default-free"] && !isStatusCanceled;
  const publishedPosts = projectsData?.meta["published-projects"];

  // Move trial plan to start of array
  const trialPlanIndex = plans.findIndex(
    ({ attributes }) => attributes["default-free"]
  );
  if (trialPlanIndex) {
    plans.unshift(plans.splice(trialPlanIndex, 1)[0]);
  }

  // Plan selector
  const planSelector = (
    <section className="px-5 pt-14 pb-8 lg:pt-16 lg:pb-0">
      <Pricing {...{ productName, existingProduct }}>
        {plans
          // Remove parking plan and some other dank plans in there
          .filter(
            ({ id }) =>
              id !== PUBLISH_PARKING_PLAN_ID &&
              id !== "b0c9ac62-fd70-4105-8dd6-16c3080aee6f" &&
              id !== "2db637e8-fa0c-4f0e-9645-a4da83cc9bff"
          )
          .map(({ id, attributes }) => {
            const maxPosts = attributes.features.find(
              ({ slug }) => slug === "post-count"
            )?.quantity;
            const showCurrentPlanTrial =
              (attributes["default-free"] && isTrialPlan) || undefined; // If trial, show 'current plan' for both intervals
            const features = {
              yearly: [
                {
                  name: "Total number of posts",
                  slug: "post-count",
                  quantity: maxPosts || "Unlimited",
                  "singular-noun": "Published Post",
                  "plural-noun": "Published Posts",
                  description:
                    "The total number of posts that can be published at once. This does not renew monthly or yearly.",
                },
                {
                  name: "Export posts as JPEGs",
                  slug: "export-jpg",
                  description: "Save your post as individual JPEG files.",
                  active: !attributes["default-free"],
                },
                {
                  name: "Export image collages",
                  slug: "export-collage",
                  description:
                    "Save sections of your post to publish on social media.",
                  active: !attributes["default-free"],
                },
                {
                  name: "SEO integration",
                  slug: "seo-integration",
                  description:
                    "Maximize your SEO gains with our easy-to-use SEO tools.",
                },
                {
                  name: "Design offline",
                  slug: "design-offline",
                  description:
                    "Build posts anywhere, any time. Publish works offline.",
                },
                {
                  name: "Text editor",
                  slug: "text-editor",
                  description:
                    "Add text to your blog post that is formatted to match your website’s style when Published.",
                },
                {
                  name: "Pinterest and Facebook plugins",
                  slug: "social-plugins",
                  description:
                    "Help your work get seen by adding Pinterest share buttons and Facebook comments.",
                },
              ],
              monthly: [
                {
                  name: "Total number of posts",
                  slug: "post-count",
                  quantity: maxPosts || "Unlimited",
                  "singular-noun": "Published Post",
                  "plural-noun": "Published Posts",
                  description:
                    "The total number of posts that can be published at once. This does not renew monthly or yearly.",
                },
                {
                  name: "Export image collages",
                  slug: "export-collage",
                  description:
                    "Save sections of your post to publish on social media.",
                  active: !attributes["default-free"],
                },
                {
                  name: "SEO integration",
                  slug: "seo-integration",
                  description:
                    "Maximize your SEO gains with our easy-to-use SEO tools.",
                },
                {
                  name: "Design offline",
                  slug: "design-offline",
                  description:
                    "Build posts anywhere, any time. Publish works offline.",
                },
                {
                  name: "Text editor",
                  slug: "text-editor",
                  description:
                    "Add text to your blog post that is formatted to match your website’s style when Published.",
                },
                {
                  name: "Pinterest and Facebook plugins",
                  slug: "social-plugins",
                  description:
                    "Help your work get seen by adding Pinterest share buttons and Facebook comments.",
                },
              ],
            };

            // Handle plan click
            const handlePlanClick = async ({
              planID,
              interval,
            }: {
              planID: string;
              interval: ProductInterval;
            }) => {
              try {
                // If published posts are within the new plans limit
                if (
                  maxPosts !== undefined &&
                  publishedPosts !== undefined &&
                  !arePostsWithinLimit(publishedPosts, maxPosts)
                ) {
                  triggerPostLimitExceededAlert(alertDispatch);
                  return;
                }
                if (planID === PUBLISH_TRIAL_PLAN_ID) {
                  // If a user clicked the trial plan
                  await doCreateOrUpdateProduct({
                    accountID,
                    planID,
                    interval,
                    product: ProductName.Publish,
                    productID: existingProduct?.id,
                  });
                } else {
                  await router.push({
                    pathname: `/${ROUTES.CHECKOUT.SLUG}`,
                    query: {
                      planID,
                      interval,
                      ...(isSaleActive() && isTrialPlan
                        ? { fp_ref: COUPONS[interval].id }
                        : {}),
                    },
                  });
                }
                modalDispatch({ type: ModalActionType.Close });
              } catch {}
            };

            return (
              <Pricing.Item
                key={id}
                id={id}
                monthlyPrice={attributes["monthly-price"]}
                yearlyPrice={attributes["yearly-price"]}
                monthlyDiscount={
                  isTrialPlan && isSaleActive()
                    ? COUPONS["month"].percent_off
                    : undefined
                }
                yearlyDiscount={
                  isTrialPlan && isSaleActive()
                    ? COUPONS["year"].percent_off
                    : undefined
                }
                features={
                  features as {
                    yearly: IPlanFeature[];
                    monthly: IPlanFeature[];
                  }
                }
                isCurrentPlan={showCurrentPlanTrial}
                onClick={handlePlanClick}
              >
                {attributes.name}
              </Pricing.Item>
            );
          })}
      </Pricing>
    </section>
  );

  // Open the plan selector in a modal
  const openPlanModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        size: "large",
        hideBorder: true,
        showOverflow: true,
        children: planSelector,
      },
    });
  };

  // Get unsubscribed plan card
  const getUnsubscribedPlanCard = () => {
    const handleClick = async () => {
      try {
        await doCreateOrUpdateProduct({
          accountID,
          planID: PUBLISH_TRIAL_PLAN_ID,
          interval: ProductInterval.Month,
          product: ProductName.Publish,
          productID: existingProduct?.id,
        });
        if (existingProduct) return;
        await router.push(`/${ROUTES.PUBLISH.DOWNLOAD.SLUG}`);
      } catch {}
    };

    const buttonPrimary = (
      <Button
        colour="slowpoke"
        appearance="primary"
        onClick={handleClick}
        isLoading={isSendingCreateOrUpdateProduct}
        showLoader
      >
        Try Publish free
      </Button>
    );

    return (
      <PlanCard
        {...PUBLISH_PLAN_CARD_PROPS_COMMON}
        buttonPrimary={buttonPrimary}
        image={
          <div className="mt-6">
            <Image
              src="/images/narrative-publish@2x.webp"
              alt="Narrative Publish"
              width="230"
              height="135"
            />
          </div>
        }
      />
    );
  };

  /**
   * Unsubscribed
   */
  if (!existingPlan || !existingProduct) {
    return getUnsubscribedPlanCard();
  }

  // Open the confirm cancel modal
  const openConfirmModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <ConfirmCancelModal
            product={existingProduct}
            plan={existingPlan}
            parkingPlanID={PUBLISH_PARKING_PLAN_ID}
          />
        ),
      },
    });
  };

  // Open the cancel options modal
  const openOptionsModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <CancelOptionsModal
            product={existingProduct}
            plan={existingPlan}
            parkingPlanID={PUBLISH_PARKING_PLAN_ID}
          />
        ),
      },
    });
  };

  // Values for plan card
  const {
    attributes: { name: planName, features },
  } = existingPlan;

  const {
    attributes: { interval },
  } = existingProduct;

  const index = `${interval}ly-price` as "yearly-price" | "monthly-price";
  const price = `${numberToCurrency(
    existingPlan.attributes[index]
  )} USD/${interval}`;
  const nextPayment = moment
    .unix(existingProduct.attributes["current-period-end"])
    .format("D MMM YYYY");
  const maxPostLimit = features.find(
    ({ slug }) => slug === "post-count"
  )?.quantity;
  const coupon = existingProduct.attributes.coupon?.name || "-";
  const discount = existingProduct.attributes.coupon?.["percent-off"]
    ? `${existingProduct.attributes.coupon["percent-off"]}% off`
    : "-";
  const status =
    existingProduct.attributes.status === ProductStatus.IncompleteExpired
      ? "Payment method expired"
      : capitalize(existingProduct.attributes.status).replace(/_/g, " ");

  let postCount = "-";
  if (publishedPosts !== undefined) {
    if (maxPostLimit === undefined || maxPostLimit === 0) {
      postCount = `${publishedPosts}`;
    } else if (maxPostLimit === null) {
      postCount = `${publishedPosts} of unlimited`;
    } else {
      postCount = `${publishedPosts} of your ${maxPostLimit} post limit`;
    }
  }

  const couponAndDiscountItems = (
    <>
      <PlanCard.Item term="Coupon">{coupon}</PlanCard.Item>
      <PlanCard.Item term="Discounts & rewards">{discount}</PlanCard.Item>
      <PlanCard.Item term="Published posts">
        {isLoadingProjects ? <Spinner size={4} /> : postCount}
      </PlanCard.Item>
    </>
  );

  const buttonSecondary = (
    <button type="button" className="underline" onClick={openOptionsModal}>
      Cancel plan
    </button>
  );

  // Conditionally render by plan type
  switch (true) {
    /**
     * Paid
     */
    case existingProduct &&
      !isParkingPlan &&
      !isTrialPlan &&
      !isStatusCanceled: {
      const buttonPrimary = (
        <Button
          colour="gray-600"
          appearance="secondary"
          onClick={openPlanModal}
        >
          Change plan
        </Button>
      );

      return (
        <PlanCard
          {...PUBLISH_PLAN_CARD_PROPS_COMMON}
          buttonPrimary={buttonPrimary}
          buttonSecondary={buttonSecondary}
        >
          <PlanCard.Item term="Your plan">{planName}</PlanCard.Item>
          <PlanCard.Item term="Price">{price}</PlanCard.Item>
          <PlanCard.Item term="Subscription status">{status}</PlanCard.Item>
          <PlanCard.Item term="Next payment">{nextPayment}</PlanCard.Item>
          {couponAndDiscountItems}
        </PlanCard>
      );
    }

    /**
     * Trial
     */
    case isTrialPlan: {
      const buttonPrimary = (
        <Button colour="slowpoke" appearance="primary" onClick={openPlanModal}>
          Upgrade now
        </Button>
      );

      return (
        <PlanCard
          {...PUBLISH_PLAN_CARD_PROPS_COMMON}
          buttonPrimary={buttonPrimary}
          buttonSecondary={buttonSecondary}
        >
          <PlanCard.Item term="Your plan">{planName}</PlanCard.Item>
          <PlanCard.Item term="Price">FREE</PlanCard.Item>
          <PlanCard.Item term="Subscription status">{status}</PlanCard.Item>
          {couponAndDiscountItems}
        </PlanCard>
      );
    }

    /**
     * Parking
     */
    case isParkingPlan: {
      const price = `$10 USD/year`;

      const buttonPrimary = (
        <Button colour="slowpoke" appearance="primary" onClick={openPlanModal}>
          Upgrade to use Publish
        </Button>
      );
      const buttonSecondary = (
        <button type="button" className="underline" onClick={openConfirmModal}>
          Cancel
        </button>
      );

      return (
        <PlanCard
          {...PUBLISH_PLAN_CARD_PROPS_COMMON}
          buttonPrimary={buttonPrimary}
          buttonSecondary={buttonSecondary}
        >
          <PlanCard.Item term="Your plan">{planName}</PlanCard.Item>
          <PlanCard.Item term="Price">{price}</PlanCard.Item>
          <PlanCard.Item term="Subscription status">{status}</PlanCard.Item>
          <PlanCard.Item term="Next payment">{nextPayment}</PlanCard.Item>
          {couponAndDiscountItems}
        </PlanCard>
      );
    }

    /**
     * Cancelled
     */
    case isStatusCanceled: {
      const activationDate = Math.round(
        new Date(existingProduct.attributes["activation-date"]).getTime() / 1000
      );
      const unixTimeInSeconds = Math.round(new Date().getTime() / 1000);
      const secondsTillActivationDate = activationDate - unixTimeInSeconds;
      const daysRemaining = Math.round(
        secondsTillActivationDate / 60 / 60 / 24
      );
      const subscriptionStatus = `${status} – ${
        daysRemaining > 0 ? daysRemaining : 0
      } day${daysRemaining === 1 ? "" : "s"} to reactivate`;

      const buttonPrimary = (
        <Button colour="slowpoke" appearance="primary" onClick={openPlanModal}>
          Reactivate
        </Button>
      );

      return (
        <PlanCard
          {...PUBLISH_PLAN_CARD_PROPS_COMMON}
          buttonPrimary={buttonPrimary}
        >
          <PlanCard.Item term="Your plan">{planName}</PlanCard.Item>
          <PlanCard.Item term="Price">{price}</PlanCard.Item>
          <PlanCard.Item term="Subscription status">
            {subscriptionStatus}
          </PlanCard.Item>
          <PlanCard.Item term="Next payment">-</PlanCard.Item>
          {couponAndDiscountItems}
        </PlanCard>
      );
    }

    /**
     * Unsubscribed
     */
    default: {
      return getUnsubscribedPlanCard();
    }
  }
};

export default PlanCardPublish;
