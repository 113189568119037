import { LayoutPublic } from "@/containers";
import { Spinner } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";

type Props = {
  addLayout?: boolean;
  fullHeight?: boolean;
  className?: string;
};

const LoadingScreen: React.FC<Props> = ({
  addLayout,
  fullHeight,
  className,
}) => {
  const Loader = (
    <div
      className={classNames("flex items-center justify-center", className, {
        "min-h-screen": fullHeight,
      })}
    >
      <Spinner size={12} />
    </div>
  );

  if (addLayout) return <LayoutPublic>{Loader}</LayoutPublic>;

  return Loader;
};

export default LoadingScreen;
