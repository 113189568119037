import { ProductName } from "@/api";
import { Product } from "@/types";
import { colour } from "@narrative-software/narrative-web-ui";

const { COLOUR_PALETTE } = colour;

/**
 * Capitalize a string
 */
export const capitalize = (string: string) =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1);

/**
 * Product utils
 */
export const productCapitalized = (product: string) =>
  capitalize(product) as ProductName;
export const productLowerCase = (product: ProductName) =>
  product.toLowerCase() as Product;

/**
 * Get a hex value from our UI library colour palette, by class name
 */
export const getHexValueByClassName = (className: string) => {
  const colourObj = Object.values(COLOUR_PALETTE).find(
    (item) => item.CLASS_NAME === className
  );
  return colourObj?.HEX;
};

/**
 * Build a URL
 */
export const createUrl = (baseUrl: string, params?: Record<string, string>) => {
  const url = new URL(baseUrl);
  if (params) {
    url.search = new URLSearchParams(params).toString();
  }
  return url.toString();
};

/**
 * Recursively remove null, undefined and empty string values from an object or array
 * @param param | Object or Array to clean
 */
export const omitEmpty = (param: any[] | Record<string | number, any>): any => {
  if (Array.isArray(param)) {
    return param
      .map((v) => (v && typeof v === "object" ? omitEmpty(v) : v))
      .filter((v) => !(v == null || v === ""));
  } else {
    return Object.entries(param)
      .map(([k, v]) => [k, v && typeof v === "object" ? omitEmpty(v) : v])
      .reduce(
        (a: Record<string | number, any>, [k, v]) =>
          v == null || v === "" ? a : ((a[k] = v), a),
        {}
      );
  }
};
