import { TextField, TextFieldProps } from "@material-ui/core";

type Props = TextFieldProps & {
  options: React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >[];
  addEmpty?: boolean;
};

const SelectField: React.FC<Props> = ({ options, addEmpty, ...props }) => {
  const hasEmpty = addEmpty || options.some(({ value }) => value === "");
  return (
    <TextField
      {...props}
      select
      SelectProps={{
        native: true,
        inputProps: { style: { backgroundColor: "transparent" } },
      }}
    >
      {hasEmpty && <option aria-label="None" disabled />}
      {options
        .filter(({ value }) => value)
        .map(({ label, ...option }, i) => (
          <option key={`${label}-${i}`} {...option}>
            {label}
          </option>
        ))}
    </TextField>
  );
};

export default SelectField;
