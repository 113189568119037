import EmailIcon from "@/svg/email.svg";
import FacebookIcon from "@/svg/facebook.svg";
import MessengerIcon from "@/svg/messenger.svg";
import WhatsAppIcon from "@/svg/whatsapp.svg";
import { AnchorButton } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import Image from "next/image";

type Props = {
  href: string;
  title: string;
  color: string;
  external?: boolean;
  className?: string;
};

const SocialLink: React.FC<Props> = ({
  href,
  title,
  color,
  external,
  className,
  children,
}) => (
  <div className={classNames("w-13.75 h-13.75", className)}>
    <AnchorButton
      href={href}
      external={external}
      title={title}
      style={{
        border: "none",
        backgroundColor: color,
        paddingLeft: "0",
        paddingRight: "0",
      }}
    >
      {children}
    </AnchorButton>
  </div>
);

const SocialLinks: React.FC<{ urlToShare: string }> = ({ urlToShare }) => (
  <div className="flex space-x-3 mt-3 md:mt-0 md:ml-3">
    <SocialLink
      href={`https://www.facebook.com/sharer.php?u=${urlToShare}`}
      title="Share on Facebook"
      color="#4968ad"
      external
    >
      <Image src={FacebookIcon} alt="Share on Facebook" unoptimized priority />
    </SocialLink>
    <SocialLink
      href={`fb-messenger://share?link=${urlToShare}`}
      title="Share on Messenger"
      color="#fbfbfb"
      className="lg:hidden"
    >
      <Image
        src={MessengerIcon}
        alt="Share on Messenger"
        unoptimized
        priority
      />
    </SocialLink>
    <SocialLink
      href={`https://api.whatsapp.com/send?text=${urlToShare}`}
      title="Share on WhatsApp"
      color="#25d366"
      external
    >
      <Image src={WhatsAppIcon} alt="Share on WhatsApp" unoptimized priority />
    </SocialLink>
    <SocialLink
      href={`mailto:?body=${urlToShare}`}
      title="Share via Email"
      color="#bdbdbd"
      external
    >
      <Image src={EmailIcon} alt="Share via Email" unoptimized priority />
    </SocialLink>
  </div>
);

export default SocialLinks;
