import { GoogleIcon } from "./GoogleIcon";
import React from "react";

type GoogleButtonConnectedProps = {
  children: React.ReactNode;
  onClick: () => void;
};

const GoogleButtonConnected = ({
  children,
  onClick,
}: GoogleButtonConnectedProps) => {
  return (
    <div className="relative w-full h-16 bg-white border-cinderBlock border">
      <div className="flex items-center justify-start w-full absolute inset-0">
        <div className="bg-caspar h-full w-[62px] flex items-center justify-center shrink-0">
          <GoogleIcon />
        </div>
        <div className="flex justify-between w-full px-5">
          {children}
          <button
            type="button"
            className="underline text-xxs hover:no-underline"
            onClick={onClick}
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>
  );
};

GoogleButtonConnected.displayName = "GoogleButtonConnected";

export { GoogleButtonConnected };
