import { useGoogleOAuth } from "./useGoogleOAuth";
import { IGoogleOAuthAssociateResponse } from "@/api";
import { AssociateGoogleAccountWarningModal } from "@/components";
import { useModalDispatch } from "@/providers";
import { ModalActionType } from "@narrative-software/narrative-web-ui";
import { useCallback } from "react";

interface GoogleErrorResponse {
  status: number;
  code?: string;
  detail?: string;
  title?: string;
}

/**
 * Light wrapper around useGoogleOAuth that precedes the openWindow callout with a user warning modal.
 */
const useGoogleOauthAssociate = ({
  onSuccess,
  onError,
  userId,
}: {
  onSuccess: (data: IGoogleOAuthAssociateResponse) => void;
  onError?: (error: GoogleErrorResponse) => void;
  userId?: string;
}) => {
  const modalDispatch = useModalDispatch();
  const { openWindow } = useGoogleOAuth(onSuccess, onError);

  const onModalConfirm = useCallback(() => {
    openWindow("associate", userId);
    modalDispatch({ type: ModalActionType.Close });
  }, [openWindow]);

  return () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <AssociateGoogleAccountWarningModal onConfirm={onModalConfirm} />
        ),
      },
    });
  };
};

export { useGoogleOauthAssociate };
