import {
  useAccountQuery,
  useUpdateAccountMutation,
  useUpdateUserMutation,
  useUserQuery,
} from "@/api";
import { BillingAddressFields, IBillingAddressFields } from "@/containers";
import { RequestHelper } from "@/helpers";
import { Button } from "@narrative-software/narrative-web-ui";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

type Props = {
  closeModal: () => void;
  buttonText?: string;
  showUserFields?: boolean;
};

const BillingAddressForm: React.FC<Props> = ({
  closeModal,
  buttonText = "Update",
  showUserFields = false,
}) => {
  const { data: accountData } = useAccountQuery();
  const { data: userData } = useUserQuery();

  const { mutateAsync: doUpdateUser, isLoading: isSendingUpdateUser } =
    useUpdateUserMutation({ showSuccess: false });
  const { mutateAsync: doUpdateAccount, isLoading: isSendingUpdateAccount } =
    useUpdateAccountMutation({
      successMessage: "Billing address updated successfully!",
    });

  // Validation
  const validationSchema = Yup.object().shape({
    firstName: showUserFields
      ? Yup.string().required("First name is required")
      : Yup.string().optional(),
    lastName: showUserFields
      ? Yup.string().required("Last name is required")
      : Yup.string().optional(),
    line1: Yup.string().required("Address is required"),
    line2: Yup.string().optional(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State or region is required"),
    "postal-code": Yup.string().required("Zip or postcode is required"),
    country: Yup.string().required("Country is required"),
  });

  // Initial values
  const addressData = accountData?.data.attributes.address;
  const ownerData = userData?.data.attributes;
  const initialValues: IBillingAddressFields = {
    firstName: ownerData?.["f-name"] || "",
    lastName: ownerData?.["l-name"] || "",
    line1: addressData?.line1 || "",
    line2: addressData?.line2 || "",
    city: addressData?.city || "",
    state: addressData?.state || "",
    "postal-code": addressData?.["postal-code"] || "",
    country: addressData?.country || "",
  };

  // Handle submit
  const handleSubmit = async (
    values: IBillingAddressFields,
    actions: FormikHelpers<IBillingAddressFields>
  ) => {
    try {
      const { firstName, lastName, ...addressValues } = values;

      if (ownerData && showUserFields) {
        await doUpdateUser({
          firstName: firstName || ownerData["f-name"],
          lastName: lastName || ownerData["l-name"],
          id: ownerData.id,
          email: ownerData.email,
        });
      }

      if (accountData) {
        const body = RequestHelper.getUpdateBillingAddressRequestBody({
          id: accountData.data.id,
          address: addressValues,
        });
        await doUpdateAccount(body);
      }

      closeModal();
    } catch {
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty, isSubmitting, ...rest }) => {
        const isLoading =
          isSubmitting || isSendingUpdateAccount || isSendingUpdateUser;
        return (
          <Form noValidate>
            <BillingAddressFields {...{ showUserFields, ...rest }} />
            <div className="flex space-x-2.5 mt-12 sm:max-w-80 sm:ml-auto">
              <Button
                colour="gray-600"
                appearance="secondary"
                disabled={isLoading}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colour="black"
                disabled={!dirty}
                isLoading={isLoading}
                showLoader
              >
                {buttonText}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BillingAddressForm;
