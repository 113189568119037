import {
  GET_ACCOUNTS_KEY,
  GET_ACCOUNT_KEY,
  GET_PRODUCTS_KEY,
  Api,
  ProductName,
  ProductInterval,
  MutationOptions,
  ErrorCode,
  ICreateAccountRequest,
  ICreateAccountResponse,
  IErrorResponse,
  IErrorObject,
  useLoginMutation,
  useCreateAccountMutation,
  usePreviewChargeMutation,
  usePlansQuery,
  IGoogleOAuthResponse,
} from "@/api";
import { UserOSAnswer } from "@/components";
import { ROUTES } from "@/config";
import { ISignUpFormCharge, ISignUpFormTrial } from "@/containers";
import { TOPICS, publishEvent } from "@/events";
import {
  RequestHelper,
  CouponHelper,
  DataHelper,
  TrackingHelper,
} from "@/helpers";
import {
  useCreateOrUpdateProduct,
  useQueryParams,
  useCoupon,
  useRedirect,
  useAuthRedirect,
} from "@/hooks";
import { useAlertDispatch, useAuthContext } from "@/providers";
import { tuple } from "@/types";
import { productLowerCase } from "@/utils";
import { AlertActionType } from "@narrative-software/narrative-web-ui";
import { useRouter } from "next/router";
import { useState } from "react";
import { QueryClient, useQueryClient } from "react-query";

// Get existing product
const getExistingProduct = async (
  queryClient: QueryClient,
  product: ProductName
) => {
  // Get account data after login
  const accountsResponse = await queryClient.fetchQuery(GET_ACCOUNTS_KEY, () =>
    Api.getAccounts(["products"])
  );
  if (!accountsResponse) throw new Error("Failed to fetch accounts");
  const accountObject = accountsResponse.data[0];
  const accountID = accountObject.id;

  // Get product data
  const productsData = accountsResponse.included?.filter(
    (item) => item.type === "products"
  );
  if (!productsData) throw new Error("Failed to fetch products");

  // Set data into query cache
  queryClient.setQueryData([GET_ACCOUNT_KEY, accountID], {
    data: accountObject,
  });
  queryClient.setQueryData([GET_PRODUCTS_KEY, accountID], {
    data: productsData,
  });

  return {
    accountID,
    existingProduct: DataHelper.getProductByName(product, productsData),
  };
};

export interface UseSignUpParams<
  T extends ISignUpFormTrial | ISignUpFormCharge
> {
  planID: string;
  interval: ProductInterval;
  product: ProductName;
  formValues: T;
  googleData?: Nullable<IGoogleOAuthResponse>;
}

/**
 * Hook that handles sign up
 */
export const useSignUp = ({
  config,
  sendFailedEvent = true,
}: {
  config?: MutationOptions<ICreateAccountResponse, ICreateAccountRequest>;
  sendFailedEvent?: boolean;
} = {}) => {
  const router = useRouter();
  const alertDispatch = useAlertDispatch();
  const { setAuthenticated } = useAuthContext();
  const { backPath, redirect } = useQueryParams();
  const queryClient = useQueryClient();
  const doRedirect = useRedirect();
  const doAuthRedirect = useAuthRedirect();
  const couponHook = useCoupon();
  const { data: plansData } = usePlansQuery();
  const {
    mutateAsync: doLogin,
    isLoading: isSendingLogin,
    isError: isErrorLogin,
  } = useLoginMutation();
  const [doCreateOrUpdateProduct, { isError: isErrorCreateOrUpdateProduct }] =
    useCreateOrUpdateProduct(false);
  const {
    mutateAsync: doCreateAccount,
    isLoading: isSendingCreateAccount,
    isError: isErrorCreateAccount,
  } = useCreateAccountMutation(config);
  const {
    mutateAsync: doPreviewCharge,
    isLoading: isSendingPreviewCharge,
    isError: isErrorPreviewCharge,
  } = usePreviewChargeMutation();

  // Handle sign up
  const callback = async <T extends ISignUpFormTrial | ISignUpFormCharge>({
    planID,
    interval,
    product,
    formValues,
    googleData,
  }: UseSignUpParams<T>) => {
    try {
      const plan = plansData && DataHelper.getPlanById(planID, plansData.data);

      if (!plan) {
        alertDispatch({
          type: AlertActionType.SetContent,
          payload: {
            type: "error",
            title: "Failed to get plan",
          },
        });
        throw new Error("Failed to get plan");
      }

      const { userOSAnswer, genre, genreOther, ...formData } = formValues;
      const coupon =
        "coupon" in formValues ? formValues.coupon : couponHook.coupon;
      const redirectType = redirect || productLowerCase(product);
      const isFreePlan = plan.attributes["default-free"];
      const isAppAuthFlow = !!backPath;
      const isGoogleSignUp = !!googleData;

      if (isGoogleSignUp) {
        // If coupon exists, patch product
        if (coupon) {
          // If existing product, update it with coupon
          const { existingProduct } = await getExistingProduct(
            queryClient,
            product
          );
          if (existingProduct) {
            await doCreateOrUpdateProduct({
              planID,
              interval,
              product,
              coupon,
              productID: existingProduct.id,
            });
          }
        }

        // Sign up succeeded event
        const { user_id: userId, oauth_status: oauthStatus } = googleData;
        if (userId && oauthStatus !== "user_exists") {
          TrackingHelper.sendSignUpSucceededEvent({
            ...formValues,
            product,
            userId,
            method: "google",
          });
        }
      } else {
        // Create account
        const body = RequestHelper.getCreateAccountRequestBody({
          coupon,
          interval,
          user: formData,
          planID: isFreePlan ? planID : undefined, // If paid plan, create account then redirect to checkout page for payment
        });
        await doCreateAccount(body);

        // Sign up succeeded event
        const { user_id: userId } = await doLogin(formData);
        if (userId) {
          TrackingHelper.sendSignUpSucceededEvent({
            ...formValues,
            product,
            userId,
            method: "email",
          });
        }
      }

      // Preview charge
      const chargeData = await doPreviewCharge({ planID, interval, coupon });
      const isCharge = chargeData?.data.attributes.total > 0;

      // If a payment is required, redirect to checkout
      if (isCharge) {
        await doAuthRedirect(`/${ROUTES.CHECKOUT.SLUG}`, {
          ...router.query,
          planID,
          interval,
          signup: "true",
        });
        return;
      }

      // Delete coupon cookie
      CouponHelper.deleteCookie();

      // If we want to redirect to the auth page after login
      if (isAppAuthFlow) {
        setAuthenticated(true);
        return;
      }

      // Query params
      let query: { userOSAnswer?: UserOSAnswer; cancelTracking?: boolean } = {};
      if (userOSAnswer) {
        query.userOSAnswer = userOSAnswer;
      }

      // Explicit conditions from differing departments on when to fire events
      if (
        (genre === "Other" && product === ProductName.Publish) ||
        (genre === "I’m not a professional photographer" &&
          product === ProductName.Select)
      ) {
        query.cancelTracking = true;
      }

      // Otherwise redirect to download page
      await doRedirect({ query, type: redirectType });
    } catch (e: any) {
      const status = e.response?.status;
      const errorObj = e.response?.data?.errors?.[0] as IErrorObject;
      const reason = status ? `status_code_${status}` : e.message;
      const code = errorObj?.code;

      // Delete coupon cookie if invalid
      if (code === ErrorCode.CouponInvalid) {
        const query = { ...router.query };
        delete query.fp_ref;
        delete query.referral;
        delete query.affiliate;
        await router.replace({ pathname: router.pathname, query });
        CouponHelper.deleteCookie();
      }

      // Sign up failed event
      if (sendFailedEvent) {
        publishEvent(TOPICS.signUpFailed, { reason });
      }

      throw new Error(code || e.message);
    }
  };

  return tuple(callback, {
    isLoading:
      isSendingLogin || isSendingCreateAccount || isSendingPreviewCharge,
    isError:
      isErrorLogin ||
      isErrorCreateAccount ||
      isErrorPreviewCharge ||
      isErrorCreateOrUpdateProduct,
  });
};

/**
 * Hook that handles creating a product (used when a user trys to sign up but already has an account)
 */
export const useLoginSuccess = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { redirect, backPath } = useQueryParams();
  const { coupon } = useCoupon();
  const doRedirect = useRedirect();
  const doAuthRedirect = useAuthRedirect();
  const [doCreateOrUpdateProduct, { isError: isErrorCreateOrUpdateProduct }] =
    useCreateOrUpdateProduct(false);

  const [isLoading, setLoading] = useState(false);

  const callback = async ({
    planID,
    interval,
    product,
  }: {
    planID: string;
    interval: ProductInterval;
    product: ProductName;
  }) => {
    try {
      setLoading(true);

      const isAuthFlow = !!backPath;
      // If no existing product, then subscribe to product
      const { existingProduct, accountID } = await getExistingProduct(
        queryClient,
        product
      );
      if (!existingProduct) {
        await doCreateOrUpdateProduct({
          accountID,
          planID,
          interval,
          product,
          coupon,
        });
      }

      // If auth flow, go to auth page
      if (isAuthFlow) {
        await doAuthRedirect(backPath, router.query);
      } else if (!existingProduct) {
        await doRedirect({ type: redirect || product.toLowerCase() });
      }

      return Boolean(existingProduct);
    } catch (e: any) {
      const errorResponse = e.response as IErrorResponse | undefined;
      const code = errorResponse?.data?.errors?.[0]?.code;

      throw new Error(code || e.message);
    } finally {
      setLoading(false);
    }
  };

  return tuple(callback, {
    isLoading,
    isError: isErrorCreateOrUpdateProduct,
  });
};
