import ChromeDeepLinkImage from "../../../public/images/chrome-deep-link-publish@2x.webp";
import FirefoxDeepLinkImage from "../../../public/images/firefox-deep-link-publish@2x.webp";
import GenericDeepLinkImage from "../../../public/svg/male-photographer.svg";
import { Api } from "@/api";
import { LoadingScreen, SEO, AuthError } from "@/components";
import { PUBLISH_DEEP_LINK_URL } from "@/config";
import { LayoutPublic } from "@/containers";
import { TOPICS, publishEvent, getSessionID } from "@/events";
import { withAuth } from "@/hoc";
import { useNonAuthRedirect, useMounted, useQueryParams } from "@/hooks";
import { isBrowser } from "@/utils";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import * as Sentry from "@sentry/nextjs";
import Bowser from "bowser";
import classNames from "classnames";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Tokens = {
  accessToken: string;
  refreshToken: string;
};

const PUBLISH_INSTALL_ID_SESSION_KEY = "narrative-publish-install-id";

const browserType =
  isBrowser() && Bowser.parse(window.navigator.userAgent)?.browser?.name;

export const getPublishInstallIdFromSession = () =>
  isBrowser()
    ? window.sessionStorage.getItem(PUBLISH_INSTALL_ID_SESSION_KEY)
    : null;

const setPublishInstallIdToSession = (id: string) =>
  isBrowser() &&
  window.sessionStorage.setItem(PUBLISH_INSTALL_ID_SESSION_KEY, id);

const browserLinkImages: { [key: string]: React.ReactNode } = {
  Firefox: (
    <Image
      src={FirefoxDeepLinkImage}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
  Chrome: (
    <Image
      src={ChromeDeepLinkImage}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
};

const browserHeaders: { [key: string]: string } = {
  Firefox: "Click ‘Open Link’ to complete sign in",
  Chrome: "Click ‘Open Narrative Publish’ to complete sign in",
  Safari: "Click ‘Allow’ to complete sign in",
};

const browserBody: { [key: string]: string } = {
  Firefox:
    "You should see a pop-up from your browser asking to open Publish. You must click ‘Open Link’ to complete your sign in.",
  Chrome:
    "You should see a pop-up from your browser asking to open Publish. You must click ‘Open Narrative Publish’ to complete your sign in.",
  Safari:
    "You should see a pop-up from your browser in the center of the screen asking to open Publish. You must click ‘Allow’ to complete your sign in.",
};

const otherBrowserBody =
  "You should see a pop-up from your browser asking to open Publish. You must click this to complete your sign in.";

const PublishAuth: React.FC = () => {
  const router = useRouter();
  const isMounted = useMounted();
  const doNonAuthRedirect = useNonAuthRedirect();
  const { authCheck, installId } = useQueryParams();

  const [tokens, setTokens] = useState<Nullable<Tokens>>(null);
  const [tokenError, setTokenError] = useState<Nullable<string>>(null);

  // Deep link
  const openDeepLink = () => {
    const sessionId = getSessionID();
    const installIdFromSession = getPublishInstallIdFromSession();
    if (installIdFromSession) {
      publishEvent(TOPICS.publishSignInFinish, {
        publish_install_id: installIdFromSession,
      });
      window.sessionStorage.removeItem(PUBLISH_INSTALL_ID_SESSION_KEY);
    }
    window.location.href =
      `${PUBLISH_DEEP_LINK_URL}://auth?refresh_token=${tokens?.refreshToken}&token=${tokens?.accessToken}` +
      (sessionId ? `&sessionId=${sessionId}` : "");
  };

  // Publish event
  useEffect(() => {
    if (installId && !getPublishInstallIdFromSession()) {
      publishEvent(TOPICS.publishSignInStart, {
        publish_install_id: installId,
        force_new_sign_in: !!authCheck,
      });
      setPublishInstallIdToSession(installId);
    }
  }, [installId]);

  // Fetch the token if no auth check
  useEffect(() => {
    let canceled = false; // Used to prevent React warning from calling setState on unmounted component
    (async () => {
      if (authCheck) {
        const query = { ...router.query };
        delete query.authCheck;
        await doNonAuthRedirect(router.pathname, query); // Logout and redirect to login page, then redirect back here if login succeeds
      } else {
        try {
          const { access_token, refresh_token } =
            await Api.swapWebTokenForPublishToken();
          if (!canceled) {
            setTokens({
              accessToken: access_token,
              refreshToken: refresh_token,
            });
          }
        } catch (e: any) {
          if (!canceled) {
            setTokenError(e);
          }
          Sentry.captureException(e);
        }
      }
    })();
    return () => {
      canceled = true;
    };
  }, [authCheck]);

  // Open deep link
  useEffect(() => {
    if (tokens) {
      const timeout = setTimeout(() => {
        openDeepLink();
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tokens]);

  // Error
  if (tokenError) {
    return <AuthError product="publish" />;
  }

  return (
    <LayoutPublic>
      <SEO title="Publish Auth | Narrative" />

      {!tokens || !isMounted ? (
        <LoadingScreen />
      ) : (
        <div className="flex flex-col items-center">
          <div className={classNames({ "mt-30": browserType === "Firefox" })}>
            <h1 className="text-h2 font-semibold text-center leading-tight md:text-h1">
              {browserType && browserHeaders[browserType]
                ? browserHeaders[browserType]
                : "Complete sign in"}
            </h1>
          </div>

          <HorizontalRule topMargin bottomMargin />

          <div className="text-center w-full max-w-4xl">
            <p className="text-18 text-stealth-bomber">
              {browserType && browserBody[browserType]
                ? browserBody[browserType]
                : otherBrowserBody}{" "}
              If it didn’t appear,{" "}
              <a
                role="link"
                tabIndex={0}
                onClick={openDeepLink}
                onKeyDown={openDeepLink}
                className="underline cursor-pointer"
              >
                click here
              </a>{" "}
              to try again.
            </p>
          </div>

          <div className="flex justify-center items-center mt-10 pb-10">
            {browserType && browserLinkImages[browserType] ? (
              <div className="border border-stealth-bomber">
                {browserLinkImages[browserType]}
              </div>
            ) : (
              <Image
                src={GenericDeepLinkImage}
                alt="How to open link"
                width="226"
                height="278"
              />
            )}
          </div>
        </div>
      )}
    </LayoutPublic>
  );
};

export default withAuth(PublishAuth);
