import { IPlanObject } from "@/api";
import { SELECT_PLAN_SLUGS } from "@/config";

const getIsProPlan = (plan?: IPlanObject) =>
  SELECT_PLAN_SLUGS.allPro.includes(String(plan?.attributes.slug));

const getIsFreePlan = (plan?: IPlanObject) =>
  SELECT_PLAN_SLUGS.allFree.includes(String(plan?.attributes.slug));

const getLivePlans = (plans: IPlanObject[]) =>
  plans.filter(({ attributes: { status } }) => status === 1);

// We use fallbacks here, because during rollouts the defaultFree might not be available yet. The default free is used for new users.
const findDefaultFreePlanInPlans = (plans: IPlanObject[]) =>
  plans.find(
    ({ attributes: { status, "default-free": defaultFree, slug } }) =>
      status === 1 && slug === SELECT_PLAN_SLUGS.defaultFree && defaultFree
  ) ||
  plans.find(
    ({ attributes: { status, "default-free": defaultFree, slug } }) =>
      status === 1 && SELECT_PLAN_SLUGS.allFree.includes(slug) && defaultFree
  ) ||
  plans.find(({ attributes: { slug } }) =>
    SELECT_PLAN_SLUGS.allFree.includes(slug)
  );

// We use a fallbacks because during rollouts the defaultPro might not be available yet. The default pro is used for users who upgrade.
const findDefaultProPlanInPlans = (plans: IPlanObject[]) =>
  plans.find(
    ({ attributes: { status, slug } }) =>
      status === 1 && slug === SELECT_PLAN_SLUGS.defaultPro
  ) ||
  plans.find(
    ({ attributes: { status, slug } }) =>
      status === 1 && SELECT_PLAN_SLUGS.allPro.includes(slug)
  ) ||
  plans.find(({ attributes: { slug } }) =>
    SELECT_PLAN_SLUGS.allPro.includes(slug)
  );

export {
  getIsFreePlan,
  getIsProPlan,
  getLivePlans,
  findDefaultFreePlanInPlans,
  findDefaultProPlanInPlans,
};
