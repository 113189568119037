import { useUserQuery } from "@/api";

function useIntercom() {
  const { data: userData } = useUserQuery();

  return () => {
    if (userData) {
      window.Intercom("show", {
        email: userData?.data?.attributes?.email,
      });
      return;
    }

    window.Intercom("show");
  };
}

export { useIntercom };
