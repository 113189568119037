import NotFoundImage from "../../../public/svg/not-found.svg";
import { SEO } from "@/components";
import { LayoutPublic } from "@/containers";
import { Product } from "@/types";
import { capitalize } from "@/utils";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import Image from "next/image";

type Props = {
  product: Product;
};

const AuthError: React.FC<Props> = ({ product: _product }) => {
  const product = capitalize(_product);
  return (
    <LayoutPublic>
      <SEO title={`${product} Auth Error | Narrative`} />

      <div className="mb-10">
        <h1 className="text-h2 font-semibold text-center leading-tight md:text-h1">
          Oops, something went wrong
        </h1>
      </div>

      <div className="flex justify-center">
        <HorizontalRule />
      </div>

      <div className="mt-8 text-center w-full max-w-4xl self-center">
        <p className="text-18 text-stealth-bomber">
          Well, this is awkward. We tried to sign you into {product} in a very
          clever way, but something went awry. Sorry about that. Head back to{" "}
          {product} and click ‘sign in manually’ to sign in the old fashioned
          way.
        </p>
      </div>

      <div className="flex justify-center mt-12">
        <Image src={NotFoundImage} alt="Auth Error" />
      </div>
    </LayoutPublic>
  );
};

export default AuthError;
