import { IUserResponse } from "@/api";

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  hasSetPassword: boolean;
  hasConnectedGoogleAccount: boolean;
}

/**
 * @function getUserDetails - formats IUserResponse into a more usable object for in-app logic.
 */
function getUserDetails(user: IUserResponse): IUser {
  return {
    id: user.data.id,
    firstName: user.data.attributes["f-name"],
    lastName: user.data.attributes["l-name"],
    email: user.data.attributes.email,
    emailVerified: user.data.attributes["email-verified"],
    hasSetPassword: user.data.attributes["auth-providers"].includes("email"),
    hasConnectedGoogleAccount:
      user.data.attributes["auth-providers"].includes("google"),
  };
}

/**
 * @function userHasSetPassword
 */
function userHasSetPassword(user: IUserResponse): boolean | null {
  if (!user?.data?.attributes?.["auth-providers"]) {
    return null;
  }

  // if email is in this list, the user has set a password as per service code.
  return user.data.attributes["auth-providers"].includes("email");
}

/**
 * @function hasUserConnectedGoogleAccount
 */
function hasUserConnectedGoogleAccount(user?: IUserResponse): boolean | null {
  if (!user?.data?.attributes?.["auth-providers"]) {
    return null;
  }

  return user.data.attributes["auth-providers"].includes("google");
}

export { getUserDetails, userHasSetPassword, hasUserConnectedGoogleAccount };
