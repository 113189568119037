import ChromeDeepLinkImageMac from "../../../public/images/chrome-deep-link-select@2x.webp";
import ChromeDeepLinkImageWindows from "../../../public/images/chrome-windows-deep-link-select@2x.webp";
import EdgeDeepLinkImageWindows from "../../../public/images/edge-windows-deep-link-select@2x.webp";
import FirefoxDeepLinkImageMac from "../../../public/images/firefox-deep-link-select@2x.webp";
import FirefoxDeepLinkImageWindows from "../../../public/images/firefox-windows-deep-link-select@2x.webp";
import GenericDeepLinkImage from "../../../public/svg/male-photographer.svg";
import { Api } from "@/api";
import { LoadingScreen, SEO, AuthError } from "@/components";
import { getDeviceData } from "@/components/Download/utils";
import { SELECT_DEEP_LINK_URL } from "@/config";
import { LayoutPublic } from "@/containers";
import { TOPICS, publishEvent, getSessionID } from "@/events";
import { withAuth } from "@/hoc";
import { useNonAuthRedirect, useMounted, useQueryParams } from "@/hooks";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import * as Sentry from "@sentry/nextjs";
import Bowser from "bowser";
import classNames from "classnames";
import Image from "next/image";
import router, { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Tokens = {
  accessToken: string;
  refreshToken: string;
};

const SELECT_INSTALL_ID_SESSION_KEY = "narrative-select-install-id";

export const getSelectInstallIdFromSession = () =>
  window.sessionStorage.getItem(SELECT_INSTALL_ID_SESSION_KEY);

const setSelectInstallIdToSession = (id: string) =>
  window.sessionStorage.setItem(SELECT_INSTALL_ID_SESSION_KEY, id);

const browserLinkImages: { [key: string]: React.ReactNode } = {
  firefox_macos: (
    <Image
      src={FirefoxDeepLinkImageMac}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
  firefox_windows: (
    <Image
      src={FirefoxDeepLinkImageWindows}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
  chrome_macos: (
    <Image
      src={ChromeDeepLinkImageMac}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
  chrome_windows: (
    <Image
      src={ChromeDeepLinkImageWindows}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
  microsoft_edge_windows: (
    <Image
      src={EdgeDeepLinkImageWindows}
      alt="How to open link"
      width="384"
      height="220"
      quality={90}
    />
  ),
};

const browserHeaders: { [key: string]: string } = {
  firefox: "Click ‘Open Link’ to complete sign in",
  chrome: "Click ‘Open Narrative Select’ to complete sign in",
  safari: "Click ‘Allow’ to complete sign in",
  microsoft_edge: "Click ‘Open Narrative Select’ to complete sign in",
};

const browserBody: { [key: string]: string } = {
  firefox:
    "You should see a pop-up from your browser asking to open Select. You must click ‘Open Link’ to complete your sign in.",
  chrome:
    "You should see a pop-up from your browser asking to open Select. You must click ‘Open Narrative Select’ to complete your sign in.",
  safari:
    "You should see a pop-up from your browser in the center of the screen asking to open Select. You must click ‘Allow’ to complete your sign in.",
  microsoft_edge:
    "You should see a pop-up from your browser asking to open Select. You must click ‘Open’ to complete your sign in. If it didn’t appear, click here to try again.",
};

const otherBrowserBody =
  "You should see a pop-up from your browser asking to open Select. You must click this to complete your sign in.";

const SelectAuth: React.FC = () => {
  const router = useRouter();
  const isMounted = useMounted();
  const { browserType, osName } = getDeviceData();
  const browserWithOSName = `${browserType}_${osName}`;

  const doNonAuthRedirect = useNonAuthRedirect();
  const { authCheck, installId, protocol } = useQueryParams(); // protocol is for dev envs only.

  const [tokens, setTokens] = useState<Nullable<Tokens>>(null);
  const [tokenError, setTokenError] = useState<Nullable<string>>(null);

  // Deep link
  const openDeepLink = () => {
    const deepLinkUrl = protocol || SELECT_DEEP_LINK_URL;
    const sessionId = getSessionID();
    const installIdFromSession = getSelectInstallIdFromSession();
    if (installIdFromSession) {
      publishEvent(TOPICS.selectSignInFinish, {
        select_install_id: installIdFromSession,
      });
      window.sessionStorage.removeItem(SELECT_INSTALL_ID_SESSION_KEY);
    }
    window.location.href =
      `${deepLinkUrl}://auth?refresh_token=${tokens?.refreshToken}&token=${tokens?.accessToken}` +
      (sessionId ? `&sessionId=${sessionId}` : "");
  };

  // Publish event
  useEffect(() => {
    if (installId && !getSelectInstallIdFromSession()) {
      publishEvent(TOPICS.selectSignInStart, {
        select_install_id: installId,
        force_new_sign_in: !!authCheck,
      });
      setSelectInstallIdToSession(installId);
    }
  }, [installId]);

  // Fetch the token if the user is authenticated
  useEffect(() => {
    let canceled = false; // Used to prevent React warning from calling setState on unmounted component.
    (async () => {
      if (authCheck) {
        const query = { ...router.query };
        delete query.authCheck;
        await doNonAuthRedirect(router.pathname, query); // Logout and redirect to login page, then redirect back here if login succeeds
      } else {
        try {
          const { access_token, refresh_token } =
            await Api.swapWebTokenForSelectToken();
          if (!canceled) {
            setTokens({
              accessToken: access_token,
              refreshToken: refresh_token,
            });
          }
        } catch (e: any) {
          if (!canceled) {
            setTokenError(e);
          }
          Sentry.captureException(e);
        }
      }
    })();
    return () => {
      canceled = true;
    };
  }, [authCheck]);

  // Open deep link
  useEffect(() => {
    if (tokens) {
      const timeout = setTimeout(() => {
        openDeepLink();
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tokens]);

  // Error
  if (tokenError) {
    return <AuthError product="select" />;
  }

  return (
    <LayoutPublic>
      <SEO title="Select Auth | Narrative" />

      {!tokens || !isMounted ? (
        <LoadingScreen />
      ) : (
        <div className="flex flex-col items-center">
          <div className={classNames({ "mt-30": browserType === "Firefox" })}>
            <h1 className="text-h2 font-semibold text-center leading-tight md:text-h1">
              {browserType && browserHeaders[browserType]
                ? browserHeaders[browserType]
                : "Complete sign in"}
            </h1>
          </div>

          <HorizontalRule topMargin bottomMargin />

          <div className="text-center w-full max-w-4xl">
            <p className="text-18 text-stealth-bomber">
              {browserType && browserBody[browserType]
                ? browserBody[browserType]
                : otherBrowserBody}{" "}
              If it didn’t appear,{" "}
              <a
                role="link"
                tabIndex={0}
                onClick={openDeepLink}
                onKeyDown={openDeepLink}
                className="underline cursor-pointer"
              >
                click here
              </a>{" "}
              to try again.
            </p>
          </div>

          <div className="flex justify-center items-center my-10">
            {browserType && browserLinkImages[browserWithOSName] ? (
              <div>{browserLinkImages[browserWithOSName]}</div>
            ) : (
              <Image
                src={GenericDeepLinkImage}
                alt="How to open link"
                width="226"
                height="278"
              />
            )}
          </div>
        </div>
      )}
    </LayoutPublic>
  );
};

export default withAuth(SelectAuth);
