import {
  Api,
  GET_ACCOUNT_KEY,
  GET_ACCOUNTS_KEY,
  GET_PRODUCTS_KEY,
  GET_PLANS_KEY,
} from "@/api";
import { useIsAuthenticated, useAccountContext } from "@/providers";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

export const usePrefetchPlans = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    if (queryClient.getQueryData(GET_PLANS_KEY)) return;
    queryClient.prefetchQuery(GET_PLANS_KEY, Api.getPlans);
  }, []);
};

export const usePrefetchAccounts = (isPublicPage?: boolean) => {
  const queryClient = useQueryClient();
  const isAuthenticated = useIsAuthenticated();
  const { accountID, setAccountID } = useAccountContext();

  useEffect(() => {
    if (accountID || isPublicPage || !isAuthenticated) return;

    // If user is logged in and the account ID doesn't exist in context
    try {
      (async () => {
        const accountsResponse = await queryClient.fetchQuery(
          GET_ACCOUNTS_KEY,
          () => Api.getAccounts()
        );

        // If we get a successful response
        if (accountsResponse?.data?.length) {
          const accountObject = accountsResponse.data[0];
          const accountID = accountObject.id;
          queryClient.setQueryData([GET_ACCOUNT_KEY, accountID], {
            data: accountObject,
          });

          // If products are included in the request
          if (accountsResponse?.included?.length) {
            const productsData = accountsResponse.included.filter(
              (item) => item.type === "products"
            );
            if (Array.isArray(productsData)) {
              queryClient.setQueryData([GET_PRODUCTS_KEY, accountID], {
                data: productsData,
              });
            }
          }

          setAccountID(accountID);
        }
      })();
    } catch {}
  }, [accountID, isPublicPage, isAuthenticated]);
};
