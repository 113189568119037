import classNames from "classnames";
import React from "react";

type PropTypes = {
  term: string;
  termWidthClass?: string;
};

const PlanCardItem: React.FC<PropTypes> = ({
  term,
  children,
  termWidthClass = "w-9/20",
}) => (
  <div className="flex mb-3">
    <dt
      className={classNames("mr-4 flex-shrink-0 font-semibold", termWidthClass)}
    >
      {term}
    </dt>
    <dd>{children}</dd>
  </div>
);

PlanCardItem.displayName = "PlanCardItem";

export default PlanCardItem;
