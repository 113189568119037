import {
  getApiEndpoint,
  IGoogleOAuthAssociateResponse,
  IGoogleOAuthResponse,
} from "@/api";
import { API_URL } from "@/config";
import { TOPICS, publishEvent } from "@/events";
import { AuthHelper } from "@/helpers";
import { useWindowEvent } from "@/hooks";
import { useRef, useState } from "react";

interface GoogleErrorResponse {
  status: number;
  code?: string;
  detail?: string;
  title?: string;
}

type GoogleOAuthEndpoint = "login" | "associate" | "select" | "publish"; // 'select' and 'publish' => sign up

/**
 * Hook that handles Google OAuth login, sign up, and account association
 */
const useGoogleOAuth = (
  onSuccess:
    | ((data: IGoogleOAuthResponse) => void)
    | ((data: IGoogleOAuthAssociateResponse) => void),
  onError?: (error: GoogleErrorResponse) => void
) => {
  const windowRef = useRef<Nullable<WindowProxy>>(null);
  const [data, setData] = useState<Nullable<IGoogleOAuthResponse>>(null);
  const [error, setError] = useState<Nullable<GoogleErrorResponse>>(null);
  const [isLoading, setLoading] = useState(false);
  const lastOpenedWindowEndpointRef = useRef<Nullable<string>>(null);

  const openWindow = (
    endpoint: GoogleOAuthEndpoint,
    userId?: string
  ): Nullable<WindowProxy> => {
    setLoading(true);
    lastOpenedWindowEndpointRef.current = endpoint;

    const windowSize = 610;
    const windowX = window.outerWidth / 2 + window.screenX - windowSize / 2;
    const windowY = window.outerHeight / 2 + window.screenY - windowSize / 2;

    // 'select' and 'publish' account creation
    const windowUrl = getApiEndpoint(
      `oauth/${endpoint}/google${userId ? `/${userId}` : ""}`
    );

    if (windowRef.current === null || windowRef.current.closed) {
      windowRef.current = window.open(
        windowUrl,
        "_blank",
        `popup,width=${windowSize},height=${windowSize},top=${windowY},left=${windowX}`
      );
    } else {
      windowRef.current.focus();
    }

    publishEvent(TOPICS.googleSignInWindowOpen, {
      context: ENDPOINT_TO_ANALYTICS_KEYS[endpoint],
    });

    return windowRef.current;
  };

  useWindowEvent("message", (event) => {
    if (event.origin !== API_URL) return;
    try {
      if (!event.data) throw { status: 500 };

      const data = JSON.parse(event.data);

      if (lastOpenedWindowEndpointRef.current === "associate") {
        setError(null);
        onSuccess(data);
        publishEvent(TOPICS.googleSignInWindowOutcome, { succeeded: true });
        return;
      }

      if (data?.access_token && data?.refresh_token) {
        AuthHelper.initialize(data.access_token, data.refresh_token);
        setData(data);
        setError(null);
        onSuccess(data);
        publishEvent(TOPICS.googleSignInWindowOutcome, { succeeded: true });
        return;
      }

      throw data;
    } catch (e: any) {
      setData(null);
      onError?.(e);
      publishEvent(TOPICS.googleSignInWindowOutcome, { succeeded: false });
    } finally {
      setLoading(false);
    }
  });

  return { openWindow, isLoading, data };
};

const ENDPOINT_TO_ANALYTICS_KEYS: Record<GoogleOAuthEndpoint, string> = {
  login: "login",
  associate: "new_connection",
  select: "select_signup",
  publish: "publish_signup",
};

export { useGoogleOAuth };
