import { setMetadata } from "@/events";
import { isBrowser, isIPadOS } from "@/utils";
import Bowser from "bowser";
import isEqual from "lodash/isEqual";

const getOrSetSessionValue = (storageKey: string, _value: string | null) => {
  let value = _value;
  const valueFromSession = window.sessionStorage.getItem(storageKey);

  if (value) {
    window.sessionStorage.setItem(storageKey, value);
  } else if (valueFromSession) {
    value = valueFromSession;
  }

  return value;
};

/**
 * UTM
 */
interface MetaUTM {
  source: string | null;
  medium: string | null;
  campaign: string | null;
  term: string | null;
  content: string | null;
}

const getMetaUTM = (): MetaUTM => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } =
    Object.fromEntries(urlSearchParams.entries());

  const utmObj = {
    source: utm_source || null,
    medium: utm_medium || null,
    campaign: utm_campaign || null,
    term: utm_term || null,
    content: utm_content || null,
  };

  Object.entries(utmObj).forEach(([key, _value]) => {
    const value = getOrSetSessionValue(`utm_${key}`, _value);
    utmObj[key as keyof MetaUTM] = value;
  });

  return utmObj;
};

/**
 * Referral
 */
interface MetaReferral {
  ref: string | null;
  fp_ref: string | null;
  affiliate: string | null;
  referral: string | null;
}

const getMetaReferral = (): MetaReferral => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { ref, fp_ref, affiliate, referral } = Object.fromEntries(
    urlSearchParams.entries()
  );

  const referralObj: MetaReferral = {
    ref: ref || null,
    fp_ref: fp_ref || null,
    affiliate: affiliate || null,
    referral: referral || null,
  };

  Object.entries(referralObj).forEach(([key, _value]) => {
    const value = getOrSetSessionValue(key, _value);
    referralObj[key as keyof MetaReferral] = value;
  });

  return referralObj;
};

/**
 * Browser
 */
interface MetaBrowser {
  user_agent: string;
  name: string;
  version: string | null;
  version_array: number[] | null;
  device_type: string;
  os: string;
  os_version: string | null;
  os_version_array: number[] | null;
  timezone: number;
  locale: string;
}

const getMetaBrowser = (): MetaBrowser => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserVersion = browser.getBrowserVersion();
  const OSVersion = browser.getOSVersion();
  const _isIPadOS = isIPadOS();

  return {
    user_agent: browser.getUA(),
    name: browser.getBrowserName(true),
    version: browserVersion || null,
    version_array:
      browserVersion?.split(".").map((number) => Number.parseFloat(number)) ||
      null,
    device_type: _isIPadOS ? "tablet" : browser.getPlatformType(true),
    os: _isIPadOS ? "ipados" : browser.getOSName(),
    os_version: _isIPadOS ? null : OSVersion,
    os_version_array: _isIPadOS
      ? null
      : OSVersion?.split(".").map((number) => Number.parseFloat(number)) ||
        null,
    timezone: new Date().getTimezoneOffset(),
    locale: window.navigator.language?.toLowerCase(),
  };
};

/**
 * Viewport
 */
interface MetaViewport {
  width: number;
  height: number;
  offset_x: number;
  offset_y: number;
}

const getMetaViewport = (): MetaViewport => {
  // Scrollable element in private layout. For public layout, just use 'window'
  const main = document.getElementById("dashboard-main");
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    offset_x: Math.round(main ? main.scrollLeft : window.scrollX),
    offset_y: Math.round(main ? main.scrollTop : window.scrollY),
  };
};

/**
 * Metadata
 */
export interface EventMetadata {
  utm: MetaUTM;
  referral: MetaReferral;
  browser: MetaBrowser;
  viewport: MetaViewport;
}

export const Metadata = {
  metadata: null as EventMetadata | null,

  getMetaObject() {
    return {
      utm: getMetaUTM(),
      referral: getMetaReferral(),
      browser: getMetaBrowser(),
      viewport: getMetaViewport(),
    };
  },

  get() {
    if (!isBrowser()) return this.metadata;
    return this.metadata || (this.metadata = this.getMetaObject());
  },

  set() {
    const metadata = this.get();
    setMetadata("", metadata);
  },

  refresh() {
    const newMetadata = this.getMetaObject();
    if (!isEqual(this.metadata, newMetadata)) {
      setMetadata("", newMetadata);
      this.metadata = newMetadata;
    }
  },

  refreshViewport() {
    const viewport = getMetaViewport();
    if (this.metadata && !isEqual(this.metadata.viewport, viewport)) {
      setMetadata("viewport", viewport);
      this.metadata.viewport = viewport;
    }
  },
};
