import {
  ProductName,
  ProductInterval,
  useCreateProductMutation,
  useUpdateProductMutation,
} from "@/api";
import { SELECT_PRO_PLAN_ID } from "@/config";
import { publishEvent, TOPICS } from "@/events";
import { TrackingHelper } from "@/helpers";
import { tuple, RequireAtLeastOne } from "@/types";

/**
 * Hook that handles creating or updating a product
 */
export const useCreateOrUpdateProduct = (showSuccess?: boolean) => {
  const {
    mutateAsync: doCreateProduct,
    isLoading: isSendingCreateProduct,
    isError: isErrorCreateProduct,
  } = useCreateProductMutation({ showSuccess });

  const {
    mutateAsync: doUpdateProduct,
    isLoading: isSendingUpdateProduct,
    isError: isErrorUpdateProduct,
  } = useUpdateProductMutation({ showSuccess });

  const callback = async ({
    accountID,
    planID,
    interval,
    product,
    productID,
    coupon,
  }: RequireAtLeastOne<
    {
      accountID: string;
      productID: string;
      planID: string;
      interval: ProductInterval;
      product: ProductName;
      coupon?: string;
    },
    "accountID" | "productID"
  >) => {
    if (productID) {
      const response = await doUpdateProduct({
        productID,
        planID,
        interval,
        coupon,
      });
      if (response && planID === SELECT_PRO_PLAN_ID) {
        TrackingHelper.facebookPixelTrackCustom("Upgrade", {
          plan: `select_pro_${interval}`,
        });
      }
      return response;
    } else if (accountID) {
      const response = await doCreateProduct({
        accountID,
        planID,
        interval,
        coupon,
      });
      if (response) {
        publishEvent(TOPICS.productCreated, { app: product.toLowerCase() });
      }
      return response;
    }
  };

  return tuple(callback, {
    isLoading: isSendingCreateProduct || isSendingUpdateProduct,
    isError: isErrorCreateProduct || isErrorUpdateProduct,
  });
};
