import { useUserQuery } from "@/api";
import { Sidebar } from "@/components";
import type { DownloadLinks } from "@/components/Download/utils";
import { useMounted, usePrefetchAccounts, usePrefetchPlans } from "@/hooks";
import { Menu } from "@/svg";
import { Logo } from "@narrative-software/narrative-web-ui";
import useScrollPosition from "@react-hook/window-scroll";
import classNames from "classnames";
import { useState } from "react";

type LayoutPrivateProps = {
  title: string;
  textClassName?: string;
  backgroundClassName?: string;
  headerBackgroundClassName?: string;
  mobileTheme?: "light" | "dark";
  testId?: string;
  productDownloadLinks: DownloadLinks;
  children: React.ReactNode;
};

const LayoutPrivate = ({
  title,
  textClassName,
  backgroundClassName,
  headerBackgroundClassName,
  testId,
  children,
  productDownloadLinks,
  mobileTheme = "light",
}: LayoutPrivateProps) => {
  // Prefetch data
  usePrefetchAccounts();
  usePrefetchPlans();

  const { data: userData, isLoading: isLoadingUser } = useUserQuery();

  // Scroll state
  const isMounted = useMounted();
  const scrollY = useScrollPosition(60);

  // Sidebar state
  const [open, setOpen] = useState(false);
  const openSidebar = () => setOpen(true);
  const closeSidebar = () => setOpen(false);

  return (
    <div
      data-testid={testId}
      className={classNames(
        "flex flex-1 lg:overflow-hidden",
        backgroundClassName,
        {
          "bg-mankey": !backgroundClassName,
        }
      )}
    >
      <Sidebar
        open={open}
        onClose={closeSidebar}
        productDownloadLinks={productDownloadLinks}
      />

      <div className="flex flex-col w-0 flex-1 lg:overflow-hidden">
        <div
          className={classNames(
            "fixed z-10 flex items-center justify-between w-full h-18 pl-5 lg:hidden",
            headerBackgroundClassName,
            {
              shadow: isMounted && scrollY > 0,
              "bg-mankey": !headerBackgroundClassName,
            }
          )}
        >
          <Logo colour={mobileTheme === "light" ? "black" : "white"} small>
            Narrative
          </Logo>
          <button
            type="button"
            title="Open sidebar"
            aria-label="Open sidebar"
            className={classNames(
              "flex items-center justify-center p-5 rounded-md focus:outline-none focus:bg-gray-500 focus:bg-opacity-25",
              {
                "text-white": mobileTheme === "dark",
                "text-black": mobileTheme === "light",
              }
            )}
            data-testid="nav-mobile-button"
            onClick={openSidebar}
          >
            <Menu />
          </button>
        </div>

        <main
          id="dashboard-main"
          className={classNames(
            "relative z-0 flex-1 lg:overflow-y-auto focus:outline-none"
          )}
        >
          <div
            className={classNames(
              "px-5 pb-10 lg:p-14 xl:px-22.5 xl:py-26 pt-26"
            )}
          >
            <h1
              className={classNames(
                "mb-6 text-h2 font-semibold leading-tight lg:text-38 xl:text-h1",
                textClassName,
                {
                  "text-gray-900": !textClassName,
                }
              )}
            >
              {title}
            </h1>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default LayoutPrivate;
