import { IS_PRODUCTION } from "@/config";
import Head from "next/head";

const defaultPageUrl = "https://account.narrative.so";
const defaultShareImage = "/images/share-image-narrative.jpg";
const defaultMetaTitle =
  "Industry-leading software for professional photographers.";
const defaultDescription = `Narrative software for professional photographers. Cull images with AI-powered 'Select' and create a beautiful blog in minutes with 'Publish'.`;

interface Props {
  title: string;
  metaTitle?: string;
  description?: string;
  pageUrl?: string;
  imageUrl?: string;
}

const SEO: React.FC<Props> = ({
  title,
  children,
  metaTitle = defaultMetaTitle,
  description = defaultDescription,
  pageUrl = defaultPageUrl,
  imageUrl = defaultShareImage,
}) => (
  <Head>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta key="description" name="description" content={description} />
    <meta
      key="keywords"
      name="keywords"
      content="narrative, select, publish, software, design, blog"
    />
    <meta key="og:type" property="og:type" content="website" />
    <meta key="og:title" property="og:title" content={metaTitle} />
    <meta
      key="og:description"
      property="og:description"
      content={description}
    />
    <meta key="og:image" property="og:image" content={imageUrl} />
    <meta key="og:url" property="og:url" content={pageUrl} />
    <meta
      key="twitter:card"
      property="twitter:card"
      content="summary_large_image"
    />
    <meta
      key="gsv"
      name="google-site-verification"
      content="PDMeuvJiB5iqeFKiYEWvkwueLl_c5SWC9UR-KU9q7es"
    />
    <meta
      key="viewport"
      name="viewport"
      content="width=device-width, initial-scale=1, minimum-scale=1"
    />
    <link key="favicon" rel="icon" href="/favicon.ico" />
    <link key="favicon-svg" rel="icon" type="image/svg+xml" href="/icon.svg" />
    <link
      key="apple-touch-icon"
      rel="apple-touch-icon"
      href="/apple-touch-icon.png"
    />
    {IS_PRODUCTION && (
      <link key="manifest" rel="manifest" href="/manifest.webmanifest" />
    )}
    {children}
  </Head>
);

export default SEO;
