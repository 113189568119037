import {
  Api,
  IProductObject,
  IRewardObject,
  ProductName,
  useProductsQuery,
  useRewardsQuery,
  useUserQuery,
} from "@/api";
import { SEO } from "@/components";
import {
  DownloadLinks,
  resolveDownloadLinks,
} from "@/components/Download/utils";
import { ROUTES } from "@/config";
import {
  LayoutPrivate,
  InfoContainer,
  InviteModal,
  ReferralSignUps,
  ThreeStep,
  ShareLink,
  Invite,
} from "@/containers";
import { DataHelper } from "@/helpers";
import { withAuth } from "@/hoc";
import { accordionFragment, AccordionFragmentType, Accordion } from "@/modules";
import { useModalDispatch } from "@/providers";
import EnvelopeIcon from "@/svg/envelope.svg";
import HeartEyesIcon from "@/svg/heart-eyes.svg";
import SignUpIcon from "@/svg/sign-up.svg";
import {
  AnchorButton,
  ModalActionType,
  Spinner,
} from "@narrative-software/narrative-web-ui";
import { GetStaticProps } from "next";
import Link from "next/link";
import { Props, useEffect } from "react";

export const REFERRALS_PAGE_VISITED_STORAGE_KEY =
  "narrative-has-visited-referrals-page";

const getRewardsByProductName = (
  productName: ProductName,
  rewards: IRewardObject[],
  products: IProductObject[]
) => {
  return rewards.filter((reward) => {
    return (
      productName ===
      DataHelper.getProductById(reward["rewarder-product-id"], products, true)
        ?.attributes.name
    );
  });
};

const getProductTitle = (
  isSubscribedToSelect: boolean,
  isSubscribedToPublish: boolean
) => {
  if (isSubscribedToSelect && isSubscribedToPublish)
    return "Select and Publish";
  if (isSubscribedToSelect) return "Select";
  if (isSubscribedToPublish) return "Publish";
  return "";
};

type ReferralsPageCMSProps = {
  faqs: Nullable<AccordionFragmentType>;
};

type ReferralsPageProps = ReferralsPageCMSProps & {
  productDownloadLinks: DownloadLinks;
};

const ReferralsPage = ({ faqs, productDownloadLinks }: ReferralsPageProps) => {
  const modalDispatch = useModalDispatch();

  const { data: userData, isLoading: isLoadingUsers } = useUserQuery();
  const { data: rewardsData, isLoading: isLoadingRewards } = useRewardsQuery();
  const { data: productsData, isLoading: isLoadingProducts } =
    useProductsQuery();

  const products = productsData?.data;
  const attributes = rewardsData?.data.attributes;
  const rewards = attributes?.rewards;
  const email = userData?.data.attributes.email || "";
  const code = attributes?.["referral-code"] || "";
  const isReferrer = attributes?.["scheme-type"] === "referral_scheme";
  const isLoading =
    !userData ||
    !rewardsData ||
    !productsData ||
    isLoadingUsers ||
    isLoadingRewards ||
    isLoadingProducts;

  // To remove dot from nav item
  useEffect(() => {
    window.localStorage.setItem(REFERRALS_PAGE_VISITED_STORAGE_KEY, "true");
  }, []);

  // Select
  const isSubscribedToSelect = Boolean(
    products && DataHelper.getProductByName(ProductName.Select, products, true)
  );
  const selectRewards =
    (rewards &&
      products &&
      isSubscribedToSelect &&
      getRewardsByProductName(ProductName.Select, rewards, products)) ||
    undefined;

  // Publish
  const isSubscribedToPublish = Boolean(
    products && DataHelper.getProductByName(ProductName.Publish, products, true)
  );
  const publishRewards =
    (rewards &&
      products &&
      isSubscribedToPublish &&
      getRewardsByProductName(ProductName.Publish, rewards, products)) ||
    undefined;

  const isOnlySubscribedToSelect =
    isSubscribedToSelect && !isSubscribedToPublish;
  const isOnlySubscribedToPublish =
    isSubscribedToPublish && !isSubscribedToSelect;
  const openInviteModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <InviteModal
            productTitle={getProductTitle(
              isSubscribedToSelect,
              isSubscribedToPublish
            )}
            {...{ code, email, isSubscribedToSelect, isSubscribedToPublish }}
          />
        ),
      },
    });
  };

  return (
    <LayoutPrivate
      title="Get Pro Free"
      testId="page-referrals"
      productDownloadLinks={productDownloadLinks}
    >
      <SEO title="Get Pro Free | Narrative" />

      <div className="max-w-260 xl:mt-8">
        {isLoading ? (
          <Spinner />
        ) : !isReferrer ? (
          <Link href={`/${ROUTES.AMBASSADORS.SLUG}`} passHref>
            <AnchorButton colour="black" className="max-w-70">
              Go to {ROUTES.AMBASSADORS.LABEL}
            </AnchorButton>
          </Link>
        ) : (
          <>
            <InfoContainer
              title={
                isOnlySubscribedToSelect
                  ? "Refer 4 friends to Narrative Select, get a year of Select Pro FREE!"
                  : "Refer 4 friends to Narrative – get a year on Pro FREE!"
              }
            >
              <ThreeStep className="mb-2 sm:mb-10 lg:mb-14">
                <ThreeStep.Step
                  icon={EnvelopeIcon}
                  title={
                    isOnlySubscribedToSelect ? (
                      <>
                        Share and{" "}
                        <button
                          className="font-semibold underline focus:outline-none"
                          onClick={openInviteModal}
                        >
                          invite
                        </button>
                      </>
                    ) : (
                      "Share"
                    )
                  }
                >
                  {isOnlySubscribedToSelect ? (
                    <>
                      Share your personalised referral link (below) with
                      friends, or get us to{" "}
                      <button
                        className="underline focus:outline-none"
                        onClick={openInviteModal}
                      >
                        invite them
                      </button>
                      .
                    </>
                  ) : (
                    "Share your personalised referral link (below) with friends."
                  )}
                </ThreeStep.Step>
                <ThreeStep.Step
                  icon={SignUpIcon}
                  title={
                    isOnlySubscribedToSelect
                      ? "4 friends sign up"
                      : "Your friends sign up"
                  }
                >
                  {isOnlySubscribedToSelect
                    ? "Track how many friends have signed up for Select Pro via the dashboard below."
                    : "Track how many friends have signed up to each product via the dashboards below."}
                </ThreeStep.Step>
                <ThreeStep.Step
                  icon={HeartEyesIcon}
                  title={
                    isOnlySubscribedToSelect
                      ? "You get Select Pro, free!"
                      : "You get Pro, free!"
                  }
                >
                  {isOnlySubscribedToSelect
                    ? "Once you’ve signed up 4 friends to Select, enjoy Select Pro features for free, for 1 year!"
                    : "Sign up 4 friends to Select and get Select Pro free for 1 year. Sign up 4 friends to Publish and get Publish Pro free for 1 year!"}
                </ThreeStep.Step>
              </ThreeStep>

              <ShareLink
                type="referral"
                code={code}
                product={
                  isOnlySubscribedToSelect
                    ? "select"
                    : isOnlySubscribedToPublish
                    ? "publish"
                    : undefined
                }
              />

              {(isSubscribedToSelect || isSubscribedToPublish) && (
                <Invite
                  {...{
                    code,
                    email,
                    isSubscribedToSelect,
                    isSubscribedToPublish,
                  }}
                />
              )}
            </InfoContainer>

            {isSubscribedToSelect && (
              <ReferralSignUps
                product={ProductName.Select}
                rewards={selectRewards}
                className="mt-6 xl:mt-10"
              />
            )}

            {isSubscribedToPublish && (
              <ReferralSignUps
                product={ProductName.Publish}
                rewards={publishRewards}
                className="mt-6 xl:mt-10"
              />
            )}

            {faqs && (
              <section className="mt-12 lg:mt-14 xl:mt-18">
                <Accordion {...faqs} />
              </section>
            )}
          </>
        )}
      </div>
    </LayoutPrivate>
  );
};

/**
 * Static props
 */
export const getStaticProps: GetStaticProps<ReferralsPageProps> = async () => {
  const response = await Api.datocmsGraphqlRequest<{
    data: ReferralsPageCMSProps;
  }>({
    query: `
      {
        faqs: moduleAccordion(filter: { id: { eq: "5884732" }}) {
          ...accordionFragment,
        }
      }
      ${accordionFragment}
    `,
  });

  const productDownloadLinks = await resolveDownloadLinks();

  return {
    props: {
      faqs: response.data?.faqs || null,
      productDownloadLinks,
    },
  };
};

export default withAuth(ReferralsPage);
