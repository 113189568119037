import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

export interface IAccountState {
  accountID: string;
  isLoading: boolean;
  setAccountID: Dispatch<SetStateAction<string>>;
}

const initialState: IAccountState = {
  accountID: "",
  isLoading: true,
  setAccountID: () => {},
};

const AccountContext = createContext<IAccountState>(initialState);

export const AccountProvider: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [accountID, setAccountID] = useState(initialState.accountID);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <AccountContext.Provider value={{ accountID, isLoading, setAccountID }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAccountContext must be used within an AccountProvider");
  }
  return context;
};
