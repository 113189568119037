import { Button } from "@narrative-software/narrative-web-ui";
import React from "react";

type AssociateGoogleAccountWarningModalProps = {
  onConfirm: () => void;
};

const AssociateGoogleAccountWarningModal = ({
  onConfirm,
}: AssociateGoogleAccountWarningModalProps) => {
  return (
    <div>
      <h3 className="text-h3 font-semibold mb-6">
        Heads up, this might change a few things
      </h3>
      <p className="mb-4">
        If you connect a Google account that uses a different email address to
        the one that’s currently associated to your Narrative account, you’ll no
        longer be able to sign in with that email address and password. In the
        future, you’ll need to use the ‘Sign in with Google’ button.
      </p>
      <p>
        {" "}
        We’ll also use the associated Gmail address to contact you going
        forwards, which may be different to the email address currently
        associated to your account.
      </p>
      <div className="mt-8">
        <Button colour="black" className="" onClick={onConfirm}>
          Ok, got it
        </Button>
      </div>
    </div>
  );
};

export { AssociateGoogleAccountWarningModal };
