import { useForgotPasswordMutation } from "@/api";
import TextField from "@material-ui/core/TextField";
import { Button } from "@narrative-software/narrative-web-ui";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface IForgotPasswordFields {
  email: string;
}

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required"),
});

const ForgotPasswordForm: React.FC = () => {
  const { mutateAsync: doForgotPassword, isLoading } =
    useForgotPasswordMutation();

  const handleSubmit = async (
    values: IForgotPasswordFields,
    actions: FormikHelpers<IForgotPasswordFields>
  ) => {
    try {
      const { email } = values;
      await doForgotPassword({ email });
      actions.resetForm({});
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
      }) => (
        <Form noValidate>
          <div>
            <TextField
              name="email"
              id="forgot-password-email-input"
              label="Email"
              type="email"
              value={values.email}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ "data-testid": "forgot-password-email-input" }}
              fullWidth
            />
          </div>

          <div className="mt-7">
            <Button
              type="submit"
              colour="black"
              disabled={!dirty}
              isLoading={isSubmitting || isLoading}
              showLoader
              testId="forgot-password-submit-button"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
