import { AccountBillingType, useAccountQuery } from "@/api";
import { LinkIcon } from "@/components/icons/LinkIcon";
import { UpdatePaymentForm } from "@/containers";
import { useQueryParams } from "@/hooks";
import { useModalDispatch } from "@/providers";
import { formatCreditCardDate } from "@/utils";
import { TextField } from "@material-ui/core";
import {
  Button,
  ButtonColour,
  ButtonAppearance,
  Spinner,
  ModalActionType,
} from "@narrative-software/narrative-web-ui";
import { useEffect } from "react";

type Props = {
  buttonColour?: ButtonColour;
  buttonAppearance?: ButtonAppearance;
  showRemoveButton?: boolean;
  showUpdateMessage?: boolean;
};

export const Payment: React.FC<Props> = ({
  buttonColour = "black",
  buttonAppearance = "primary",
  showRemoveButton = false,
  showUpdateMessage = false,
}) => {
  const queryParams = useQueryParams();
  const modalDispatch = useModalDispatch();
  const { data: accountData, isLoading } = useAccountQuery();

  const billingData = accountData?.data.attributes.billing;

  const ccNumber = billingData?.last4
    ? `**** **** **** ${billingData.last4}`
    : "";

  const isLink = billingData?.type === AccountBillingType.Link

  const isBillingData = Boolean(ccNumber) || isLink;
  const ccExpiry =
    billingData?.["exp-month"] && billingData?.["exp-year"]
      ? formatCreditCardDate(billingData["exp-month"], billingData["exp-year"])
      : "";

  // Open payment modal on load if query param is set
  useEffect(() => {
    if (queryParams["change-card"]) {
      openPaymentModal();
    }
  }, [queryParams["change-card"]]);

  const closeModal = () => {
    modalDispatch({ type: ModalActionType.Close });
  };

  const openIntercom = () => {
    window?.Intercom?.("show");
    closeModal();
  };

  const openRemoveModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <>
            <h3 className="mb-6 text-h3 font-semibold leading-tight">
              Remove payment details
            </h3>
            <p className="mb-8">
              Please{" "}
              <button
                type="button"
                className="underline"
                onClick={openIntercom}
              >
                click here
              </button>{" "}
              to contact support to remove your payment details.
            </p>
            <div className="sm:max-w-40 sm:ml-auto">
              <Button colour="black" onClick={closeModal}>
                Close
              </Button>
            </div>
          </>
        ),
      },
    });
  };

  const openPaymentModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <>
            <h3 className="text-h3 font-semibold mb-8 mt-6 sm:mt-0">
              {isBillingData ? "Update" : "Add"} payment details
            </h3>

            {showUpdateMessage && (
              <p className="-mt-2 mb-9">
                {isBillingData ? "Updating" : "Adding"} your details will apply
                to both products.
              </p>
            )}

            <UpdatePaymentForm
              closeModal={closeModal}
              buttonText={isBillingData ? "Update" : "Add"}
            />
          </>
        ),
      },
    });
  };

  // Loading state
  if (!accountData || isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col">
      {isBillingData ? (
        <div className="grid grid-cols-1 gap-y-5 mb-6 md:mb-10">
          {Boolean(ccNumber)? (
            <>
              <TextField disabled label="Card number" value={ccNumber} />
              <TextField disabled label="Expiry" value={ccExpiry} />
            </>
            
          ) : isLink? (
            <div className="flex">
              <dd className="mr-2 flex-shrink-0 font-semibold">
                <LinkIcon />
              </dd>
              <dt>Payment information connected via Link</dt>
            </div>
          ): null}
        </div>
      ) : (
        <p className="text-sm -mt-2 mb-6">No credit card has been added.</p>
      )}

      <Button
        colour={buttonColour}
        appearance={buttonAppearance}
        onClick={openPaymentModal}
      >
        {isBillingData ? "Change" : "Add"} card
      </Button>

      {showRemoveButton && isBillingData ? (
        <div className="mt-4.5 text-11 text-center">
          <button type="button" className="underline" onClick={openRemoveModal}>
            Remove card
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Payment;
