import { useUpdateAccountMutation } from "@/api";
import { RequestHelper } from "@/helpers";
import { useAccountContext, useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";

type Props = {
  subject: "address" | "payment";
};

const RemoveDetailsModal: React.FC<Props> = ({ subject }) => {
  const modalDispatch = useModalDispatch();
  const { accountID } = useAccountContext();
  const { mutateAsync: doUpdateAccount, isLoading } =
    useUpdateAccountMutation();

  const title = subject === "address" ? "billing address" : "payment details";

  // Handle back click
  const handleBackClick = () => {
    modalDispatch({ type: ModalActionType.Close });
  };

  // Handle confirm click
  const handleConfirmClick = async () => {
    let body = RequestHelper.getUpdatePaymentRequestBody({
      id: accountID,
      token: "",
    });

    if (subject === "address") {
      body = RequestHelper.getUpdateBillingAddressRequestBody({
        id: accountID,
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          "postal-code": "",
          country: "",
        },
      });
    }

    await doUpdateAccount(body);
    modalDispatch({ type: ModalActionType.Close });
  };

  return (
    <>
      <h3 className="mb-6 text-h3 font-semibold leading-tight">
        Remove {title}
      </h3>
      <p className="mb-8">Are you sure you want to remove your {title}?</p>
      <div className="flex space-x-2.5 sm:max-w-80 sm:ml-auto">
        <Button
          appearance="secondary"
          colour="gray-600"
          onClick={handleBackClick}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          colour="black"
          onClick={handleConfirmClick}
          isLoading={isLoading}
          showLoader
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default RemoveDetailsModal;
