type ServicesCreditPurchase = {
  data: {
    attributes: {
      id: Uuid;
      "error-code"?: "card_declined";
      "client-secret"?: string;
      count: number;
      "inserted-at": IsoDate;
      "pack-id": Uuid;
      relationships: {};
      status: "pending" | "succeeded";
      type: "credit-purchases";
      "updated-at": IsoDate;
    };
  };
};

type CreditPurchase = {
  errorCode?: "card_declined";
  clientSecret?: string;
  purchaseId: Uuid;
  count: number;
  insertedAt: IsoDate;
  packId: Uuid;
  status: "pending" | "succeeded";
  updatedAt: IsoDate;
};

type CreditsPurchaseParams = {
  accountId: Uuid;
  packId: Uuid;
  quantity: number;
};

function deserializeCreditPurchase({
  data: {
    attributes: {
      id,
      "error-code": errorCode,
      "client-secret": clientSecret,
      count,
      "inserted-at": insertedAt,
      "pack-id": packId,
      status,
      "updated-at": updatedAt,
    },
  },
}: ServicesCreditPurchase): CreditPurchase {
  return {
    purchaseId: id,
    errorCode,
    clientSecret,
    status,
    packId,
    count,
    insertedAt,
    updatedAt,
  };
}

export type { CreditsPurchaseParams, ServicesCreditPurchase, CreditPurchase };
export { deserializeCreditPurchase };
