/**
 * Unique keys used for caching and refetching data in React Query
 */
export const LOGIN_KEY = "SESSION_LOGIN";

export const GET_ACCOUNT_KEY = "GET_ACCOUNT";
export const GET_ACCOUNTS_KEY = "GET_ACCOUNTS";
export const GET_USER_KEY = "GET_USER";
export const GET_PLANS_KEY = "GET_PLANS";
export const GET_GRANDFATHERED_PLANS_KEY = "GET_GRANDFATHERED_PLANS";
export const GET_EDIT_CREDIT_PACKS_KEY = "GET_EDIT_CREDIT_PACKS";
export const GET_EDIT_CREDITS_BALANCE_KEY = "GET_EDIT_CREDITS_BALANCE";
export const GET_HAS_EDIT_KEY = "GET_HAS_EDIT";
export const GET_SELECT_FREE_PROJECT_THRESHOLD_KEY =
  "GET_SELECT_FREE_PROJECT_THRESHOLD";
export const GET_PRODUCTS_KEY = "GET_PRODUCTS";
export const GET_REWARDS_KEY = "GET_REWARDS";
export const GET_COUPON_KEY = "GET_COUPON";
export const GET_PROJECTS_PUBLISH_KEY = "GET_PROJECT_PUBLISH";
export const GET_AFFILIATE_CLICKS_KEY = "GET_AFFILIATE_CLICKS";
export const GET_AFFILIATE_SIGN_UPS_KEY = "GET_AFFILIATE_SIGN_UPS";

export const PREVIEW_CHARGE_KEY = "PREVIEW_CHARGE";
export const PREVIEW_CHARGE_SIGN_UP_KEY = "PREVIEW_CHARGE_SIGN_UP";
export const PREVIEW_CHARGE_NEW_KEY = "PREVIEW_CHARGE_NEW";
export const PREVIEW_CHARGE_EXISTING_KEY = "PREVIEW_CHARGE_EXISTING";
