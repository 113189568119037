import { EyeOpen, EyeClosed } from "@/svg";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  InputProps,
  TextFieldProps,
} from "@material-ui/core";
import { useState } from "react";

type Props = InputProps &
  Pick<TextFieldProps, "label" | "helperText"> & {
    showVisibilityToggle?: boolean;
  };

const PasswordField: React.FC<Props> = ({
  id,
  label,
  error,
  helperText,
  fullWidth,
  showVisibilityToggle,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        {...{ id, fullWidth, ...rest }}
        type={visible ? "text" : "password"}
        endAdornment={
          showVisibilityToggle && (
            <InputAdornment position="end">
              <button
                type="button"
                className="py-2 pl-2 text-11 font-semibold uppercase text-gray-600 hover:text-stealth-bomber focus:outline-none"
                onClick={handleVisibleChange}
              >
                {visible ? <EyeClosed /> : <EyeOpen />}
              </button>
            </InputAdornment>
          )
        }
      />
      {helperText && (
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default PasswordField;
