const EyeClosed: React.FC = () => (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0054 13.9868L4.70288 0.684327L5.3916 -0.00439453L7.16407 1.76807C8.36259 1.30096 9.65622 1 11 1C17 1 22 7 22 7C22 7 19.8715 9.55417 16.7256 11.3296L18.6941 13.2981L18.0054 13.9868ZM11 2C9.97621 2 9.02429 2.3077 8.23166 2.83567L10.1135 4.71751C10.3861 4.61296 10.682 4.55566 10.9914 4.55566C12.3455 4.55566 13.4433 5.6534 13.4433 7.00752C13.4433 7.31687 13.386 7.61285 13.2814 7.88541L15.1643 9.76834C15.6923 8.97571 16 8.02379 16 7C16 4.23858 13.7614 2 11 2ZM15.8832 10.4872L15.9897 10.5937C16.7676 10.1732 17.4937 9.69224 18.15 9.2C19.1036 8.48482 19.8803 7.76798 20.4179 7.23039C20.4991 7.14916 20.5748 7.07215 20.6446 7C20.5748 6.92786 20.4991 6.85084 20.4179 6.76961C19.8803 6.23202 19.1036 5.51518 18.15 4.8C17.4068 4.24259 16.5741 3.69966 15.6781 3.24257C16.5051 4.27091 17 5.57766 17 7C17 8.30051 16.5862 9.50439 15.8832 10.4872ZM6.86249 4.19182C6.31813 4.99229 6 5.95899 6 7C6 9.76142 8.23858 12 11 12C12.041 12 13.0077 11.6819 13.8082 11.1375L14.8838 12.2132C13.6719 12.691 12.3617 13 11 13C5 13 0 7 0 7C0 7 2.14774 4.42272 5.31711 2.64644L6.05363 3.38297C5.25939 3.80898 4.5185 4.29863 3.85 4.8C2.89643 5.51518 2.11969 6.23202 1.58211 6.76961C1.50087 6.85084 1.42523 6.92786 1.35539 7C1.42523 7.07215 1.50087 7.14916 1.58211 7.23039C2.11969 7.76798 2.89643 8.48482 3.85 9.2C4.59322 9.75741 5.42592 10.3003 6.32194 10.7574C5.49492 9.72909 5 8.42234 5 7C5 5.68235 5.42474 4.46391 6.14478 3.47411L6.86249 4.19182ZM8.53955 7.00752C8.53955 6.67192 8.60697 6.35207 8.72902 6.06078L11.9381 9.2699C11.6468 9.39194 11.327 9.45937 10.9914 9.45937C9.63728 9.45937 8.53955 8.36164 8.53955 7.00752Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeClosed;
