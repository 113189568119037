import { ROUTES } from "@/config";
import { useAlertDispatch, useAuthContext } from "@/providers";
import {
  AlertActionType,
  AlertAction,
} from "@narrative-software/narrative-web-ui";
import { Dispatch, SetStateAction } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export let queryClient: QueryClient;

const routesWithLogin = [
  `/${ROUTES.LOGIN.SLUG}`,
  `/${ROUTES.SIGN_UP.SLUG}`,
  `/${ROUTES.GET_STARTED.SELECT.SLUG}`,
  `/${ROUTES.GET_STARTED.PUBLISH.SLUG}`,
];

// Error handler
export const handleErrorResponse = (
  error: any,
  alertDispatch: Dispatch<AlertAction>,
  setAuthenticated: Dispatch<SetStateAction<boolean>>
) => {
  const showAlert = ({
    title,
    content,
  }: {
    title: string;
    content?: React.ReactNode;
  }) => {
    alertDispatch({
      type: AlertActionType.SetContent,
      payload: {
        type: "error",
        title,
        children: content,
      },
    });
  };

  // Authentication error
  if (
    error.response?.status === 401 &&
    !routesWithLogin.includes(window.location.pathname)
  ) {
    showAlert({
      title: "Session expired",
      content: <p>Please login and try again.</p>,
    });
    setAuthenticated(false);
    return;
  }

  // Error response received
  if (Array.isArray(error.response?.data?.errors)) {
    const { title, detail, code } = error.response.data.errors[0];
    showAlert({
      title,
      content: (
        <>
          <p>
            {typeof detail === "string"
              ? detail
              : detail.message || "Unknown server error"}
          </p>
          {code && <p className="mt-1 text-xxs">Code: {code}</p>}
        </>
      ),
    });
    return;
  }

  // Request made but no response received
  if (error.request) {
    showAlert({
      title: "Response not received",
      content: (
        <p>
          A response hasn’t been received or the request was never sent. Please
          try again.
        </p>
      ),
    });
    return;
  }

  // Simple error message
  if (error.message) {
    showAlert({ title: error.message });
    return;
  }

  // Utter failure
  showAlert({ title: "An unknown error occurred" });
};

// Query provider
export const QueryProvider: React.FC = ({ children }) => {
  const alertDispatch = useAlertDispatch();
  const { setAuthenticated } = useAuthContext();

  const onError = (error: any) =>
    handleErrorResponse(error, alertDispatch, setAuthenticated);

  if (!queryClient) {
    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: 1,
          staleTime: Infinity,
          cacheTime: 60 * 60 * 1000, // 60 minutes in milliseconds
          onError,
        },
        mutations: {
          retry: 1,
          onError,
        },
      },
    });
  }

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
