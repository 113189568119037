import { ILinks } from "./global";

export enum CouponDuration {
  Once = "once",
  Forever = "forever",
  Months = "months",
}

export interface ICouponAttributes {
  name: string;
  code: string;
  valid: boolean;
  duration: CouponDuration;
  created: string;
  "redeem-by": string | null;
  "percent-off": number;
  "duration-in-months": number | null;
  "amount-off": number;
}

export interface ICouponObject {
  type: "coupons";
  relationships: null;
  links: ILinks;
  id: string;
  attributes: ICouponAttributes;
}

export interface ICouponResponse {
  links: ILinks;
  included: [];
  data: ICouponObject;
}
