import { GoogleIcon } from "./GoogleIcon";
import { Button, ButtonProps } from "@narrative-software/narrative-web-ui";
import React from "react";

type GoogleButtonProps = {} & Omit<ButtonProps, "appearance" | "colour">;

const GoogleButton = React.forwardRef<HTMLButtonElement, GoogleButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        colour="white"
        style={{ color: "#1c1c1c", borderColor: "#e0e0e0" }}
        {...rest}
      >
        <span className="flex items-center">
          <span className="mr-2.5 shrink-0">
            <GoogleIcon />
          </span>
          <span className="leading-loose">{children}</span>
        </span>
      </Button>
    );
  }
);

GoogleButton.displayName = "GoogleButton";

export { GoogleButton };
