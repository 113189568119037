import classNames from "classnames";

type Props = {
  className?: string;
  anchorOffsetFromEdge?: number;
  alignment?: "left" | "center" | "right";
  content: React.ReactNode;
};

const anchorSize = 7; // Size in px of the little triangle knob thing.
const anchorYOffset = 1; // Offset in px from bottom of tooltip 'host' element.
const defaultAnchorInset = 20; // Distance from side of tooltip to the triangle knob (when left or right aligned).

const Tooltip: React.FC<Props> = ({
  className,
  anchorOffsetFromEdge,
  alignment,
  content,
  children,
}) => {
  const isLeft = alignment === "left";
  const isRight = alignment === "right";
  const isCenter = !alignment || alignment === "center";
  const sideInsetStyle = `${
    (anchorOffsetFromEdge || defaultAnchorInset) - anchorSize
  }px`;

  return (
    <div
      className={classNames(
        "relative flex flex-col group select-none",
        className
      )}
    >
      <div className="flex flex-col">{children}</div>
      <div
        className={classNames("absolute w-0 h-0 z-20 flex flex-col", {
          "left-0 items-start": alignment === "left",
          "right-0 items-end": alignment === "right",
          "left-1/2 items-center": !alignment || alignment === "center",
        })}
        style={{ bottom: `-${anchorSize + anchorYOffset}px` }}
      >
        <div className="relative pointer-events-none bg-gray-900 shadow-button p-4 opacity-0 transition-opacity duration-75 group-hover:opacity-100 sm:group-hover:delay-200">
          <div
            className="absolute w-0 h-0 border-solid"
            style={{
              borderWidth: `${anchorSize}px`,
              left: isLeft
                ? sideInsetStyle
                : isCenter
                ? `calc(50% - ${anchorSize}px)`
                : undefined,
              right: isRight ? sideInsetStyle : undefined,
              top: `-${2 * anchorSize}px`,
              borderBottomColor: "#1c1c1c",
              borderLeftColor: "transparent",
              borderRightColor: "transparent",
              borderTopColor: "transparent",
            }}
          />
          <div className="relative text-white text-xs font-semibold whitespace-nowrap">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
