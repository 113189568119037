import { ServicesCreditBalanceResponse } from "./interfaces/creditBalance";
import {
  ServicesCreditPurchase,
  CreditsPurchaseParams,
} from "./interfaces/creditPurchase";
import {
  ServicesCreditPurchasingPack,
  ServicesCreditPurchasingPacksResponse,
} from "./interfaces/editCreditPacks";
import { ServicesEditRollout } from "./interfaces/editRollout";
import {
  ProductInterval,
  PlanStatus,
  GraphqlRequest,
  ILoginResponse,
  IGetAccountsResponse,
  IGetAccountResponse,
  ICreateAccountRequest,
  ICreateAccountResponse,
  IUserResponse,
  IGetPlansResponse,
  IProductResponse,
  IGetProductsResponse,
  IUpdateAccountRequest,
  IGetRewardsResponse,
  ICouponResponse,
  IPreviewChargeResponse,
  IGetProjectsResponse,
  IUploadUrlResponse,
  IRefreshTokenResponse,
  IAffiliateResponse,
  IAffiliateClicksResponse,
  IAffiliateSignUpsResponse,
} from "@/api";
import {
  API_URL,
  BETA_FEATURES_CONFIG_URL,
  DATOCMS_API_TOKEN,
  SELECT_FREE_PROJECT_THRESHOLD_ENDPOINT,
} from "@/config";
import {
  ILoginFields,
  IUserDetailsFields,
  IForgotPasswordFields,
  IResetPasswordFields,
} from "@/containers";
import { AuthHelper, RequestHelper } from "@/helpers";
import { queryClient } from "@/providers";
import { Product } from "@/types";
import axios, { AxiosResponse, AxiosError } from "axios";
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig,
} from "axios-auth-refresh";

// Get API endpoint
export const getApiEndpoint = (endpoint: string) =>
  `${API_URL}/api/${endpoint}`;

// Get request headers
const getHeaders = (isAuthRequest = true, version = 2) => ({
  "Content-Type": "application/vnd.api+json",
  Accept: `application/vnd.api.v${version}+json`,
  ...(isAuthRequest && AuthHelper.isToken() ? AuthHelper.getAuthHeader() : {}),
});

// Handle response
const handleResponse = <T>(response: AxiosResponse<T>): T => {
  const refreshedToken = response?.headers?.["x-refresh-token"];
  if (refreshedToken) AuthHelper.setToken(refreshedToken);
  return response?.data;
};

// Handle session expire
const handleError = <T>(error: AxiosError<T>) => {
  throw error;
};

// Handle refresh token
const onRefreshToken = async (failedRequest: AxiosError) => {
  try {
    const refreshToken = AuthHelper.getRefreshToken();

    if (!refreshToken || !failedRequest.response) {
      return Promise.reject(failedRequest);
    }

    // For some reason if I use the API module directly, the interceptor doesn't wait for the promise to resolve
    const response = (await axios.post(
      getApiEndpoint("log-in"),
      RequestHelper.getRefreshTokenRequestBody(refreshToken),
      { headers: getHeaders(false, 3) }
    )) as IRefreshTokenResponse;

    if (!response.access_token) {
      return Promise.reject(failedRequest);
    }

    AuthHelper.setToken(response.access_token);

    if (!failedRequest.response.config.headers) {
      failedRequest.response.config.headers = {};
    }

    failedRequest.response.config.headers.Authorization = `Bearer ${response.access_token}`;

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

// Add response interceptors
axios.interceptors.response.use(handleResponse, handleError);
createAuthRefreshInterceptor(axios, onRefreshToken);

// Request types
const request = {
  GET: <T>(
    endpoint: string,
    params = {},
    isAuthRequest = true,
    version = 2
  ): Promise<T> =>
    axios.get(getApiEndpoint(endpoint), {
      params,
      headers: getHeaders(isAuthRequest, version),
    }),

  POST: <T>(
    endpoint: string,
    body = {},
    isAuthRequest = true,
    version = 2
  ): Promise<T> =>
    axios.post(getApiEndpoint(endpoint), body, {
      headers: getHeaders(isAuthRequest, version),
      skipAuthRefresh: !isAuthRequest,
    } as AxiosAuthRefreshRequestConfig),

  PATCH: <T>(endpoint: string, body = {}): Promise<T> =>
    axios.patch(getApiEndpoint(endpoint), body, {
      headers: getHeaders(),
    }),

  DELETE: <T>(endpoint: string): Promise<T> =>
    axios.delete(getApiEndpoint(endpoint), {
      headers: getHeaders(),
    }),
};

// API request parameters
export type CreateProductParameters = {
  accountID: string;
  planID: string;
  interval: ProductInterval;
  coupon?: string;
};

export type UpdateProductParameters = {
  productID: string;
  planID: string;
  interval: ProductInterval;
  coupon?: string;
};

export type DeleteProductParameters = {
  accountID: string;
  productID: string;
};

// API requests
export const Api = {
  /**
   * Login
   * Create a user session
   */
  login: ({ email, password }: ILoginFields) => {
    queryClient?.clear();
    const body = RequestHelper.getLoginRequestBody({ email, password });
    return request.POST<ILoginResponse>("log-in", body, false, 3);
  },

  /**
   * Refresh Token
   * Retrieve a new access token
   */
  refreshToken: (refreshToken: string) => {
    queryClient?.clear();
    const body = RequestHelper.getRefreshTokenRequestBody(refreshToken);
    return request.POST<IRefreshTokenResponse>("log-in", body, false, 3);
  },

  /**
   * Swap Web JWT for Select JWT
   */
  swapWebTokenForSelectToken: () =>
    request.GET<ILoginResponse>("select/token", undefined, undefined, 3),

  /**
   * Swap Web JWT for Publish JWT
   */
  swapWebTokenForPublishToken: () =>
    request.GET<ILoginResponse>("publish/token", undefined, undefined, 3),

  /**
   * Get account
   * Retrieve a given Account by the ID.
   */
  getAccount: (id: string, include?: string[]) => {
    const params: { include?: string } = {};
    if (include) params.include = include.join();
    return request.GET<IGetAccountResponse>(`accounts/${id}`, params);
  },

  /**
   * Get accounts
   * Retrieve a list of Accounts that the user is associated to.
   */
  getAccounts: (include?: string[]) => {
    const params: { include?: string } = {};
    if (include) params.include = include.join();
    return request.GET<IGetAccountsResponse>("accounts", params);
  },

  /**
   * Create account
   * Create a new Account, along with the 'owning' User, and a Product.
   */
  createAccount: (body: ICreateAccountRequest) => {
    return request.POST<ICreateAccountResponse>("accounts", body, false);
  },

  /**
   * Update account
   * Updates account details of the currently logged in User.
   */
  updateAccount: (body: IUpdateAccountRequest) => {
    return request.PATCH<IGetAccountResponse>(`accounts/${body.data.id}`, body);
  },

  /**
   * Delete account
   */
  deleteAccount: (id: string) => {
    return request.DELETE<void>(`accounts/${id}`);
  },

  /**
   * Get products
   * Retrieve a list of Products that the Account is subscribed to.
   */
  getProducts: (accountID: string) => {
    return request.GET<IGetProductsResponse>(
      `accounts/${accountID}/relationships/products`,
      undefined,
      true,
      3
    );
  },

  /**
   * Create product
   * Subscribe to a new Product. Performing this action is a charge event, and the Account will be charged money.
   */
  createProduct: ({ accountID, ...rest }: CreateProductParameters) => {
    const body = RequestHelper.getCreateProductRequestBody(rest);
    return request.PATCH<IProductResponse>(
      `accounts/${accountID}/relationships/products`,
      body
    );
  },

  /**
   * Update product
   * Update a Product. In other words, change the current plan subscription for the Product, be it via the plan_id or the interval (or both).
   * Performing this action is a charge event, and the Account will be charged money.
   */
  updateProduct: ({ productID, ...rest }: UpdateProductParameters) => {
    const body = RequestHelper.getUpdateProductRequestBody({
      productID,
      ...rest,
    });
    return request.PATCH<IProductResponse>(`products/${productID}`, body);
  },

  /**
   * Delete product
   * Retrieve a list of Products that the Account is subscribed to.
   */
  deleteProduct: ({ accountID, productID }: DeleteProductParameters) => {
    return request.DELETE<IProductResponse>(
      `accounts/${accountID}/relationships/products/${productID}`
    );
  },

  /**
   * Get User
   * Retrieve the currently 'logged in' User.
   */
  getUser: (include?: string[]) => {
    const params: { include?: string } = {};
    if (include) params.include = include.join();
    return request.GET<IUserResponse>("user", params);
  },

  /**
   * Update User
   * Updates the parameters of the currently 'logged in' User.
   */
  updateUser: (data: IUserDetailsFields & { id: string }) => {
    const body = RequestHelper.getUpdateUserRequestBody(data);
    return request.PATCH<IUserResponse>("user", body);
  },

  /**
   * Disconnect Google Account
   * Removes the Google Account from the currently 'logged in' User as an authentication method.
   */
  disconnectUserGoogleAccount: () => {
    return request.DELETE<IUserResponse>("user/auth_provider/google");
  },

  /**
   * Associate Google Account
   * Associates a Google Account to the currently 'logged in' User as an authentication method.
   */
  associateUserGoogleAccount: (data: {
    email: string;
    oauth_provider: "google";
    oauth_provider_user_id: string;
    hmac: string;
    user_id: string;
  }) => {
    return request.POST<IUserResponse>(
      "user/auth_provider/google",
      RequestHelper.getAssociateUserGoogleAccountBody(data)
    );
  },

  /**
   * Get Plans
   * Retrieves a list of 'active' Plans. This has no relation to the currently 'logged in' User.
   */
  getPlans: (_: any, status = PlanStatus.Live) => {
    const params = { "filter[status]": status }; // Default to live plans
    return request.GET<IGetPlansResponse>("plans", params, false);
  },

  /**
   * Get Edit Credit Packs
   */
  getEditCreditPacks: () => {
    return request.GET<ServicesCreditPurchasingPacksResponse>(
      "packs",
      undefined,
      false
    );
  },

  /**
   * Get Edit Credit Balance
   */
  getEditCreditBalance: (accountId: Uuid) => {
    return request.GET<ServicesCreditBalanceResponse>(
      `accounts/${accountId}/credits`,
      undefined,
      true
    );
  },

  /**
   * Purchase Edit Credits
   */
  purchaseEditCredits: ({
    accountId,
    packId,
    quantity,
  }: CreditsPurchaseParams) => {
    return request.POST<ServicesCreditPurchase>(
      `accounts/${accountId}/credits/purchases`,
      {
        data: {
          type: "credit_purchases",
          attributes: {
            pack_id: packId,
            count: quantity,
          },
        },
      }
    );
  },

  /**
   * Get Is User part of edit rollout
   */
  getHasEditRollout: () => {
    return request.GET<ServicesEditRollout>("/user/rollout/edit");
  },

  /**
   * Get Is User part of edit beta
   */
  getHasEditBeta: (userId: Uuid) =>
    axios
      .get<Record<Uuid, string[]>>(
        `${BETA_FEATURES_CONFIG_URL}?t=${Date.now()}`
      )
      .then((res: any) =>
        res[userId]?.some((feature: string) => feature === "edit")
      )
      .catch((e) => {
        console.warn("Failed to fetch beta features", e);
        return false;
      }),

  /**
   * Get Publish projects
   */
  getProjectsPublish: (productID: string) => {
    return request.GET<IGetProjectsResponse>(
      `publish/${productID}/relationships/projects`
    );
  },

  /**
   * Forgot password
   * If a user exists with the given email, they will receive an email with a link to create a new password.
   * Multiple requests to this endpoint will invalid any previous links generated.
   */
  forgotPassword: (data: IForgotPasswordFields) => {
    const body = RequestHelper.getForgotPasswordRequestBody(data);
    return request.POST<void>("users/request-password", body, false);
  },

  /**
   * Reset password
   */
  resetPassword: (data: IResetPasswordFields & { code: string }) => {
    const body = RequestHelper.getResetPasswordRequestBody(data);
    return request.POST<IUserResponse>(
      `users/update-password/${data.code}`,
      body,
      false
    );
  },

  /**
   * Verify email
   */
  verifyEmail: (email: string) => {
    const body = RequestHelper.getVerifyEmailRequestBody(email);
    return request.POST<void>("users/request-verify-email", body, false);
  },

  /**
   * Get rewards
   */
  getRewards: (accountID: string) => {
    return request.GET<IGetRewardsResponse>(
      `accounts/${accountID}/relationships/reward-scheme`
    );
  },

  /**
   * Get coupon
   */
  getCoupon: ({
    coupon,
    interval,
    planID,
  }: {
    coupon: string;
    interval: ProductInterval;
    planID?: string;
  }) => {
    const params = {
      plan_id: planID,
      interval: `${interval}ly`,
    };
    return request.GET<ICouponResponse>(`coupons/${coupon}`, params, false);
  },

  /**
   * Preview the charge for creating a new account and subscription
   */
  previewChargeSignUp: ({
    planID,
    interval,
    coupon,
  }: {
    planID: string;
    interval: ProductInterval;
    coupon?: string;
  }) => {
    const body = RequestHelper.getPreviewChargeSignUpRequestBody({
      planID,
      interval,
      coupon,
    });
    return request.POST<IPreviewChargeResponse>("products/preview", body);
  },

  /**
   * Preview the charge for creating a new subscription
   */
  previewChargeNew: ({
    accountID,
    planID,
    interval,
    coupon,
  }: {
    accountID: string;
    planID: string;
    interval: ProductInterval;
    coupon?: string;
  }) => {
    const body = RequestHelper.getPreviewChargeNewRequestBody({
      planID,
      interval,
      coupon,
    });
    return request.PATCH<IPreviewChargeResponse>(
      `accounts/${accountID}/relationships/products/preview`,
      body
    );
  },

  /**
   * Preview the charge for updating an existing subscription
   */
  previewChargeExisting: ({
    productID,
    planID,
    interval,
    coupon,
  }: {
    productID: string;
    planID: string;
    interval: ProductInterval;
    coupon?: string;
  }) => {
    const body = RequestHelper.getPreviewChargeExistingRequestBody({
      productID,
      planID,
      interval,
      coupon,
    });
    return request.PATCH<IPreviewChargeResponse>(
      `products/${productID}/preview`,
      body
    );
  },

  /**
   * Get a Publish 2.0 Database Upload URL
   */
  getPublishDatabaseUploadUrl: (productID: string) =>
    request.GET<IUploadUrlResponse>(
      `publish/${productID}/relationships/projects/upload-url`
    ),

  /**
   * Get Affiliate details
   */
  getAffiliate: (accountID: string) =>
    request.GET<IAffiliateResponse>(
      `accounts/${accountID}/relationships/affiliate-scheme`
    ),

  /**
   * Get Affiliate clicks
   */
  getAffiliateClicks: ({
    accountID,
    product,
    startDate,
    endDate,
  }: {
    accountID: string;
    product?: Product;
    startDate?: string;
    endDate?: string;
  }) => {
    const params: {
      "filter[product_type]"?: Product;
      "filter[start_date]"?: string;
      "filter[end_date]"?: string;
    } = {};
    if (product) {
      params["filter[product_type]"] = product;
    }
    if (startDate && endDate) {
      params["filter[start_date]"] = startDate;
      params["filter[end_date]"] = endDate;
    }
    return request.GET<IAffiliateClicksResponse>(
      `accounts/${accountID}/relationships/affiliate-scheme/clicks`,
      params
    );
  },

  /**
   * Get Affiliate sign ups
   */
  getAffiliateSignUps: ({
    accountID,
    product,
    startDate,
    endDate,
  }: {
    accountID: string;
    product?: Product;
    startDate?: string;
    endDate?: string;
  }) => {
    const params: {
      "filter[product_type]"?: Product;
      "filter[start_date]"?: string;
      "filter[end_date]"?: string;
    } = {};
    if (product) {
      params["filter[product_type]"] = product;
    }
    if (startDate && endDate) {
      params["filter[start_date]"] = startDate;
      params["filter[end_date]"] = endDate;
    }
    return request.GET<IAffiliateSignUpsResponse>(
      `accounts/${accountID}/relationships/affiliate-scheme/signups`,
      params
    );
  },

  /**
   * Get data from Dato CMS
   */
  datocmsGraphqlRequest: async <T>({
    query,
    variables,
    preview,
  }: GraphqlRequest): Promise<T> => {
    // Use Node fetch as the axios interceptors cause an error in getStaticProps
    const response = await fetch(
      `https://graphql.datocms.com/${preview ? "preview" : ""}`,
      {
        method: "POST",
        body: JSON.stringify({ query, variables }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${DATOCMS_API_TOKEN}`,
        },
      }
    );
    return response.json();
  },
};
