import { Container } from "@/components";
import { ROUTES, EXTERNAL, MARKETING_BASE_URL } from "@/config";
import { AuthHelper } from "@/helpers";
import { useMounted, usePrefetchAccounts, usePrefetchPlans } from "@/hooks";
import { Header, Footer } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import Head from "next/head";
import { useRouter } from "next/router";

const LOGO_ANCHOR = {
  href: MARKETING_BASE_URL,
  label: "Narrative",
  isExternal: true,
};

const FOOTER_ITEMS = [
  {
    href: EXTERNAL.SUPPORT.HREF,
    label: "Support",
    isExternal: true,
    isNewTab: true,
  },
  { href: EXTERNAL.ABOUT.HREF, label: "About Us", isExternal: true },
  { href: EXTERNAL.CAREERS.HREF, label: "Careers", isExternal: true },
  { href: EXTERNAL.BLOG.HREF, label: "Blog", isExternal: true },
  { href: EXTERNAL.BLOG.HREF, label: "Partnerships", isExternal: true },
];

const LEGAL_ITEMS = [
  {
    href: EXTERNAL.TERMS.HREF,
    label: "Terms & Conditions",
    isExternal: true,
    isNewTab: true,
  },
  {
    href: EXTERNAL.PRIVACY.HREF,
    label: "Privacy Policy",
    isExternal: true,
    isNewTab: true,
  },
];

const getHeaderItems = (isAuthenticated?: boolean) => [
  {
    href: EXTERNAL.SELECT.BASE.HREF,
    label: "Select",
    isExternal: true,
  },
  {
    href: EXTERNAL.EDIT.BASE.HREF,
    label: "Edit",
    isExternal: true,
  },
  {
    href: EXTERNAL.PUBLISH.BASE.HREF,
    label: "Publish",
    isExternal: true,
  },
  {
    href: EXTERNAL.SUPPORT.HREF,
    label: "Support",
    isExternal: true,
    isNewTab: true,
    hideMobile: true,
  },
  {
    href: EXTERNAL.BLOG.HREF,
    label: "Blog",
    isExternal: true,
  },
  {
    href: "",
    label: "Company",
    dropdownItems: [
      {
        href: EXTERNAL.ABOUT.HREF,
        label: "About Us",
        isExternal: true,
      },
      {
        href: EXTERNAL.CAREERS.HREF,
        label: "Careers",
        isExternal: true,
      },
      {
        href: EXTERNAL.PARTNERSHIPS.HREF,
        label: "Partnerships",
        isExternal: true,
      },
    ],
  },
  ...(isAuthenticated
    ? [
        {
          href: `/${ROUTES.ACCOUNT.SLUG}`,
          label: "My Account",
        },
      ]
    : [
        {
          href: `/${ROUTES.LOGIN.SLUG}`,
          label: "Sign in",
        },
        {
          href: `/${ROUTES.GET_STARTED.BASE.SLUG}`,
          label: `Get started free`,
          isButton: true,
        },
      ]),
];

const getFooterItems = (isAuthenticated?: boolean) => ({
  legal: LEGAL_ITEMS,
  items: [
    {
      href: EXTERNAL.SELECT.BASE.HREF,
      label: "Select",
      isExternal: true,
    },
    {
      href: EXTERNAL.EDIT.BASE.HREF,
      label: "Edit",
      isExternal: true,
    },
    {
      href: EXTERNAL.PUBLISH.BASE.HREF,
      label: "Publish",
      isExternal: true,
    },
    ...FOOTER_ITEMS,
  ],
  downloads: isAuthenticated
    ? [
        { href: `/${ROUTES.SELECT.DOWNLOAD.SLUG}`, label: "Select" },
        { href: `/${ROUTES.PUBLISH.DOWNLOAD.SLUG}`, label: "Publish" },
      ]
    : [
        { href: `/${ROUTES.GET_STARTED.SELECT.SLUG}`, label: "Select" },
        { href: `/${ROUTES.GET_STARTED.PUBLISH.SLUG}`, label: "Publish" },
      ],
});

type Props = {
  hideNav?: boolean;
  hasBanner?: boolean;
  isAuthPage?: boolean;
  testId?: string;
  className?: string;
};

const LayoutPublic: React.FC<Props> = ({
  hideNav,
  hasBanner,
  isAuthPage,
  testId,
  className,
  children,
}) => {
  // Prefetch data
  usePrefetchAccounts(!isAuthPage);
  usePrefetchPlans();

  const { pathname } = useRouter();
  const isMounted = useMounted();
  const isAuthenticated = isMounted && AuthHelper.isToken();

  const headerItems = hideNav ? [] : getHeaderItems(isAuthenticated);
  const footerItems = getFooterItems(isAuthenticated);

  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/fonts/Graphik-Extralight.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Graphik-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Graphik-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Graphik-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Graphik-Semibold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>

      <div
        className={classNames(
          "relative flex flex-col flex-1 overflow-hidden bg-mankey",
          { "pt-13": hasBanner }
        )}
        data-testid={testId}
      >
        <Header
          logoAnchor={LOGO_ANCHOR}
          items={headerItems}
          activePath={pathname}
          className={classNames("z-10", { "top-13": hasBanner })}
          mobileSecondaryItems={FOOTER_ITEMS}
          mobileLegalItems={LEGAL_ITEMS}
        />
        <Container className={className}>{children}</Container>
        <Footer
          items={footerItems.items}
          legal={footerItems.legal}
          downloads={footerItems.downloads}
        />
      </div>
    </>
  );
};

export default LayoutPublic;
