import { SelectField } from "@/components";
import { IUserDetailsFields } from "@/containers";
import { countries } from "@/utils";
import { TextField } from "@material-ui/core";
import classNames from "classnames";
import { FormikProps } from "formik";

const countryOptions = countries.map((country) => {
  const disabled = country.charAt(0) === "-";
  return { disabled, value: country, label: country };
});

export interface IBillingAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  "postal-code": string;
  country: string;
}

export interface IBillingAddressFields
  extends IBillingAddress,
    Omit<IUserDetailsFields, "email"> {}

interface Props extends Partial<FormikProps<IBillingAddressFields>> {
  disabled?: boolean;
  showUserFields?: boolean;
}

const BillingAddressFields: React.FC<Props> = ({
  values,
  errors,
  touched,
  disabled,
  handleChange,
  handleBlur,
  showUserFields,
}) => {
  const commonProps = {
    disabled,
    type: "text",
    fullWidth: true,
    onBlur: handleBlur,
    onChange: handleChange,
  };

  return (
    <div className="grid grid-cols-1 gap-y-5">
      <div
        className={classNames("grid grid-cols-1 gap-5 sm:grid-cols-2", {
          hidden: !showUserFields,
        })}
      >
        <TextField
          name="firstName"
          label="First name"
          value={values?.firstName}
          error={touched?.firstName && !!errors?.firstName}
          helperText={touched?.firstName && errors?.firstName}
          {...commonProps}
        />
        <TextField
          name="lastName"
          label="Last name"
          value={values?.lastName}
          error={touched?.lastName && !!errors?.lastName}
          helperText={touched?.lastName && errors?.lastName}
          {...commonProps}
        />
      </div>
      <TextField
        name="line1"
        label="Address line 1"
        value={values?.line1}
        error={touched?.line1 && !!errors?.line1}
        helperText={touched?.line1 && errors?.line1}
        {...commonProps}
      />
      {(!disabled || values?.line2) && (
        <TextField
          name="line2"
          label="Address line 2"
          value={values?.line2}
          error={touched?.line2 && !!errors?.line2}
          helperText={touched?.line2 && errors?.line2}
          {...commonProps}
        />
      )}
      <TextField
        name="city"
        label="City"
        value={values?.city}
        error={touched?.city && !!errors?.city}
        helperText={touched?.city && errors?.city}
        {...commonProps}
      />
      <TextField
        name="state"
        label="State/Region"
        value={values?.state}
        error={touched?.state && !!errors?.state}
        helperText={touched?.state && errors?.state}
        {...commonProps}
      />
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
        <TextField
          name="postal-code"
          label="Zip/Postcode"
          value={values?.["postal-code"]}
          error={touched?.["postal-code"] && !!errors?.["postal-code"]}
          helperText={touched?.["postal-code"] && errors?.["postal-code"]}
          {...commonProps}
        />
        <SelectField
          name="country"
          label="Country"
          value={values?.country}
          error={touched?.country && !!errors?.country}
          helperText={touched?.country && errors?.country}
          options={countryOptions}
          addEmpty
          {...commonProps}
        />
      </div>
    </div>
  );
};

export default BillingAddressFields;
