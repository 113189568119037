import { SelectField } from "@/components";
import { browserDetection } from "@/utils";
import { TextFieldProps } from "@material-ui/core";

export type UserCurrentMachine =
  | "mobile"
  | "tablet"
  | "macos"
  | "windows"
  | "linux"
  | "chrome"
  | "";

export type UserOSAnswer =
  | ("mac" | "windows" | "both" | "neither")
  | ("mac_and_windows" | "windows_only")
  | ("mac_and_chromeos" | "chromeos_only")
  | ("mac_and_linux" | "linux_only")
  | "";

const options = (() => {
  const { userCurrentMachine } = browserDetection;
  switch (userCurrentMachine) {
    case "linux":
      return [
        { value: "mac_and_linux", label: "Mac and Linux" },
        { value: "linux_only", label: "Linux only" },
      ];
    case "windows":
      return [
        { value: "mac_and_windows", label: "Mac and Windows" },
        { value: "windows_only", label: "Windows only" },
      ];
    case "chrome":
      return [
        { value: "mac_and_chromeos", label: "Mac and Chrome OS" },
        { value: "chromeos_only", label: "Chrome OS only" },
      ];
    default:
      return [
        { value: "mac", label: "Mac" },
        { value: "windows", label: "Windows" },
        { value: "both", label: "Both" },
        { value: "neither", label: "Neither" },
      ];
  }
})();

const OperatingSystemField: React.FC<TextFieldProps> = (props) => (
  <SelectField {...props} addEmpty options={options} />
);

export default OperatingSystemField;
