const Download: React.FC = () => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8978 6.25065L5.94816 11.2003L0.998529 6.25065L4.93685 6.25065L4.93685 0.999352L6.93685 0.999352L6.93685 6.25065L10.8978 6.25065Z"
      fill="white"
    />
    <rect
      x="12"
      y="14.9961"
      width="12"
      height="2"
      transform="rotate(-180 12 14.9961)"
      fill="#828282"
    />
  </svg>
);

export default Download;
