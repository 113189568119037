import {
  ProductInterval,
  ILoginRequest,
  ICreateAccountRequest,
  IUpdateUserRequest,
  IUpdateAccountRequest,
  ICreateProductRequest,
  IUpdateProductRequest,
  IForgotPasswordRequest,
  IResetPasswordRequest,
  IVerifyEmailRequest,
  IDeleteProductRequest,
  IPreviewChargeSignUpRequest,
  IPreviewChargeNewRequest,
  IPreviewChargeExistingRequest,
  IRefreshTokenRequest,
  IGoogleOAuthAssociateResponse,
  IAssociateUserGoogleAccountRequest,
} from "@/api";
import {
  ILoginFields,
  IUserDetailsFields,
  IForgotPasswordFields,
  IResetPasswordFields,
  IBillingAddress,
} from "@/containers";
import { omitEmpty } from "@/utils";

const RequestHelper = {
  /**
   * Login
   */
  getLoginRequestBody: ({ email, password }: ILoginFields): ILoginRequest => ({
    data: {
      type: "users",
      attributes: {
        email,
        password,
      },
    },
  }),

  /**
   * Refresh Token
   */
  getRefreshTokenRequestBody: (refreshToken: string): IRefreshTokenRequest => ({
    data: {
      type: "users",
      attributes: {
        refresh_token: refreshToken,
      },
    },
  }),

  /**
   * Create account
   */
  getCreateAccountRequestBody: ({
    user,
    planID,
    interval,
    address,
    token,
    coupon,
  }: {
    user: {
      email: string;
      password: string;
      firstName?: string;
      lastName?: string;
    };
    planID?: string;
    interval?: ProductInterval;
    address?: IBillingAddress;
    token?: string;
    coupon?: string;
  }): ICreateAccountRequest =>
    omitEmpty({
      data: {
        type: "accounts",
        attributes: {
          address: {
            line1: address?.line1,
            line2: address?.line2,
            city: address?.city,
            "postal-code": address?.["postal-code"],
            state: address?.state,
            country: address?.country,
          },
          billing: {
            token,
          },
        },
        relationships: {
          owner: {
            data: {
              type: "users",
              attributes: {
                f_name: user.firstName,
                l_name: user.lastName,
                email: user.email,
                password: user.password,
              },
            },
          },
          ...(planID && interval
            ? {
                products: {
                  data: [
                    {
                      type: "products",
                      attributes: {
                        interval,
                        plan_id: planID,
                        coupon_code: coupon,
                      },
                    },
                  ],
                },
              }
            : {}),
        },
      },
    }),

  /**
   * Update user
   */
  getUpdateUserRequestBody: ({
    firstName,
    lastName,
    email,
    id,
  }: IUserDetailsFields & { id: string }): IUpdateUserRequest => ({
    data: {
      type: "users",
      id,
      attributes: {
        email,
        "f-name": firstName,
        "l-name": lastName,
      },
    },
  }),

  getAssociateUserGoogleAccountBody: ({
    user_id,
    ...oauthResponse
  }: IGoogleOAuthAssociateResponse): IAssociateUserGoogleAccountRequest => ({
    data: {
      type: "users",
      id: user_id,
      attributes: oauthResponse,
    },
  }),

  /**
   * Update billing address
   */
  getUpdateBillingAddressRequestBody: ({
    id,
    address,
  }: { id: string } & { address: IBillingAddress }): IUpdateAccountRequest => ({
    data: {
      type: "accounts",
      id,
      attributes: {
        address,
      },
    },
  }),

  /**
   * Update credit card (as stripe token)
   */
  getUpdatePaymentRequestBody: ({
    id,
    token,
  }: {
    id: string;
    token: string;
  }): IUpdateAccountRequest => ({
    data: {
      type: "accounts",
      id,
      attributes: {
        billing: {
          token,
        },
      },
    },
  }),

  /**
   * Create product
   */
  getCreateProductRequestBody: ({
    planID,
    interval,
    coupon,
  }: {
    planID?: string;
    interval?: ProductInterval;
    coupon?: string;
  }): ICreateProductRequest => ({
    data: [
      {
        type: "products",
        attributes: omitEmpty({
          interval,
          plan_id: planID,
          coupon_code: coupon,
        }),
      },
    ],
  }),

  /**
   * Update product
   */
  getUpdateProductRequestBody: ({
    productID,
    planID,
    interval,
    coupon,
  }: {
    productID: string;
    planID?: string;
    interval?: ProductInterval;
    coupon?: string;
  }): IUpdateProductRequest => ({
    data: {
      type: "products",
      id: productID,
      attributes: omitEmpty({
        interval,
        plan_id: planID,
        coupon_code: coupon,
      }),
    },
  }),

  /**
   * Forgot password
   */
  getForgotPasswordRequestBody: ({
    email,
  }: IForgotPasswordFields): IForgotPasswordRequest => ({
    data: {
      type: "users",
      attributes: {
        email,
      },
    },
  }),

  /**
   * Reset password
   */
  getResetPasswordRequestBody: ({
    code,
    password,
  }: IResetPasswordFields & { code: string }): IResetPasswordRequest => ({
    data: {
      type: "users",
      attributes: {
        code,
        password,
      },
    },
  }),

  /**
   * Verify email
   */
  getVerifyEmailRequestBody: (email: string): IVerifyEmailRequest => ({
    data: {
      type: "users",
      attributes: {
        email,
      },
    },
  }),

  /**
   * Delete product
   */
  getDeleteProductRequestBody: (id: string): IDeleteProductRequest => ({
    data: {
      type: "products",
      id,
    },
  }),

  /**
   * Preview charge sign up
   */
  getPreviewChargeSignUpRequestBody: ({
    planID,
    interval,
    coupon,
  }: {
    planID: string;
    interval: ProductInterval;
    coupon?: string;
  }): IPreviewChargeSignUpRequest => ({
    data: {
      type: "products",
      attributes: omitEmpty({
        plan_id: planID,
        interval,
        coupon,
      }),
    },
  }),

  /**
   * Preview charge new
   */
  getPreviewChargeNewRequestBody: ({
    planID,
    interval,
    coupon,
  }: {
    planID: string;
    interval: ProductInterval;
    coupon?: string;
  }): IPreviewChargeNewRequest => ({
    data: [
      {
        type: "products",
        attributes: omitEmpty({
          plan_id: planID,
          interval,
          coupon,
        }),
      },
    ],
  }),

  /**
   * Preview charge existing
   */
  getPreviewChargeExistingRequestBody: ({
    productID,
    planID,
    interval,
    coupon,
  }: {
    planID: string;
    productID: string;
    interval: ProductInterval;
    coupon?: string;
  }): IPreviewChargeExistingRequest => ({
    data: {
      type: "products",
      id: productID,
      attributes: omitEmpty({
        plan_id: planID,
        interval,
        coupon,
      }),
    },
  }),
};

export default RequestHelper;
