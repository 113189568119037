import { useGoogleOAuth } from "./useGoogleOAuth";
import { IGoogleOAuthResponse } from "@/api";
import { GoogleLoginErrorModal } from "@/components";
import { useModalDispatch } from "@/providers";
import { ModalActionType } from "@narrative-software/narrative-web-ui";
import { useCallback } from "react";

interface GoogleErrorResponse {
  status: number;
  code?: string;
  detail?: string;
  title?: string;
}

/**
 * Hook for Google login - used on login page, and on retry modal.
 */
const useGoogleLogin = (onSuccess: (data: IGoogleOAuthResponse) => void) => {
  const modalDispatch = useModalDispatch();

  const _onSuccess = useCallback(
    (data: IGoogleOAuthResponse) => {
      // Clear any error modals
      modalDispatch({ type: ModalActionType.Close });

      onSuccess(data);
    },
    [onSuccess]
  );

  const onError = useCallback((error: GoogleErrorResponse) => {
    if (error.status === 401) {
      modalDispatch({
        type: ModalActionType.SetContent,
        payload: {
          children: <GoogleLoginErrorModal onSuccess={_onSuccess} />,
        },
      });
    }
  }, []);

  const { openWindow } = useGoogleOAuth(_onSuccess, onError);

  const openLoginWindow = useCallback(() => openWindow("login"), [openWindow]);

  return { openWindow: openLoginWindow };
};

export { useGoogleLogin };
