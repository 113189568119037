const Menu: React.FC = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="2" fill="currentColor" />
    <rect y="9" width="24" height="2" fill="currentColor" />
    <rect y="18" width="24" height="2" fill="currentColor" />
  </svg>
);

export default Menu;
