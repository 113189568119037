import { Nav } from "@/components";
import type { DownloadLinks } from "@/components/Download/utils";
import { EXTERNAL } from "@/config";
import { DeleteAccount } from "@/containers";
import { Close } from "@/svg";
import { Transition } from "@headlessui/react";
import { Logo } from "@narrative-software/narrative-web-ui";
import { LogoAnchor } from "@narrative-software/narrative-web-ui/dist/Header/components";

type SidebarProps = {
  open: boolean;
  onClose: () => void;
  productDownloadLinks: DownloadLinks;
};

const Sidebar = ({ open, onClose, productDownloadLinks }: SidebarProps) => (
  <>
    <Transition className="fixed inset-0 flex z-30 lg:hidden" show={open}>
      {/* Overlay */}
      <Transition.Child
        className="fixed inset-0"
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute inset-0 bg-black opacity-50"
          aria-hidden="true"
          onClick={onClose}
        />
      </Transition.Child>

      {/* Mobile Sidebar */}
      <Transition.Child
        className="relative flex-1 flex flex-col w-full bg-stealth-bomber md:max-w-90"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="absolute top-0 right-0">
          <button
            type="button"
            title="Close sidebar"
            aria-label="Close sidebar"
            className="flex items-center justify-center p-4.5 rounded-full focus:outline-none focus:bg-gray-800"
            onClick={onClose}
          >
            <Close />
          </button>
        </div>
        <div className="flex-1 h-0 overflow-y-auto">
          <div className="flex-shrink-0 flex items-center pt-5 pl-5 mb-5">
            <LogoAnchor href={EXTERNAL.BASE.HREF} label="Narrative">
              <Logo colour="white" small />
            </LogoAnchor>
          </div>
          <Nav
            testIdPrefix="mobile"
            onClose={onClose}
            productDownloadLinks={productDownloadLinks}
          />
        </div>
      </Transition.Child>
    </Transition>

    {/* Desktop Sidebar */}
    <div className="hidden flex-shrink-0 overflow-y-auto lg:flex">
      <div className="flex flex-col flex-1 w-64 p-6 bg-stealth-bomber xl:w-90 xl:p-10">
        <div className="flex items-center flex-shrink-0 mb-6 xl:mb-9">
          <LogoAnchor href={EXTERNAL.BASE.HREF} label="Narrative">
            <Logo colour="white" />
          </LogoAnchor>
        </div>
        <Nav
          testIdPrefix="desktop"
          productDownloadLinks={productDownloadLinks}
        />
        <div className="mt-auto">
          <DeleteAccount />
        </div>
      </div>
    </div>
  </>
);

export default Sidebar;
