import { EXTERNAL } from "@/config";
import classNames from "classnames";

type Props = {
  className?: string;
};

const Terms: React.FC<Props> = ({ className }) => (
  <p className={classNames("text-xxs text-gray-700", className)}>
    By signing up you agree to our{" "}
    <a
      href={EXTERNAL.TERMS.HREF}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-700 underline hover:text-black transition-colors duration-200"
    >
      terms
    </a>{" "}
    and{" "}
    <a
      href={EXTERNAL.PRIVACY.HREF}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-700 underline hover:text-black transition-colors duration-200"
    >
      privacy policy
    </a>
    .
  </p>
);

export default Terms;
