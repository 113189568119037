import {
  useUserQuery,
  useUpdateUserMutation,
  useForgotPasswordMutation,
  IUserResponse,
} from "@/api";
import { IS_DEVELOPMENT } from "@/config";
import { getUserDetails, userHasSetPassword } from "@/helpers/user";
import {
  TextField,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import { Button, Spinner } from "@narrative-software/narrative-web-ui";
import { Formik, Form, FormikHelpers } from "formik";
import { updateIntercom } from "intercom-next";
import { useEffect } from "react";
import * as Yup from "yup";

// Types
export interface IUserDetailsFields {
  firstName: string;
  lastName: string;
  email: string;
}

type UserDetailsFormProps = {
  user?: IUserResponse;
  isLoading: boolean;
  hasConnectedGoogleAccount: boolean;
};

const UserDetailsForm = ({
  user,
  isLoading,
  hasConnectedGoogleAccount,
}: UserDetailsFormProps) => {
  const { mutateAsync: doUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUserMutation();
  const { mutateAsync: doForgotPassword, isLoading: isLoadingForgotPassword } =
    useForgotPasswordMutation();

  // Init Intercom with user details
  useEffect(() => {
    if (IS_DEVELOPMENT || !user || isLoading || !window.Intercom) return;

    const { firstName, lastName, email } = getUserDetails(user);

    updateIntercom(undefined, { name: `${firstName} ${lastName}`, email });
  }, [user, isLoading]);

  // Loading state
  if (!user || isLoading) {
    return <Spinner />;
  }

  // Initial values
  const initialValues = {
    firstName: user.data.attributes["f-name"] || "",
    lastName: user.data.attributes["l-name"] || "",
    email: user.data.attributes.email || "",
  };

  // Validation
  const validationSchema = Yup.object().shape({
    firstName: initialValues.firstName
      ? Yup.string().required("First name required")
      : Yup.string().optional(),
    lastName: initialValues.lastName
      ? Yup.string().required("Last name required")
      : Yup.string().optional(),
    email: Yup.string().email("Invalid email").required("Email required"),
  });

  // Handle change password click
  const handleChangePasswordClick = async () => {
    try {
      await doForgotPassword({ email: user.data.attributes.email });
    } catch {}
  };

  // Handle submit
  const handleSubmit = async (
    values: IUserDetailsFields,
    actions: FormikHelpers<IUserDetailsFields>
  ) => {
    try {
      // Send update request
      await doUpdateUser({ ...values, id: user.data.attributes.id });
      actions.resetForm({ values });
    } finally {
      actions.setSubmitting(false);
    }
  };

  // Render
  return (
    <Formik
      key={hasConnectedGoogleAccount ? "google" : "email"} // Hard reset form when google account is connected/disconnected
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
      }) => (
        <Form noValidate>
          <div className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <TextField
              name="firstName"
              id="user-details-first-name-input"
              label="First name"
              type="text"
              value={values.firstName}
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                "data-testid": "user-details-first-name-input",
              }}
              fullWidth
            />

            <TextField
              name="lastName"
              id="user-details-last-name-input"
              label="Last name"
              type="text"
              value={values.lastName}
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                "data-testid": "user-details-last-name-input",
              }}
              fullWidth
            />
          </div>

          <div className="mb-6">
            <TextField
              name="email"
              id="user-details-email-input"
              label="Email"
              type="email"
              value={values.email}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                readOnly: hasConnectedGoogleAccount,
                "data-testid": "user-details-email-input",
              }}
              fullWidth
            />
          </div>

          <div className="mb-6">
            <FormControl fullWidth>
              <InputLabel htmlFor="user-details-password-input">
                Password
              </InputLabel>
              <Input
                name="password"
                id="user-details-password-input"
                type={userHasSetPassword(user) ? "password" : "text"}
                value={userHasSetPassword(user) ? "********" : " "}
                readOnly
                fullWidth
                inputProps={{
                  "data-testid": "user-details-password-input",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <button
                      type="button"
                      className="text-xxxs underline focus:outline-none"
                      disabled={isLoadingForgotPassword}
                      onClick={handleChangePasswordClick}
                    >
                      {(isLoadingForgotPassword && <Spinner size={4} />) ||
                        (userHasSetPassword(user) && "Change your password") ||
                        "Set your password"}
                    </button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <Button
            type="submit"
            colour="black"
            disabled={!dirty}
            isLoading={isSubmitting || isLoadingUpdateUser}
            showLoader
            testId="user-details-submit-button"
          >
            Save changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserDetailsForm;
