import FemalePhotographer from "../../../public/svg/female-photographer.svg";
import WomanAndCat from "../../../public/svg/woman-and-cat.svg";
import { HtmlToElement } from "@/components";
import { TOPICS, publishEvent } from "@/events";
import { Product } from "@/types";
import Image from "next/image";

interface Props {
  product: Product;
  title: React.ReactNode;
  text: React.ReactNode;
  downloadUrl: string;
}

const MobileDownloadSection: React.FC<Props> = ({
  product,
  title,
  text,
  downloadUrl,
}) => {
  const handleClick = () => {
    publishEvent(TOPICS.downloadStartedManually, { app: product });
  };

  return (
    <div className="flex flex-col items-center text-center">
      <HtmlToElement
        element="h1"
        className="max-w-4xl text-h2 font-semibold leading-tight mb-8 lg:text-h1"
      >
        {title}
      </HtmlToElement>

      <div className="max-w-3xl mb-8">
        <HtmlToElement className="prose text-stealth-bomber text-lg">
          {text}
        </HtmlToElement>
      </div>

      {product === "select" && (
        <Image src={WomanAndCat} alt="" width="383" height="420" />
      )}
      {product === "publish" && (
        <Image src={FemalePhotographer} alt="" width="357" height="421" />
      )}

      <a
        href={downloadUrl}
        className="underline mt-8"
        onClick={handleClick}
        download
      >
        Download anyway
      </a>
    </div>
  );
};

export default MobileDownloadSection;
