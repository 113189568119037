import {
  AlertActionType,
  AlertState,
  AlertAction,
} from "@narrative-software/narrative-web-ui";
import {
  useEffect,
  createContext,
  useReducer,
  useContext,
  Dispatch,
} from "react";

const initialState: AlertState = {
  type: "info",
  title: "",
  children: null,
  isVisible: false,
};

const AlertStateContext = createContext<AlertState>(initialState);
const AlertDispatchContext = createContext<Dispatch<AlertAction>>(() => {});

const reducer = (state: AlertState, action: AlertAction) => {
  switch (action.type) {
    case AlertActionType.Open:
      return {
        ...state,
        isVisible: true,
      };
    case AlertActionType.Close:
      return {
        ...state,
        isVisible: false,
      };
    case AlertActionType.Toggle:
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    case AlertActionType.SetContent:
      return {
        isVisible: true,
        type: action.payload?.type || initialState.type,
        title: action.payload?.title || initialState.title,
        children: action.payload?.children || initialState.children,
      };
    default:
      throw new Error(`Unknown alert action: ${action.type}`);
  }
};

export const AlertProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isVisible } = state;

  // Hide alert after 6 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isVisible) {
        dispatch({ type: AlertActionType.Close });
      }
    }, 6000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible]);

  return (
    <AlertDispatchContext.Provider value={dispatch}>
      <AlertStateContext.Provider value={state}>
        {children}
      </AlertStateContext.Provider>
    </AlertDispatchContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertStateContext);
  if (context === undefined) {
    throw new Error("useAlertState must be used within a AlertProvider");
  }
  return context;
};

export const useAlertDispatch = () => {
  const context = useContext(AlertDispatchContext);
  if (context === undefined) {
    throw new Error("useAlertDispatch must be used within a AlertProvider");
  }
  return context;
};
