import { ProductName } from "@/api";
import { EXTERNAL } from "@/config";
import { Button } from "@narrative-software/narrative-web-ui";

type Props = {
  onClick(): void;
  productName?: ProductName;
};

const DeniedModal: React.FC<Props> = ({ onClick, productName }) => (
  <>
    <h3 className="mb-6 text-h3 font-semibold leading-tight">
      You can’t delete your account right now
    </h3>
    <p className="mb-8">
      You have one or more active subscriptions{" "}
      {productName ? `(${productName})` : ""} with Narrative. You need to cancel
      them from the subscriptions page before you’re able to delete your
      account. If you need help, please contact{" "}
      <a
        href={EXTERNAL.SUPPORT.HREF}
        target="_blank"
        rel="noopener noreferrer"
        className="underline hover:no-underline"
      >
        support
      </a>
      .
    </p>
    <div className="w-full max-w-56 ml-auto">
      <Button colour="black" onClick={onClick}>
        Okay
      </Button>
    </div>
  </>
);

export default DeniedModal;
