import classNames from "classnames";

type Props = {
  className?: string;
  testId?: string;
};

const Banner: React.FC<Props> = ({ children, className, testId }) => (
  <section
    className={classNames(
      "flex items-center justify-center w-full h-13 px-5 text-13 leading-none text-center bg-white",
      className
    )}
    data-testid={testId}
  >
    {children}
  </section>
);

export default Banner;
