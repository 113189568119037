import { ILinks } from "./global";
import { ProductInterval, IProductObject } from "./products";

export enum AccountUsageStatus {
  Active = "active",
  ViolatedFairUsePolicy = "violated_fair_use_policy",
  ViolatedTerms = "violated_terms",
  SuspiciousActivity = "suspicious_activity",
}

export enum AccountBillingBrand {
  AmericanExpress = "American Express",
  DinersClub = "Diners Club",
  Discover = "Discover",
  JCB = "JCB",
  MasterCard = "MasterCard",
  UnionPay = "UnionPay",
  Visa = "Visa",
  Unknown = "Unknown",
}

export enum AccountBillingType {
  Link = "link",
  Card = "card"
}

export interface IAccountAddress {
  line1: string;
  line2: string;
  city: string;
  "postal-code": string;
  state: string;
  country: string;
}

export interface IAccountBilling {
  token?: string;
  country?: string;
  last4?: string;
  "exp-month"?: number;
  "exp-year"?: number;
  brand?: AccountBillingBrand;
  type: AccountBillingType;
  email?: string;
}

export interface IAccountObject {
  type: "accounts";
  relationships: Record<string, unknown>;
  links: ILinks;
  id: string;
  attributes: {
    id: string;
    name: string;
    status: number;
    active: boolean;
    "inserted-at": string;
    "updated-at": string;
    "usage-status": AccountUsageStatus;
    address: IAccountAddress;
    billing: IAccountBilling;
    preferences: {
      "default-pack-id": Uuid; // Edit credit pack id
    };
  };
}

export interface IGetAccountResponse {
  data: IAccountObject;
  links: ILinks;
  included?: IProductObject[];
}

export interface IGetAccountsResponse {
  data: IAccountObject[];
  links: ILinks;
  included?: IProductObject[];
}

export interface ICreateAccountRequest {
  data: {
    type: "accounts";
    attributes: {
      address?: IAccountAddress;
      billing?: {
        token: string;
      };
    };
    relationships: {
      owner: {
        data: {
          type: "users";
          attributes: {
            f_name: string;
            l_name: string;
            email: string;
            password: string;
          };
        };
      };
      products: {
        data: {
          type: "products";
          attributes: {
            plan_id: string;
            interval: ProductInterval;
            coupon_code?: string;
          };
        }[];
      };
    };
  };
}

export interface ICreateAccountResponse {
  links: ILinks;
  included: [];
  data: Omit<IAccountObject, "relationships"> & {
    relationships: {
      owner: {
        links: ILinks;
        data: {
          type: "users";
          id: string;
        };
      };
      products: {
        links: ILinks;
        data: {
          type: "products";
          id: string;
        }[];
      };
    };
  };
}

export interface IUpdateAccountRequest {
  data: {
    type: "accounts";
    id: string;
    attributes: {
      address?: Partial<IAccountAddress>;
      billing?: Partial<IAccountBilling>;
    };
  };
}
