import {
  DownloadLinks,
  getDeviceData,
  getProductDownloadUrl,
} from "../Download/utils";
import { useProductsQuery, useRewardsQuery, ProductName } from "@/api";
import { ROUTES, EXTERNAL } from "@/config";
import { TOPICS, publishEvent } from "@/events";
import { DataHelper } from "@/helpers";
import { REFERRALS_PAGE_VISITED_STORAGE_KEY } from "@/pages/referrals";
import { useAuthContext } from "@/providers";
import { Download, ExternalLink } from "@/svg";
import { isBrowser } from "@/utils";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const hasVisitedReferralsPage = () =>
  isBrowser() &&
  !!window.localStorage.getItem(REFERRALS_PAGE_VISITED_STORAGE_KEY);

type NavProps = {
  testIdPrefix: string;
  onClose?: () => void;
  productDownloadLinks: DownloadLinks;
};

const Nav = ({ testIdPrefix, onClose, productDownloadLinks }: NavProps) => {
  const router = useRouter();
  const { setAuthenticated } = useAuthContext();
  const { data: productsData, isLoading: isLoadingProducts } =
    useProductsQuery();
  const { data: rewardsData, isLoading: isRewardsLoading } = useRewardsQuery();
  const deviceData = getDeviceData();

  const navItems = [ROUTES.ACCOUNT, ROUTES.SUBSCRIPTIONS];
  const externalItems = [EXTERNAL.SUPPORT];
  const downloadItems: {
    HREF: string;
    LABEL: string;
    app: "select" | "publish";
  }[] = [];

  // Add nav item based on the users reward scheme type
  if (rewardsData && !isRewardsLoading) {
    const schemeType = rewardsData.data.attributes["scheme-type"];
    if (schemeType === "referral_scheme") navItems.push(ROUTES.REFERRALS);
    if (schemeType === "affiliate_scheme") navItems.push(ROUTES.AMBASSADORS);
  }

  // Add links based on product subscriptions
  if (productsData && !isLoadingProducts) {
    const isSubscribedToSelect = !!DataHelper.getProductByName(
      ProductName.Select,
      productsData.data,
      true
    );
    const isSubscribedToPublish = !!DataHelper.getProductByName(
      ProductName.Publish,
      productsData.data,
      true
    );
    const selectDownloadLink = getProductDownloadUrl(
      "select",
      deviceData.osName,
      productDownloadLinks
    );
    const publishDownloadLink = getProductDownloadUrl(
      "publish",
      deviceData.osName,
      productDownloadLinks
    );

    if (isSubscribedToSelect && selectDownloadLink) {
      downloadItems.push({ ...selectDownloadLink, app: "select" });
    }
    if (isSubscribedToPublish && publishDownloadLink) {
      downloadItems.push({ ...publishDownloadLink, app: "publish" });
    }
  }

  const handleNavItemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.currentTarget.pathname === router.pathname) {
      onClose?.();
    }
  };

  const handleNavItemKeyPress = (
    event: React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    if (
      event.currentTarget.pathname === router.pathname &&
      event.key === "Enter"
    ) {
      onClose?.();
    }
  };

  const handleDownloadClick = (app: "select" | "publish") => {
    publishEvent(TOPICS.downloadStartedManually, { app });
  };

  const handleSignOutClick = () => {
    setAuthenticated(false);
  };

  const handleSignOutKeyPress = (
    event: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key === "Enter") {
      setAuthenticated(false);
    }
  };

  const listItemClassName = "relative border-b border-black lg:border-none";
  const anchorClassName =
    "group flex items-center pl-9 pr-6 py-2.5 text-lg text-gray-500 tracking-medium hover:text-white hover:underline focus:outline-none focus:text-white focus:underline transition ease-in-out duration-200 lg:px-0 lg:py-2 lg:text-base";

  return (
    <nav>
      <ul className="border-t border-black lg:border-none">
        {navItems.map(({ SLUG, LABEL }) => {
          const href = `/${SLUG}`;
          const testId = SLUG || "account-details"; // Account details page has an empty slug
          const showDot =
            SLUG === ROUTES.REFERRALS.SLUG && !hasVisitedReferralsPage();
          return (
            <li key={href} className={listItemClassName}>
              <Link href={href}>
                <a
                  role="link"
                  tabIndex={0}
                  onClick={handleNavItemClick}
                  onKeyPress={handleNavItemKeyPress}
                  className={classNames(anchorClassName, {
                    "text-white underline": router.pathname === href,
                  })}
                  data-testid={`${testIdPrefix}-nav-${testId}-link`}
                >
                  {LABEL}
                </a>
              </Link>
              {showDot && (
                <span
                  className="absolute top-0 left-0 mt-2.5 ml-7.5 border border-stealth-bomber rounded-full bg-slowpoke lg:mt-2 lg:-ml-1.5"
                  style={{ width: "9px", height: "9px" }}
                />
              )}
            </li>
          );
        })}

        {downloadItems.map(({ HREF, LABEL, app }) => (
          <li key={HREF} className={listItemClassName}>
            <a
              href={HREF}
              className={anchorClassName}
              onClick={() => handleDownloadClick(app)}
              data-testid={`${testIdPrefix}-nav-download-${app}-link`}
              download
            >
              <span className="mr-2">{LABEL}</span>
              <Download />
            </a>
          </li>
        ))}

        {externalItems.map(({ HREF, LABEL }) => (
          <li key={HREF} className={listItemClassName}>
            <a
              href={HREF}
              target="_blank"
              rel="noopener noreferrer"
              className={anchorClassName}
            >
              <span className="mr-2">{LABEL}</span>
              <ExternalLink />
            </a>
          </li>
        ))}

        <li className={listItemClassName}>
          <button
            onClick={handleSignOutClick}
            onKeyPress={handleSignOutKeyPress}
            className={anchorClassName}
            data-testid={`${testIdPrefix}-nav-logout-link`}
          >
            Sign Out
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
