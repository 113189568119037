import { CardElement } from "@stripe/react-stripe-js";
import classNames from "classnames";
import { useState, useEffect } from "react";

interface Props {
  error?: string;
  delayStripeMount?: boolean;
  onChange?: () => void;
}

const stealthBomber = "#1c1c1c";
const robotFriend = "#bdbdbd";
const errorRed = "#f44336";

const PaymentFields: React.FC<Props> = ({
  error,
  delayStripeMount,
  onChange,
}) => {
  const [focused, setFocused] = useState(true);
  const [fadeInFinished, setFadeInFinished] = useState(false);

  useEffect(() => {
    // Workaround for issue where our modal's fade-in animation doesn't play nice with Stripe's own fade-in animation.
    // So we wait a wee bit for the modal fade-in to be mostly done before we mount the Stripe element.
    const timeout = setTimeout(() => setFadeInFinished(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <div
        className={classNames(
          "text-xxs mb-2",
          { "text-gray-600": !focused },
          { "text-gray-900": focused },
          { "text-error": error }
        )}
      >
        Credit card
      </div>
      <div style={{ height: "19px" }}>
        {(fadeInFinished || !delayStripeMount) && (
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: error ? errorRed : stealthBomber,
                  "::placeholder": {
                    color: robotFriend,
                  },
                },
                invalid: {
                  color: errorRed,
                },
              },
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={() => onChange?.()}
          />
        )}
      </div>
      <div
        className={classNames(
          "w-full mt-2 h-1px",
          { "bg-gray-600": !focused },
          { "bg-gray-900": focused },
          { "bg-error": error }
        )}
      />
      {error && <div className="text-xxs mt-2 text-error">{error}</div>}
    </div>
  );
};

export default PaymentFields;
