import { TOPICS, Metadata, PageLoad, publishEvent } from "@/events";
import { TrackingHelper } from "@/helpers";
import { useWindowEvent, useDocumentEvent } from "@/hooks";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { useEffect } from "react";

// Hack to not send a pageview event on the initial page load because we have a separate pageview trigger for that,
// which is being used by the old site and account portal right now so we don't want to remove it.
let initialPageLoadComplete = false;

export const useGlobalAnalyticsEvents = () => {
  useEffect(() => {
    PageLoad.setStartDate();
    Metadata.set(); // Set metadata
    publishEvent(TOPICS.pageLoadStart); // Initial page load start

    const timeout = setTimeout(() => {
      initialPageLoadComplete = true;
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Initial page load finish
  useWindowEvent("load", () => publishEvent(TOPICS.pageLoadFinish));

  // Page load events fired via route change
  const router = useRouter();
  useEffect(() => {
    const handleRouteChangeStart = (_path: string) => {
      if (!initialPageLoadComplete) return;
      const path = _path.split("?")[0];
      PageLoad.resetId();
      PageLoad.setStartDate();
      publishEvent(TOPICS.pageLoadStart, { path });
    };

    const handleRouteChangeComplete = (_path: string) => {
      if (!initialPageLoadComplete) return;
      const path = _path.split("?")[0];
      publishEvent(TOPICS.pageLoadFinish, { path });
      TrackingHelper.googleAnalyticsTrackCustom("pageview");
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router]);

  // Page navigate away
  useWindowEvent("beforeunload", () => publishEvent(TOPICS.pageNavigateAway));

  // Page visibility change
  useDocumentEvent("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      publishEvent(TOPICS.pageVisibilityChangeVisible);
    } else {
      publishEvent(TOPICS.pageVisibilityChangeHidden);
    }
  });

  // Page scroll
  useWindowEvent(
    "scroll",
    debounce(() => {
      Metadata.refreshViewport();
      publishEvent(TOPICS.pageScroll);
    }, 1500),
    true
  );

  // Page resized
  useWindowEvent(
    "resize",
    debounce(() => {
      Metadata.refreshViewport();
      publishEvent(TOPICS.pageResized);
    }, 1000)
  );
};
