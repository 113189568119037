export const TOPICS = {
  pageLoadStart: "web_page_load_start",
  pageLoadFinish: "web_page_load_finish",
  pageNavigateAway: "web_page_navigate_away",
  pageVisibilityChangeHidden: "web_page_visibility_change_hidden",
  pageVisibilityChangeVisible: "web_page_visibility_change_visible",
  pageScroll: "web_page_scroll",
  pageResized: "web_page_resized",
  signInSucceeded: "web_sign_in_succeeded",
  signInFailed: "web_sign_in_failed",
  signUpSucceeded: "web_sign_up_succeeded",
  googleSignInWindowOpen: "web_google_sign_in_window_open",
  googleSignInWindowOutcome: "web_google_sign_in_outcome",
  signUpFailed: "web_sign_up_failed",
  signUpOs: "web_sign_up_selected_os",
  signUpGenre: "web_sign_up_selected_genre",
  signUpSource: "web_sign_up_selected_signup_source",
  signUpStarted: "web_lead_sign_up_started",
  emailLead: "web_lead_email_captured",
  downloadStartedAutomatically: "web_download_started_automatically",
  downloadStartedManually: "web_download_started_manually",
  selectSignInStart: "web_start_select_sign_in",
  selectSignInFinish: "web_finish_select_sign_in",
  publishSignInStart: "web_start_publish_sign_in",
  publishSignInFinish: "web_finish_publish_sign_in",
  productCreated: "web_product_created",
  chatOpened: "web_support_chat_opened",
  chatClosed: "web_support_chat_closed",
} as const;
