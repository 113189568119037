import { IS_DEVELOPMENT, IS_PRODUCTION, INTERCOM_ID } from "@/config";
import { TOPICS, getSchema, EventMetadata } from "@/events";
import { AuthHelper } from "@/helpers";
import { isBrowser } from "@/utils";
import { loadIntercom } from "intercom-next";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

const eventApiUrl = IS_PRODUCTION
  ? "wss://api.getnarrativeapp.com/api/events/rt"
  : "wss://staging.api.getnarrativeapp.com/api/events/rt";

export const getSessionID = () => {
  if (!isBrowser()) return null;

  const key = "narrative-event-session-id";
  const lifeSpan = 3650; // ~10 years in days

  let id = Cookies.get(key);
  if (!id) {
    id = uuidv4();
    if (IS_PRODUCTION) {
      Cookies.set(key, id, { expires: lifeSpan, domain: ".narrative.so" });
    } else {
      Cookies.set(key, id, { expires: lifeSpan });
    }
  }

  return id;
};

const createWebSocket = () => {
  if (!isBrowser()) return null;

  const socket = new WebSocket(eventApiUrl);
  socket.addEventListener("open", () => onOpen());
  socket.addEventListener("close", () => onClose());
  return socket;
};

const socket = createWebSocket();
let poller: NodeJS.Timeout | null = null;

const isReady = () => socket?.readyState === WebSocket.OPEN;

const onOpen = () => {
  poller = setInterval(() => {
    if (isReady()) {
      socket?.send("ping");
    }
  }, 1500);
};

const onClose = () => {
  if (poller) {
    clearInterval(poller);
    poller = null;
  }
};

const sendEvent = (event: any) => {
  socket?.send(JSON.stringify({ cmd: "publish_event", event }));
};

export const setMetadata = (
  key: string,
  value: EventMetadata | EventMetadata["viewport"] | null
) => {
  const payload = JSON.stringify({ key, value, cmd: "set_meta", system: true });

  if (isReady()) {
    socket?.send(payload);
  } else {
    socket?.addEventListener("open", () => socket?.send(payload), {
      once: true,
    });
  }
};

export const publishEvent = (
  topic: typeof TOPICS[keyof typeof TOPICS],
  data: Record<string, any> = {},
  vsn = 2
) => {
  const { user_id, path, ...rest } = data;

  const event = {
    topic,
    vsn,
    occurred_at: Date.now(),
    user_id: user_id || AuthHelper.getUserId(),
    data: {
      session_id: getSessionID(),
      ...getSchema(path),
      ...rest,
    },
  };

  if (isReady()) {
    sendEvent(event);
  } else {
    socket?.addEventListener("open", () => sendEvent(event), { once: true });
  }
};

/**
 * Start Intercom
 * - This function must be located in this file to prevent a build error
 */
export const initIntercom = async ({
  name,
  email,
}: { name?: string; email?: string } = {}) => {
  if (IS_DEVELOPMENT || !isBrowser()) return;

  await loadIntercom({
    appId: INTERCOM_ID,
    email,
    name,
  });

  if (window.Intercom) {
    window.Intercom("onShow", () => publishEvent(TOPICS.chatOpened));
    window.Intercom("onHide", () => publishEvent(TOPICS.chatClosed));
  }
};
