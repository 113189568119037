import classNames from "classnames";

type Props = {
  title: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const InfoContainer: React.FC<Props> = ({
  title,
  className,
  style,
  children,
}) => (
  <section
    className={classNames("p-8 lg:px-16 lg:py-14 bg-white", className)}
    style={style}
  >
    <h3 className="text-h3 font-semibold leading-tight mb-6 sm:mb-13">
      {title}
    </h3>
    {children}
  </section>
);

export default InfoContainer;
