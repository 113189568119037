import { useResetPasswordMutation } from "@/api";
import { PasswordField } from "@/components";
import { Button } from "@narrative-software/narrative-web-ui";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface IResetPasswordFields {
  password: string;
}

const initialValues: IResetPasswordFields = {
  password: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(100, "Password must be no more than 100 characters")
    .required("A new password is required"),
});

type Props = {
  code: string;
};

const ResetPasswordForm: React.FC<Props> = ({ code }) => {
  const { mutateAsync: doResetPassword, isLoading } =
    useResetPasswordMutation();

  const handleSubmit = async (
    values: IResetPasswordFields,
    actions: FormikHelpers<IResetPasswordFields>
  ) => {
    try {
      const { password } = values;
      await doResetPassword({ code, password });
      actions.resetForm({});
    } catch {
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
      }) => (
        <Form noValidate>
          <div>
            <PasswordField
              name="password"
              id="reset-password-form-password-input"
              label="New password"
              value={values.password}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              showVisibilityToggle
              fullWidth
            />
          </div>

          <div className="mt-7">
            <Button
              type="submit"
              colour="black"
              disabled={!dirty}
              isLoading={isSubmitting || isLoading}
              showLoader
              testId="reset-password-form-submit-button"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
