import { ProductName, ProductStatus, IPlanObject, IProductObject } from "@/api";

const DataHelper = {
  getPlanById(id: string, plansData: IPlanObject[]) {
    return plansData?.find((plan) => plan.id === id);
  },

  getProductById(
    id: string,
    productsData: IProductObject[],
    ignoreCanceled = false
  ) {
    return productsData?.find(
      ({ attributes }) =>
        attributes.id === id &&
        (ignoreCanceled ? attributes.status !== ProductStatus.Canceled : true)
    );
  },

  getProductByName(
    name: ProductName,
    productsData: IProductObject[],
    ignoreCanceled = false
  ) {
    return productsData?.find(
      ({ attributes }) =>
        attributes.name === name &&
        (ignoreCanceled ? attributes.status !== ProductStatus.Canceled : true)
    );
  },

  getProductByPlanId(
    id: string,
    productsData: IProductObject[],
    ignoreCanceled = false
  ) {
    return productsData?.find(
      ({ attributes, relationships }) =>
        relationships.plan.data.id === id &&
        (ignoreCanceled ? attributes.status !== ProductStatus.Canceled : true)
    );
  },
};

export default DataHelper;
