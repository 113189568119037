import { AuthHelper } from "@/helpers";
import { useAccountContext } from "@/providers";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";

type AuthContext = {
  isLoading: boolean;
  isAuthenticated: boolean;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
};

const initialState: AuthContext = {
  isLoading: true,
  isAuthenticated: false,
  setAuthenticated: () => {},
};

const AuthContext = createContext<AuthContext>(initialState);

export const AuthProvider: React.FC = ({ children }) => {
  const { setAccountID } = useAccountContext();

  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [isAuthenticated, setAuthenticated] = useState(AuthHelper.isToken());

  useEffect(() => {
    setLoading(false);
  }, []);

  // Sync auth provider if jwt token is missing
  useEffect(() => {
    if (!AuthHelper.isToken()) {
      setAuthenticated(false);
    }
  });

  // When 'setAuthenticated(false)' is called, delete jwt token + accountID, and clear query cache
  useEffect(() => {
    if (!isAuthenticated) {
      AuthHelper.logout();
      setAccountID("");
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        setAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

export const useIsAuthenticated = () => {
  const context = useAuthContext();
  return context.isAuthenticated;
};
