import { useToggleOverflow } from "./hooks";
import { SelectPlan } from "./types";
import { ICouponAttributes, ProductInterval } from "@/api";
import { CheckIcon } from "@/components/icons/CheckIcon";
import { DownChevronIcon } from "@/components/icons/DownChevron";
import { numberToCurrency } from "@/utils";
import { Button } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import { isEmptyDocument } from "datocms-structured-text-utils";
import { StructuredText } from "react-datocms";

type SelectPlanComparisonItemProps = SelectPlan & {
  position: number;
  billingInterval: ProductInterval;
  yearlyPrice: number;
  monthlyPrice: number;
  stripePlanId: string;
  mode: "normal" | "upgrade" | "downgrade";
  onClick: (args: { planID: string; interval: ProductInterval }) => void;
  coupon?: ICouponAttributes;
};

export const SelectPlanComparisonItem = ({
  planName,
  description,
  ctaLabel: ctaLabelDefault,
  ctaLabelDowngrade,
  ctaLabelUpgrade,
  mode,
  features,
  position,
  billingInterval,
  yearlyPrice,
  monthlyPrice,
  stripePlanSlug,
  stripePlanId,
  onClick,
  coupon,
}: SelectPlanComparisonItemProps) => {
  const { ref: overflowRef, ...featureOverflow } = useToggleOverflow();
  const isFree = yearlyPrice === 0 && monthlyPrice === 0;
  // This is used primarily for adding "rainbow" styling to the plan card
  const hasProminentCardStyle =
    (mode === "normal" && isFree) ||
    (!isFree && ["upgrade", "downgrade"].includes(mode));
  const hasDisabledCta =
    (mode === "downgrade" && !isFree) || (mode === "upgrade" && isFree);

  const ctaLabel = {
    normal: ctaLabelDefault,
    upgrade: ctaLabelUpgrade,
    downgrade: ctaLabelDowngrade,
  }[mode];

  const ctaTestId = isFree
    ? "select-plan-item-button-select-free"
    : "select-plan-item-button-select-pro";

  const discount = coupon?.["percent-off"];

  const monthlyPriceWithDiscount = discount
    ? monthlyPrice * (1 - discount / 100)
    : monthlyPrice;

  const yearlyPriceWithDiscount = discount
    ? yearlyPrice * (1 - discount / 100)
    : yearlyPrice;

  const yearlySavingAnnualised = (yearlyPrice - yearlyPriceWithDiscount) * 12;
  const monthlySavingAnnualised =
    (monthlyPrice - monthlyPriceWithDiscount) * 12;

  const showDiscount =
    !!discount && !!yearlySavingAnnualised && !!monthlySavingAnnualised;

  return (
    <div
      id={`${stripePlanSlug}`}
      className={classNames(
        "md:col-span-5 w-full flex flex-col lg:flex-1 relative bg-white bg-gradient-to-r from-[#31256F] to-[#FCB76F] via-[#B44893] scroll-snap-child",
        {
          "md:col-start-2": position === 0,
          // Reveal the gradient background
          "px-[1px] pb-[1px] pt-2": hasProminentCardStyle && !showDiscount,
          "border-1": !hasProminentCardStyle,
          "p-[1px]": showDiscount,
        }
      )}
    >
      {showDiscount && (
        <div className="absolute right-0 top-0 bg-[#FFDA18] text-stealth-bomber py-2 px-4 rounded-bl-lg font-semibold font-18">
          {coupon?.code === "vsco" ? `VSCO Pro users save` : "Save"}{" "}
          {numberToCurrency(
            billingInterval === "month"
              ? monthlySavingAnnualised
              : yearlySavingAnnualised
          )}
        </div>
      )}
      <div
        className={classNames("bg-white pb-10 md:pb-13 flex flex-col h-full", {
          "pt-[29px]": hasProminentCardStyle,
          "pt-9": !hasProminentCardStyle,
        })}
      >
        {/* Header */}
        <div className="flex flex-col items-center px-10 pt-6 pb-6 border-b-1 text-center min-h-[210px]">
          <h3
            className={classNames(
              "relative mb-2.5 text-h3 leading-none md:text-34 font-semibold"
            )}
          >
            {planName}
          </h3>
          <div className="relative text-6xl font-semibold leading-none">
            <sup className="absolute mt-7 -ml-4 text-2xl font-light">$</sup>
            {numberToCurrency(
              billingInterval === ProductInterval.Month
                ? monthlyPriceWithDiscount
                : yearlyPriceWithDiscount,
              true
            )}
          </div>
          <div className="mt-1 leading-relaxed">
            {isEmptyDocument(description) ? (
              <>
                USD per month, billed&nbsp;
                {billingInterval === ProductInterval.Month
                  ? "monthly"
                  : "yearly"}
              </>
            ) : (
              <StructuredText data={description} />
            )}
          </div>
        </div>
        {/* End Header */}
        {/* Features */}
        <div className="px-4 md:px-8 pt-8 relative">
          <div
            ref={overflowRef}
            className={classNames("transition-[max-height] overflow-hidden", {
              "max-h-[440px] md:max-h-[310px]": !featureOverflow.isOpen,
              "max-h-[600px]": featureOverflow.isOpen,
            })}
          >
            <ul>
              {features.map((feature) => (
                <li
                  key={feature.id}
                  className="text-sm flex items-center justify-between mb-2"
                >
                  <div className="flex items-center md:mr-5">
                    <div className="mr-5">
                      <CheckIcon />
                    </div>
                    <StructuredText data={feature.name} />
                  </div>
                  {feature.description && (
                    <span
                      className="group text-cinderBlock hover:text-white hidden md:inline"
                      data-tip={feature.description}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                          fill="#1C1C1C"
                          className="invisible group-hover:visible"
                        />
                        <circle cx="7" cy="4" r="1" fill="currentColor" />
                        <rect
                          x="6"
                          y="6"
                          width="2"
                          height="5"
                          fill="currentColor"
                        />
                        <path
                          d="M13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7Z"
                          stroke="#E0E0E0"
                          className="visible group-hover:invisible"
                        />
                      </svg>
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {featureOverflow.hasOverflow && (
            <>
              {!featureOverflow.isOpen && (
                <div className="h-[66px] w-full left-0 bottom-[30px] bg-gradient-to-t from-white to-transparent absolute"></div>
              )}
              <button
                className="text-sm py-1 flex items-center w-[60px] justify-between"
                onClick={() => featureOverflow.toggle()}
              >
                {featureOverflow.isOpen ? "Less" : "More"}
                <DownChevronIcon
                  className={classNames("origin-center", {
                    "rotate-180": featureOverflow.isOpen,
                  })}
                />
              </button>
            </>
          )}
        </div>
        {/* End Features */}
        {/* CTA */}
        <div className="px-4 md:px-8 mt-auto">
          {hasDisabledCta ? (
            <Button
              appearance="primary"
              colour="gray-600"
              disabled
              className="text-sm normal-case"
              data-testid={ctaTestId}
            >
              {ctaLabel}
            </Button>
          ) : (
            <Button
              onClick={() =>
                onClick({ planID: stripePlanId, interval: billingInterval })
              }
              appearance={hasProminentCardStyle ? "rainbow" : "secondary"}
              colour="gray-600"
              className="text-sm normal-case"
              data-testid={ctaTestId}
            >
              {ctaLabel}
            </Button>
          )}
        </div>
        {/* End CTA */}
      </div>
    </div>
  );
};
