import { MODULE_KEYS } from "./config";
import { ResourceCard, HtmlToElement } from "@/components";
import { ImageType } from "@/types";

export interface ResourceFragmentType {
  _modelApiKey: typeof MODULE_KEYS.resource;
  id: string;
  items: ResourceItemFragmentType[];
  heading?: React.ReactNode;
  headingSize?: "h1" | "h2" | "h3";
}

export interface ResourceItemFragmentType {
  id: string;
  title: string;
  url?: string;
  product?: ("select_only" | "publish_only" | "both")[];
  image?: ImageType;
}

// Fragment
export const resourceFragment = `
  fragment resourceFragment on ModuleResourceRecord {
    _modelApiKey
    id
    heading
    headingSize
    items {
      id
      title
      url
      product
      image {
        url
        alt
        width
        height
        responsiveImage(imgixParams: { auto: format }) {
          ...responsiveImageFragment
        }
      }
    }
  }
`;

// Constructor
export const Resources: React.FC<ResourceFragmentType> = ({
  id,
  heading,
  headingSize,
  items,
}) => (
  <div key={id}>
    <HtmlToElement
      element={headingSize}
      className="text-h3 font-semibold leading-tight mb-8"
    >
      {heading}
    </HtmlToElement>
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
      {items.map(({ id, title, url, image }) => (
        <ResourceCard key={id} {...{ title, url, image }} />
      ))}
    </div>
  </div>
);
