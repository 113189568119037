import { ProductName, ProductInterval, usePlansQuery } from '@/api';
import { Banner } from '@/components';
import { LayoutPublic, SignUpFlow } from '@/containers';
import { TrackingHelper } from '@/helpers';
import { findDefaultFreePlanInPlans } from '@/helpers/plan';
import { useCoupon, useWaitForExternalResource } from '@/hooks';
import { productLowerCase } from '@/utils';
import classNames from 'classnames';
import { useState } from 'react';

type Props = {
  product: ProductName;
  interval: ProductInterval;
  planId?: string;
};

const GetStartedPage: React.FC<Props> = ({ product, interval, planId }) => {
  const { coupon, scheme } = useCoupon();
  const showBanner = !!coupon && scheme !== 'referral';

  const [showNav, setShowNav] = useState(false);
  const handleShowNav = () => setShowNav(true);

  const { data: plansData } = usePlansQuery();

  const finalPlanId =
    planId ||
    (product === ProductName.Select &&
      plansData?.data?.length &&
      findDefaultFreePlanInPlans(plansData.data)?.id);

  useWaitForExternalResource('https://analytics.tiktok.com/i18n/pixel/events.js', () => {
    TrackingHelper.tiktokPixelSignupEvent(productLowerCase(product));
  });

  return (
    <LayoutPublic hideNav={!showNav} hasBanner={showBanner}>
      {showBanner && (
        <Banner
          className={classNames('fixed z-10 top-0 left-0', {
            'bg-gengar': product === ProductName.Select,
            'bg-slowpoke': product === ProductName.Publish,
          })}
          testId="coupon-applied-banner"
        >
          <p className="text-white">
            {product === ProductName.Select
              ? 'Sign up today to activate your discount. We’ll apply it at the checkout should you choose to upgrade to Select Pro.'
              : 'Sign up today to activate your discount. We’ll apply it at the checkout should you choose to continue at the end of your trial.'}
          </p>
        </Banner>
      )}

      {finalPlanId && (
        <SignUpFlow
          {...{ product, interval, planID: finalPlanId, coupon }}
          onShowNav={handleShowNav}
        />
      )}
    </LayoutPublic>
  );
};

export default GetStartedPage;
