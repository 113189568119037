import { IS_PRODUCTION } from "@/config";
import { createUrl } from "@/utils";

const ActiveCampaignHelper = {
  get apiUrl() {
    return IS_PRODUCTION
      ? "https://narrative.api-us1.com/api/3"
      : "https://narrative1597701264.api-us1.com/api/3";
  },

  get formUrl() {
    return IS_PRODUCTION
      ? "https://narrative.activehosted.com/proc.php"
      : "https://narrative1597701264.activehosted.com/proc.php";
  },

  getApiUrl(path: string) {
    return `${this.apiUrl}/${path}`;
  },

  getCustomFieldByTitle(
    title: string,
    fields: { id: string; title: string }[]
  ) {
    return fields.find((field) => field.title === title);
  },

  getTagByTitle(title: string, tags: { id: string; tag: string }[]) {
    return tags.find(({ tag }) => tag === title);
  },

  async fetchCustomFields() {
    const response = await fetch("/api/ac-custom-fields", { method: "GET" });
    if (response.ok) {
      const data = await response.json();
      return data?.fields;
    }
  },

  async fetchContacts(email?: string) {
    let endpoint = "/api/ac-contacts";
    if (email) endpoint += "?" + new URLSearchParams({ email }).toString();
    const response = await fetch(endpoint, { method: "GET" });
    if (response.ok) {
      const data = await response.json();
      return data?.contacts;
    }
  },

  async fetchTags(tag?: string) {
    let endpoint = "/api/ac-tags";
    if (tag) endpoint += "?" + new URLSearchParams({ search: tag }).toString();
    const response = await fetch(endpoint, { method: "GET" });
    if (response.ok) {
      const data = await response.json();
      return data?.tags;
    }
  },

  createCustomFieldValue({
    value,
    fieldId,
    contactId,
  }: {
    value: string;
    fieldId: number;
    contactId: string;
  }) {
    return fetch("/api/ac-custom-fields", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fieldValue: {
          value,
          field: fieldId,
          contact: contactId,
        },
      }),
    });
  },

  assignTag({ tagId, contactId }: { tagId: string; contactId: string }) {
    return fetch("/api/ac-contact-tags", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contactTag: {
          tag: tagId,
          contact: contactId,
        },
      }),
    });
  },

  createOrUpdateContact(
    referreeEmail: string,
    fieldValues: { field: string; value: string }[]
  ) {
    return fetch("/api/ac-contacts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contact: {
          fieldValues,
          email: referreeEmail,
        },
      }),
    });
  },

  sendForm(data: Record<string, string>) {
    const formBaseUrl = this.formUrl;
    const url = createUrl(formBaseUrl, { ...data, jsonp: "true" });
    return fetch("/api/ac-form", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ url }),
    });
  },
};

export default ActiveCampaignHelper;
