import { ProductInterval } from "@/api";
import { useState } from "react";

const useBillingIntervalToggle = () => {
  const [interval, setInterval] = useState<ProductInterval>(
    ProductInterval.Year
  );
  return [interval, setInterval] as const;
};

export { useBillingIntervalToggle };
