import { ProductName } from "@/api";
import { ReferralIcon, ReferralCompleteIcon } from "@/containers";
import classNames from "classnames";

type Props = {
  index: number;
  product: ProductName;
  name?: string;
  signUpDate?: string;
  isActive?: boolean;
  isComplete?: boolean;
};

const Referral: React.FC<Props> = ({
  index,
  product,
  name,
  signUpDate,
  isActive,
  isComplete,
}) => (
  <article
    className={classNames(
      "relative flex items-center h-28 p-3 border-2 border-current rounded md:h-45 md:flex-col md:text-center",
      {
        "text-white justify-left md:justify-center": isActive,
        "bg-starmie": isActive && product === ProductName.Select,
        "bg-charmeleon": isActive && product === ProductName.Publish,
        "justify-center": !isActive,
        "text-starmie": !isActive && product === ProductName.Select,
        "text-charmeleon": !isActive && product === ProductName.Publish,
      }
    )}
  >
    <div
      className={classNames("mt-2 text-center md:m-0", {
        "ml-1 mr-4.5": isComplete,
        "ml-3 mr-4.5": isActive && !isComplete,
      })}
    >
      {isComplete ? (
        <ReferralCompleteIcon product={product} />
      ) : (
        <ReferralIcon isActive={isActive} />
      )}
      <span className="block -mt-4.5 text-26 text-current">{index + 1}</span>
    </div>
    {isActive && (
      <div className="md:mt-1">
        {name && (
          <span className="block text-13 font-semibold leading-compact mb-1.5">
            {name}
          </span>
        )}
        <span className="block text-11 leading-tight">
          Signed up {signUpDate}
        </span>
      </div>
    )}
    {index > 0 && (
      <div className="absolute bottom-full left-1/2 w-0.5 h-3.5 bg-current md:top-1/2 md:bottom-auto md:right-full md:left-auto md:h-0.5 md:w-8 lg:w-5 xl:w-8" />
    )}
  </article>
);

export default Referral;
