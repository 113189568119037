import { IS_PRODUCTION } from "@/config";
import Cookies from "js-cookie";

const DEFAULT_KEY = "narrative-coupon";

type CouponCookie = {
  code: string;
  scheme: "affiliate" | "referral" | "fp_ref" | null;
};

const CouponHelper = {
  getCode(key = DEFAULT_KEY) {
    const string = Cookies.get(key);
    if (string) {
      try {
        const data: CouponCookie = JSON.parse(string);
        return data?.code;
      } catch {
        this.deleteCookie();
      }
    }
  },

  getScheme(key = DEFAULT_KEY) {
    const string = Cookies.get(key);
    if (string) {
      try {
        const data: CouponCookie = JSON.parse(string);
        return data?.scheme || null;
      } catch {
        this.deleteCookie();
      }
    }
    return null;
  },

  setCookie(data: CouponCookie, key = DEFAULT_KEY) {
    const value = JSON.stringify(data);
    if (IS_PRODUCTION) {
      Cookies.set(key, value, { expires: 30, domain: ".narrative.so" });
    } else {
      Cookies.set(key, value, { expires: 30 });
    }
  },

  deleteCookie(key = DEFAULT_KEY) {
    if (IS_PRODUCTION) {
      Cookies.remove(key, { domain: ".narrative.so" });
    } else {
      Cookies.remove(key);
    }
  },
};

export default CouponHelper;
