const Remove: React.FC = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8333 1.2925L11.5408 0L6.41667 5.12417L1.2925 0L0 1.2925L5.12417 6.41667L0 11.5408L1.2925 12.8333L6.41667 7.70917L11.5408 12.8333L12.8333 11.5408L7.70916 6.41667L12.8333 1.2925Z"
      fill="#4F4F4F"
    />
  </svg>
);

export default Remove;
