export const SelectPlanComparisonMobileNav = ({
  plans,
}: {
  plans: { name: string; slug: string }[];
}) => {
  return (
    <div className="mt-6 lg:hidden">
      <nav>
        <ul className="flex space-x-3 justify-center">
          {plans.map(({ name, slug }) => {
            return (
              <li key={name}>
                <a
                  href={`#${slug}`}
                  className="block w-2.5 h-2.5 rounded-full bg-gray-900 opacity-20"
                >
                  <span className="sr-only">Go to plan {name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
