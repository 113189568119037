import {
  getUserOSStatus,
  getDeviceData,
  UserOSStatus,
  getProductDownloadUrl,
  DownloadLinks,
  resolveDownloadLinks,
  sendTrackingEventsForProduct,
  sendDownloadPageViewEvent,
} from "./utils";
import { Api } from "@/api";
import {
  SEO,
  LoadingScreen,
  UserOSAnswer,
  MobileDownloadSection,
  OtherOSDownloadSection,
  SupportedOSDownloadSection,
} from "@/components";
import { PAGE_RECORDS, DATOCMS_API_TOKEN } from "@/config";
import { LayoutPublic } from "@/containers";
import { useMounted, useQueryParams } from "@/hooks";
import { metaTagsFragment } from "@/modules";
import { Subscription, IDownload, Product } from "@/types";
import { GetStaticProps } from "next";
import { useEffect, useMemo } from "react";
import {
  renderMetaTags,
  useQuerySubscription,
  TitleMetaLinkTag,
} from "react-datocms";

interface IDownloadPage extends IDownload {
  seo: TitleMetaLinkTag[];
}

type DownloadPageProps = {
  product: Product;
  downloadLinks: DownloadLinks;
  subscription: Subscription<Maybe<IDownloadPage>>;
};

export const DownloadPage = ({
  product,
  subscription,
  downloadLinks,
}: DownloadPageProps) => {
  const { data } = useQuerySubscription(subscription);

  const { cancelTracking, userOSAnswer } = useQueryParams<{
    cancelTracking: Boolean;
    userOSAnswer: Maybe<UserOSAnswer>;
  }>();
  const isMounted = useMounted();
  const deviceData = useMemo(getDeviceData, []);
  const { isMacOS } = deviceData;
  const userOSStatus = getUserOSStatus({ product, deviceData, userOSAnswer });

  useEffect(() => {
    if (cancelTracking) return;

    sendDownloadPageViewEvent(deviceData);

    sendTrackingEventsForProduct({
      product,
      deviceData,
      userOSStatus,
    });
  }, [cancelTracking, deviceData]);

  if (!data) return null;

  const {
    seo,
    supportedTitle,
    supportedTextMacos,
    supportedTextWindows,
    supportedOtherTitle,
    supportedOtherText,
    unsupportedTitle,
    unsupportedText,
    mobileTitle,
    mobileText,
    unsupportedVersionTitle,
    unsupportedVersionTextMacos,
    unsupportedVersionTextWindows,
  } = data;

  const Section = getSectionForUserOSStatus(userOSStatus);

  const cmsCopyForUserOSStatus: Record<
    UserOSStatus,
    { title: string; text: string }
  > = {
    supported: {
      title: supportedTitle,
      text: isMacOS ? supportedTextMacos : supportedTextWindows,
    },
    mobile: {
      title: mobileTitle,
      text: mobileText,
    },
    unsupported: {
      title: unsupportedTitle,
      text: unsupportedText,
    },
    unsupported_version: {
      title: unsupportedVersionTitle,
      text: isMacOS
        ? unsupportedVersionTextMacos
        : unsupportedVersionTextWindows,
    },
    supported_other_device: {
      title: supportedOtherTitle,
      text: supportedOtherText,
    },
  };

  const copyForSection = cmsCopyForUserOSStatus[userOSStatus];
  const downloadUrl = getProductDownloadUrl(
    product,
    deviceData.osName,
    downloadLinks
  )?.HREF;

  return (
    <LayoutPublic isAuthPage testId={`page-${product}-download`}>
      <SEO title={`Download | Narrative ${product}`}>{renderMetaTags(seo)}</SEO>
      {!isMounted ? (
        <LoadingScreen />
      ) : (
        <Section {...copyForSection} {...{ product, downloadUrl }} />
      )}
    </LayoutPublic>
  );
};

const getSectionForUserOSStatus = (userOSStatus: UserOSStatus) => {
  switch (userOSStatus) {
    case "supported":
      return SupportedOSDownloadSection;
    case "unsupported":
    case "unsupported_version":
      return OtherOSDownloadSection;
    default:
      return MobileDownloadSection;
  }
};

/**
 * Static props
 */
export const getStaticProps: (
  product: Product
) => GetStaticProps<DownloadPageProps> =
  (product) =>
  async ({ preview = false }) => {
    const graphqlRequest = {
      preview,
      query: `
        {
          pages: allPageDownloads {
            macosOtherTitle
            macosOtherText
            mobileTitle
            mobileText
            supportedTitle
            supportedTextMacos
            supportedTextWindows
            supportedOtherTitle
            supportedOtherText
            unsupportedTitle
            unsupportedText
            unsupportedVersionTextWindows
            unsupportedVersionTitle
            unsupportVersionTextMacos
            parentPage {
              ... on ${PAGE_RECORDS.select} {
                slug
              }
              ... on ${PAGE_RECORDS.publish} {
                slug
              }
            }
            seo: _seoMetaTags {
              ...metaTagsFragment
            }
          }
        }
        ${metaTagsFragment}
      `,
    };

    const {
      data: { pages },
    } = await Api.datocmsGraphqlRequest<{ data: { pages: IDownloadPage[] } }>(
      graphqlRequest
    );

    // Get page that matches the product
    const initialData = pages.find(
      ({ parentPage }) => parentPage.slug === product
    );

    const downloadLinks = await resolveDownloadLinks();

    return {
      props: {
        product,
        downloadLinks,
        subscription: preview
          ? {
              ...graphqlRequest,
              initialData,
              token: DATOCMS_API_TOKEN,
            }
          : {
              initialData,
              enabled: false,
            },
      },
    };
  };
