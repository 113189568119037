import classNames from "classnames";

type Props = {
  className?: string;
};

const Container: React.FC<Props> = ({ className, children }) => (
  <main
    className={classNames(
      "container flex flex-1 flex-col justify-center mx-auto px-5 py-10 sm:px-6 md:py-20 lg:px-8",
      className
    )}
  >
    {children}
  </main>
);

export default Container;
