import { useGoogleOAuth } from "./useGoogleOAuth";
import { IGoogleOAuthResponse } from "@/api";
import { ROUTES } from "@/config";
import { AuthHelper } from "@/helpers";
import { useAlertDispatch } from "@/providers";
import { Product } from "@/types";
import { AlertActionType } from "@narrative-software/narrative-web-ui";
import Link from "next/link";
import { useCallback } from "react";

interface GoogleErrorResponse {
  status: number;
  code?: string;
  detail?: string;
  title?: string;
}

/**
 * Hook for Google sign up
 */
const useGoogleSignUp = (
  onSuccess: (data: IGoogleOAuthResponse) => void,
  showUserExistsError = true
) => {
  const alertDispatch = useAlertDispatch();

  const _onSuccess = (data: IGoogleOAuthResponse) => {
    // Always call success callback
    onSuccess(data);
    // Conditionally show user exists error - some callee's don't want to show this error
    if (!showUserExistsError || !(data?.oauth_status === "user_exists")) return;

    AuthHelper.deleteTokens();
    alertDispatch({
      type: AlertActionType.SetContent,
      payload: {
        type: "error",
        children: (
          <p>
            You already have a Narrative account associated with that Google
            account.{" "}
            <Link href={`/${ROUTES.LOGIN.SLUG}`}>
              <a className="underline">Sign in</a>
            </Link>
          </p>
        ),
      },
    });
  };

  const { openWindow, ...rest } = useGoogleOAuth(_onSuccess);

  const openSignUpWindow = useCallback(
    (product: Product) => openWindow(product),
    [openWindow]
  );

  return { openWindow: openSignUpWindow, ...rest };
};

export { useGoogleSignUp };
