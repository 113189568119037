const ExternalLink: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.999517 4.00781H7.99935L7.99935 11.0076L5.21338 8.22168L1.4982 11.9369L0.0839844 10.5227L3.79917 6.80747L0.999517 4.00781Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0H0V2H10V12H12L12 2V1V0Z"
      fill="#828282"
    />
  </svg>
);

export default ExternalLink;
