type CreditBalance = {
  balance: number;
};

type ServicesCreditBalanceResponse = {
  data: {
    attributes: {
      balance: number;
    };
  };
};

function deserializeCreditBalance({
  data: {
    attributes: { balance },
  },
}: ServicesCreditBalanceResponse): CreditBalance {
  return { balance };
}

export type { ServicesCreditBalanceResponse };
export { deserializeCreditBalance };
