import { MARKETING_BASE_URL } from "@/config";

export const ROUTES = {
  ACCOUNT: {
    SLUG: "",
    LABEL: "Account Details",
  },
  SUBSCRIPTIONS: {
    SLUG: "subscriptions",
    LABEL: "Subscriptions",
  },
  REFERRALS: {
    SLUG: "referrals",
    LABEL: "Get Pro Free",
  },
  AMBASSADORS: {
    SLUG: "ambassadors",
    LABEL: "Ambassador Dashboard",
  },
  LOGIN: {
    SLUG: "login",
    LABEL: "Sign In",
  },
  SIGN_UP: {
    SLUG: "sign-up",
    LABEL: "Create Account",
  },
  FORGOT_PASSWORD: {
    SLUG: "forgot-password",
    LABEL: "Forgot Password",
  },
  RESET_PASSWORD: {
    SLUG: "reset-password",
    LABEL: "Reset Password",
  },
  CHECKOUT: {
    SLUG: "checkout",
    LABEL: "Checkout",
  },
  GET_STARTED: {
    BASE: {
      SLUG: "get-started",
      LABEL: "Get started",
    },
    SELECT: {
      SLUG: "get-started/select",
      LABEL: "Get started",
    },
    PUBLISH: {
      SLUG: "get-started/publish",
      LABEL: "Get started",
    },
  },
  SELECT: {
    AUTH: {
      SLUG: "select/auth",
      LABEL: "Select Auth",
    },
    DOWNLOAD: {
      SLUG: "select/download",
      LABEL: "Download Select + Edit",
    },
  },
  PUBLISH: {
    AUTH: {
      SLUG: "publish/auth",
      LABEL: "Publish Auth",
    },
    DATABASE: {
      SLUG: "publish/database-upload",
      LABEL: "Publish Database Upload",
    },
    DOWNLOAD: {
      SLUG: "publish/download",
      LABEL: "Download Publish",
    },
  },
};

export const EXTERNAL = {
  BASE: {
    HREF: MARKETING_BASE_URL,
    LABEL: "Narrative",
  },
  CHANGELOG: {
    HREF: `${MARKETING_BASE_URL}/select/changelog`,
    LABEL: "Changelog",
  },
  SELECT: {
    BASE: {
      HREF: `${MARKETING_BASE_URL}/select`,
      LABEL: "Select",
    },
    PRICING: {
      HREF: `${MARKETING_BASE_URL}/select/pricing`,
      LABEL: "Select Pricing",
    },
    LESSONS: {
      HREF: `${MARKETING_BASE_URL}/select/lessons`,
      LABEL: "Lessons",
    },
  },
  EDIT: {
    BASE: {
      HREF: `${MARKETING_BASE_URL}/edit`,
      LABEL: "Edit",
    },
    PRICING: {
      HREF: `${MARKETING_BASE_URL}/edit/pricing`,
      LABEL: "Edit Pricing",
    },
  },
  PUBLISH: {
    BASE: {
      HREF: `${MARKETING_BASE_URL}/publish`,
      LABEL: "Publish",
    },
    PRICING: {
      HREF: `${MARKETING_BASE_URL}/publish/pricing`,
      LABEL: "Publish Pricing",
    },
    LESSONS: {
      HREF: `${MARKETING_BASE_URL}/publish/lessons`,
      LABEL: "Lessons",
    },
  },
  ABOUT: {
    HREF: `${MARKETING_BASE_URL}/about`,
    LABEL: "About Us",
  },
  BLOG: {
    HREF: `${MARKETING_BASE_URL}/blog`,
    LABEL: "Blog",
  },
  PARTNERSHIPS: {
    HREF: `${MARKETING_BASE_URL}/partnerships`,
    LABEL: "Partnerships",
  },
  CAREERS: {
    HREF: `${MARKETING_BASE_URL}/careers`,
    LABEL: "Careers",
  },
  SUPPORT: {
    HREF: "https://help.narrative.so/en/",
    LABEL: "Visit Support",
  },
  REFERRALS: {
    HREF: "https://narrativeapp.tapfiliate.com/dashboard",
    LABEL: "Referrals Dashboard",
  },
  TERMS: {
    HREF: `${MARKETING_BASE_URL}/terms-and-conditions`,
    LABEL: "Terms and Conditions",
  },
  PRIVACY: {
    HREF: `${MARKETING_BASE_URL}/privacy-policy`,
    LABEL: "Privacy Policy",
  },
};
