import { getIsProductCancelled } from "../SelectPlanComparison/utils";
import { CreditsPurchaseModal } from "./CreditsPurchaseModal/CreditsPurchaseModal";
import {
  IProductObject,
  IPlanObject,
  ProductName,
  ProductInterval,
} from "@/api";
import { CreditPurchasingPack } from "@/api/interfaces/editCreditPacks";
import { PlanCard } from "@/components/PlanCard";
import { ROUTES } from "@/config";
import { findDefaultFreePlanInPlans } from "@/helpers/plan";
import { useCreateOrUpdateProduct, useQueryParams } from "@/hooks";
import { useAccountContext, useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";
import Image from "next/image";
import router from "next/router";
import React, { useEffect } from "react";
import { FormattedNumber } from "react-intl";

const EDIT_PLAN_CARD_PROPS_COMMON = {
  planName: "Edit",
  description: "AI Edits in your style. 1-click ship to Lightroom.",
  iconFillClassName: "fill-ekans",
};

type PlanCardEditProps = {
  editCreditsBalance: number;
  preferredPackId: Nullable<Uuid>;
  plans: IPlanObject[];
  creditPacks: CreditPurchasingPack[];
  existingProduct?: IProductObject;
};

/**
 * Edit doesn't actually have plans, so this is a bit of a misnomer. It's subscription status is tied to the select plan card's.
 */
const PlanCardEdit = ({
  existingProduct,
  plans,
  creditPacks,
  preferredPackId,
  editCreditsBalance,
}: PlanCardEditProps) => {
  const isProductStatusCanceled = getIsProductCancelled(existingProduct);
  const modalDispatch = useModalDispatch();
  const queryParams = useQueryParams<{ packId: Uuid }>();

  const openModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        size: "fluid",
        hideBorder: true,
        showOverflow: true,
        children: (
          <CreditsPurchaseModal
            creditPacks={creditPacks}
            preferredPack={creditPacks.find(({ id }) => preferredPackId === id)}
          />
        ),
      },
    });
  };

  // If coming from the website, open the modal to purchase credits - even if unsubscribed.
  useEffect(() => {
    if (queryParams.packId) {
      openModal();
      // Remove query params as the modal is now launched and we don't want to keep showing it
      router.replace(router.pathname, undefined, { shallow: true });
    }
  }, []);

  if (!existingProduct || isProductStatusCanceled) {
    return <PlanCardEditUnsubscribed plans={plans} />;
  }

  return (
    <PlanCardEditSubscribed
      openModal={openModal}
      editCreditsBalance={editCreditsBalance}
    />
  );
};

type PlanCardEditUnsubscribedProps = {
  plans: IPlanObject[];
};

/**
 * Unsubscribed
 */
function PlanCardEditUnsubscribed({ plans }: PlanCardEditUnsubscribedProps) {
  const { accountID } = useAccountContext();
  const freePlan = findDefaultFreePlanInPlans(plans)!;
  const [
    doCreateOrUpdateProduct,
    { isLoading: isSendingCreateOrUpdateProduct },
  ] = useCreateOrUpdateProduct();

  return (
    <PlanCard
      {...EDIT_PLAN_CARD_PROPS_COMMON}
      buttonPrimary={
        <Button
          colour="ekans"
          appearance="primary"
          isLoading={isSendingCreateOrUpdateProduct}
          onClick={async () => {
            try {
              await doCreateOrUpdateProduct({
                accountID,
                planID: freePlan.id,
                interval: ProductInterval.Month,
                product: ProductName.Select,
              });
              await router.push(`/${ROUTES.SELECT.DOWNLOAD.SLUG}`);
            } catch {}
          }}
        >
          Try for free
        </Button>
      }
      image={
        <div className="mt-1.5">
          <Image
            src="/images/narrative-edit@2x.webp"
            alt="Narrative Select"
            width="230"
            height="152.5"
          />
        </div>
      }
    />
  );
}

type PlanCardEditSubscribedProps = {
  openModal: () => void;
  editCreditsBalance: number;
};

/**
 * Subscribed
 */
function PlanCardEditSubscribed({
  openModal,
  editCreditsBalance,
}: PlanCardEditSubscribedProps) {
  return (
    <PlanCard
      {...EDIT_PLAN_CARD_PROPS_COMMON}
      buttonPrimary={
        <Button colour="ekans" appearance="primary" onClick={openModal}>
          Buy Credits
        </Button>
      }
    >
      <div className="mx-auto w-max min-w-[100px]">
        <PlanCard.Item term="Credits">
          <FormattedNumber value={editCreditsBalance} />
        </PlanCard.Item>
      </div>
    </PlanCard>
  );
}

export { PlanCardEdit };
