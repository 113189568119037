// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const IS_PRODUCTION =
  process.env.NODE_ENV === "production" &&
  process.env.NEXT_PUBLIC_NODE_ENV !== "test";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (IS_PRODUCTION) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://e3603c97d1444a09bb9924550dca573d@o143148.ingest.sentry.io/5420656",
    environment: "production",
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
