// Added my own node env because Netlify seems to lock NODE_ENV to 'production'
export const IS_DEVELOPMENT =
  process.env.NODE_ENV === "development" &&
  process.env.NEXT_PUBLIC_NODE_ENV !== "test";
export const IS_STAGING =
  process.env.NODE_ENV === "test" ||
  process.env.NEXT_PUBLIC_NODE_ENV === "test";
export const IS_PRODUCTION =
  process.env.NODE_ENV === "production" &&
  process.env.NEXT_PUBLIC_NODE_ENV !== "test";

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const MARKETING_BASE_URL =
  process.env.NEXT_PUBLIC_NEW_MARKETING_BASE_URL || "";
export const DATOCMS_API_TOKEN = process.env.DATOCMS_API_TOKEN || "";
export const SELECT_FREE_PROJECT_THRESHOLD_ENDPOINT = `https://narrative-select-project-limit.s3.amazonaws.com/${
  process.env.NEXT_PUBLIC_SELECT_FREE_PROJECT_THRESHOLD_FILE ||
  "project-limit-staging.txt"
}`;
export const BRANCH = process.env.NEXT_PUBLIC_BRANCH;
export const COMMIT_REF = process.env.NEXT_PUBLIC_COMMIT_REF;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY || "";
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;
export const INTERCOM_ID = process.env.NEXT_PUBLIC_INTERCOM_ID;
// VWO is A/B testing software
export const VWO_ACCOUNT_ID = process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID;
export const ACTIVE_CAMPAIGN_API_KEY =
  process.env.ACTIVE_CAMPAIGN_API_KEY || "";

export const SELECT_DEEP_LINK_URL =
  process.env.NEXT_PUBLIC_SELECT_DEEP_LINK_URL;
export const PUBLISH_DEEP_LINK_URL =
  process.env.NEXT_PUBLIC_PUBLISH_DEEP_LINK_URL;

export const IS_GOOGLE_AUTH_ENABLED =
  process.env.NEXT_PUBLIC_IS_GOOGLE_AUTH_ENABLED === "true";

export const BETA_FEATURES_CONFIG_URL =
  process.env.NEXT_PUBLIC_BETA_FEATURES_URL || "";
