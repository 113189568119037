import {
  useUpdateProductMutation,
  ProductName,
  ProductInterval,
  IPlanObject,
  IProductObject,
  usePreviewChargeMutation,
} from "@/api";
import { CancelOptionsModal } from "@/components";
import { ROUTES } from "@/config";
import { useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";
import moment from "moment";
import { useRouter } from "next/router";

type ConfirmPlanModalProps = {
  product: IProductObject;
  parkingPlanID: string;
  plan?: IPlanObject;
};

const ConfirmPlanModal = ({
  product,
  parkingPlanID,
  plan,
}: ConfirmPlanModalProps) => {
  const router = useRouter();
  const modalDispatch = useModalDispatch();
  const { mutateAsync: doPreviewCharge, isLoading: isLoadingPreviewCharge } =
    usePreviewChargeMutation();
  const { mutateAsync: doUpdateProduct, isLoading: isLoadingUpdateProduct } =
    useUpdateProductMutation();

  const productName = product.attributes.name;
  const isLoading = isLoadingPreviewCharge || isLoadingUpdateProduct;
  const nextPayment = moment
    .unix(product.attributes["current-period-end"])
    .format("D MMM YYYY");

  // Handle back click
  const handleBackClick = () => {
    if (plan) {
      modalDispatch({
        type: ModalActionType.SetContent,
        payload: {
          children: (
            <CancelOptionsModal {...{ product, plan, parkingPlanID }} />
          ),
        },
      });
    } else {
      modalDispatch({ type: ModalActionType.Close });
    }
  };

  // Handle confirm click
  const handleConfirmClick = async () => {
    try {
      const productData = {
        planID: parkingPlanID,
        productID: product.id,
        interval: ProductInterval.Year,
      };

      // Preview charge
      const chargeData = await doPreviewCharge(productData);
      const isCharge = chargeData?.data.attributes.total > 0;

      // If a payment is required, redirect to checkout
      if (isCharge) {
        const { planID, interval } = productData;
        await router.push({
          pathname: `/${ROUTES.CHECKOUT.SLUG}`,
          query: { planID, interval },
        });
      } else {
        await doUpdateProduct(productData);
      }

      modalDispatch({ type: ModalActionType.Close });
    } catch {}
  };

  // Text map
  const textMap = {
    [ProductName.Select]: {
      plan: `Select + Edit Free Plan`,
      price: `FREE`,
      content: (
        <>
          <p>
            On the Select + Edit Free plan you will still be able to access your
            existing projects and you can continue to create unlimited new
            projects - however - with any new projects you create, you will not
            have access to Select + Edit&apos;s most powerful features.
          </p>
        </>
      ),
    },
    [ProductName.Publish]: {
      plan: `Parking Plan`,
      price: `$10`,
      content: (
        <>
          <p className="mb-4">
            You’ll no longer be able to add or edit posts, but your current
            posts will stay live for $10/year.
          </p>
          <p>
            You will receive a prorated invoice for this plan change today. The
            next $10 charge will be on {nextPayment} and billed annually
            onwards.{" "}
          </p>
        </>
      ),
    },
    [ProductName.Save]: {
      plan: "",
      price: "",
      content: "",
    },
  };

  return (
    <>
      <h3 className="mb-9 text-h3 font-semibold leading-tight">
        Confirm your new plan
      </h3>
      <div className="flex justify-between mb-8 leading-tight">
        <div>
          <sup className="text-11 text-gray-700">Billed Annually</sup>
          <h5 className="text-h5 font-semibold">{textMap[productName].plan}</h5>
        </div>
        <div className="text-right">
          <sup className="text-11 text-gray-700">(USD)</sup>
          <h5 className="text-h5 font-semibold">
            {textMap[productName].price}
          </h5>
        </div>
      </div>
      <div className="mb-8">{textMap[productName].content}</div>
      <div className="flex space-x-2.5 sm:max-w-80 sm:ml-auto">
        <Button
          appearance="secondary"
          colour="gray-600"
          onClick={handleBackClick}
          disabled={isLoading}
        >
          {plan ? "Back" : "Cancel"}
        </Button>
        <Button
          colour="black"
          onClick={handleConfirmClick}
          isLoading={isLoading}
          showLoader
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default ConfirmPlanModal;
