import PublishImage from "../../../public/svg/photographer.svg";
import SelectImage from "../../../public/svg/woman-and-cat.svg";
import { HtmlToElement } from "@/components";
import { IS_DEVELOPMENT } from "@/config";
import { TOPICS, publishEvent } from "@/events";
import { Product } from "@/types";
import { capitalize } from "@/utils";
import Image from "next/image";
import { useEffect } from "react";

interface Props {
  product: Product;
  title: React.ReactNode;
  text: React.ReactNode;
  downloadUrl: string;
}

const SupportedOSDownloadSection: React.FC<Props> = ({
  product,
  title,
  text,
  downloadUrl,
}) => {
  const handleDownloadClick = () => {
    publishEvent(TOPICS.downloadStartedManually, { app: product });
  };

  // Start download automatically on page load after 3 seconds
  useEffect(() => {
    if (!IS_DEVELOPMENT) {
      const timeout = setTimeout(() => {
        publishEvent(TOPICS.downloadStartedAutomatically, { app: product });
        document.location.href = downloadUrl;
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  return (
    <div className="flex flex-col items-center text-center">
      <HtmlToElement
        element="h1"
        className="text-h2 font-semibold leading-tight mb-10 lg:text-h1"
      >
        {title}
      </HtmlToElement>

      <p className="text-lg mb-8">
        Narrative {capitalize(product)} should be downloading now. If not, you
        can{" "}
        <a
          href={downloadUrl}
          className="underline"
          onClick={handleDownloadClick}
          download
        >
          try again
        </a>
        .
      </p>

      {product === "select" && (
        <Image src={SelectImage} alt="" width="346" height="380" />
      )}
      {product === "publish" && (
        <Image src={PublishImage} alt="" width="320" height="380" />
      )}

      <HtmlToElement element="p" className="text-lg mt-9">
        {text}
      </HtmlToElement>
    </div>
  );
};

export default SupportedOSDownloadSection;
