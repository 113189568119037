type Props = {
  isActive?: boolean;
};

const ReferralIcon: React.FC<Props> = ({ isActive }) => (
  <svg width="53" height="55" viewBox="0 0 53 55">
    {isActive && (
      <path
        d="M35.7117 11.7223L25.2243 21.7727L19.3081 16.1031L20.6919 14.6591L25.2243 19.0026L34.3279 10.2783L35.7117 11.7223Z"
        fill="currentColor"
      />
    )}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9042 28.7933C38.253 26.5008 42 21.188 42 15C42 6.71573 35.2843 0 27 0C18.7157 0 12 6.71573 12 15C12 21.0521 15.5842 26.267 20.7457 28.6379C8.86602 31.3167 0 42.1156 0 55H2C2 41.1745 12.9872 30 26.5 30C40.0128 30 51 41.1745 51 55H53C53 42.3517 44.4559 31.7131 32.9042 28.7933ZM27 28C34.1797 28 40 22.1797 40 15C40 7.8203 34.1797 2 27 2C19.8203 2 14 7.8203 14 15C14 22.1797 19.8203 28 27 28Z"
      fill="currentColor"
    />
  </svg>
);

export default ReferralIcon;
