import { useUpdateAccountMutation } from "@/api";
import { PaymentFields } from "@/containers";
import { RequestHelper } from "@/helpers";
import { useAccountContext } from "@/providers";
import { Button } from "@narrative-software/narrative-web-ui";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState } from "react";

type Props = {
  closeModal: () => void;
  buttonText?: string;
};

const UpdatePaymentForm: React.FC<Props> = ({
  closeModal,
  buttonText = "Update",
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { accountID } = useAccountContext();
  const [submitting, setSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState("");

  // Note: the update account API call may fail if the credit card is invalid. In this case don't show
  // an error alert, just show an 'invalid credit card' message in this form.
  const { mutateAsync: doUpdateAccount } = useUpdateAccountMutation(
    { successMessage: "Credit card updated successfully!" },
    { onError: () => setStripeError("Your card number is invalid.") }
  );

  // Handle submit
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      if (!stripe || !elements || !accountID) {
        return;
      }

      setSubmitting(true);

      const cardElement = elements.getElement(CardElement);
      const tokenObj = cardElement && (await stripe.createToken(cardElement));

      if (tokenObj?.token) {
        const body = RequestHelper.getUpdatePaymentRequestBody({
          id: accountID,
          token: tokenObj.token.id,
        });
        await doUpdateAccount(body);
        closeModal();
      } else if (tokenObj?.error) {
        setStripeError(tokenObj.error.message || "An unknown error occurred.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentFields
        error={stripeError}
        delayStripeMount
        onChange={() => setStripeError("")}
      />
      <div className="flex space-x-2.5 mt-12 sm:max-w-80 sm:ml-auto">
        <Button
          colour="gray-600"
          appearance="secondary"
          disabled={submitting}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button type="submit" colour="black" isLoading={submitting} showLoader>
          {buttonText}
        </Button>
      </div>
    </form>
  );
};

export default UpdatePaymentForm;
