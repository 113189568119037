import { ProductName } from "@/api";
import { UserOSAnswer } from "@/components";
import { IS_PRODUCTION, FACEBOOK_PIXEL_ID } from "@/config";
import { BlogOptions, GenreOptions } from "@/containers";
import { TOPICS, publishEvent } from "@/events";
import { CouponHelper } from "@/helpers";
import { Product } from "@/types";
import { browserDetection } from "@/utils";

const { userCurrentMachine } = browserDetection;

const TrackingHelper = {
  googleAnalyticsTrackCustom(event: string, data: Record<string, any> = {}) {
    if (IS_PRODUCTION) {
      const dataLayer = (window as any).dataLayer;
      if (dataLayer) {
        dataLayer.push({ event, ...data });
      }
    }
  },

  async facebookPixelTrackCustom(title: string, data?: Record<string, any>) {
    if (IS_PRODUCTION && FACEBOOK_PIXEL_ID) {
      const ReactPixel = await import("react-facebook-pixel");
      ReactPixel.default.init(FACEBOOK_PIXEL_ID);
      ReactPixel.default.trackCustom(title, data);
    }
  },

  tiktokPixelDownloadEvent(product: Product) {
    if (IS_PRODUCTION) {
      const ttq = (window as any).ttq;
      if (ttq) {
        ttq.track("Download", {
          content_type: "product",
          content_id: product,
        });
      }
    }
  },

  tiktokPixelSignupEvent(product: Product) {
    if (IS_PRODUCTION) {
      const ttq = (window as any).ttq;
      if (ttq) {
        ttq.track("Signup", {
          content_type: "product",
          content_id: product,
        });
      }
    }
  },

  linkedinInsightDownloadEvent(id: number, product?: Product) {
    if (IS_PRODUCTION) {
      const lintrk = (window as any).lintrk;
      if (lintrk) {
        lintrk("track", {
          product,
          conversion_id: id,
        });
      }
    }
  },

  sendSignUpSucceededEvent({
    product,
    genre,
    genreOther,
    hasBlog,
    userOSAnswer,
    method,
    userId,
    coupon,
    signupSources,
    signupSourceOther,
  }: {
    product: ProductName;
    genre: GenreOptions;
    genreOther: string;
    hasBlog: BlogOptions;
    userOSAnswer: UserOSAnswer;
    method: "email" | "google";
    userId?: string;
    coupon?: string;
    signupSources: string[];
    signupSourceOther: string;
  }) {
    const isProPhotographer = genre !== "I’m not a professional photographer";

    // Event logging
    const data: any = {
      app: product.toLowerCase(),
      user_id: userId,
      coupon_code_used: coupon || null,
      scheme_of_coupon_used:
        coupon === CouponHelper.getCode() ? CouponHelper.getScheme() : null,
      mobile_sign_up_os:
        userOSAnswer && userCurrentMachine === "mobile" ? userOSAnswer : null,
      tablet_sign_up_os:
        userOSAnswer && userCurrentMachine === "tablet" ? userOSAnswer : null,
      windows_sign_up_os:
        userOSAnswer && userCurrentMachine === "windows" ? userOSAnswer : null,
      chromeos_sign_up_os:
        userOSAnswer && userCurrentMachine === "chrome" ? userOSAnswer : null,
      linux_sign_up_os:
        userOSAnswer && userCurrentMachine === "linux" ? userOSAnswer : null,
      genre: genre || null,
      genre_array: genre ? [genre] : null, // For backwards compatibility
      genre_other: genreOther || null,
      sign_up_method: method,
      personal_sign_up_source: signupSources || null,
      personal_sign_up_source_other: signupSourceOther || null,
    };

    if (product === ProductName.Publish) {
      data.existing_website = hasBlog === "yes";
    }

    publishEvent(TOPICS.signUpSucceeded, data, 3);

    // Mobile sign up tracking
    if (
      isProPhotographer &&
      (userCurrentMachine === "mobile" || userCurrentMachine === "tablet")
    ) {
      this.googleAnalyticsTrackCustom("mobile_sign_up_success", {
        os: userOSAnswer,
      });
      this.facebookPixelTrackCustom("mobile_sign_up_success", {
        os: userOSAnswer,
      });
    }
  },
};

export default TrackingHelper;
