import { ShareLink } from "@/containers";
import CalendarMonthPreviousIcon from "@/svg/calendar-month-previous.svg";
import CalendarMonthIcon from "@/svg/calendar-month.svg";
import PencilIcon from "@/svg/pencil.svg";
import PointerClickIcon from "@/svg/pointer-click.svg";
import { Product } from "@/types";
import { capitalize, numberToCurrency } from "@/utils";
import classNames from "classnames";
import Image from "next/image";

type InfoBoxProps = {
  count: string | number;
  icon: React.ReactNode;
  product: Product;
};

const InfoBox: React.FC<InfoBoxProps> = ({
  count,
  icon,
  product,
  children,
}) => (
  <article
    className={classNames(
      "relative flex items-center h-28 border-2 border-current rounded text-white sm:h-auto sm:flex-col sm:px-3 sm:pt-9 sm:pb-5 sm:text-center",
      {
        "bg-starmie": product === "select",
        "bg-charmeleon": product === "publish",
      }
    )}
  >
    <div className="flex flex-col flex-shrink-0 items-center justify-center w-28 h-full pt-2 mb-0.5 border-r border-white border-opacity-25 sm:w-auto sm:h-auto sm:pt-0 sm:mb-1 sm:border-none">
      <div className="flex items-center mb-0.5 sm:mb-1 sm:h-7.5">{icon}</div>
      <span className="block text-26">{count}</span>
    </div>
    <div className="px-6 sm:px-0">
      <p className="text-13 font-semibold leading-tight">{children}</p>
    </div>
  </article>
);

type Props = {
  product: Product;
  code: string;
  clicks: number;
  signUps: number;
  commissionCurrentMonth: number;
  commissionPreviousMonth: number;
  className?: string;
};

const AffiliateSignUps: React.FC<Props> = ({
  product,
  code,
  clicks,
  signUps,
  commissionCurrentMonth,
  commissionPreviousMonth,
  className,
}) => {
  const productName = capitalize(product);
  return (
    <section
      className={classNames("p-8 lg:px-16 lg:py-14", className, {
        "bg-gengar": product === "select",
        "bg-slowpoke": product === "publish",
      })}
    >
      <h3 className="text-h3 font-semibold text-white leading-tight mb-4">
        Your {productName} affiliate sign-ups
      </h3>
      <div className="grid grid-cols-1 gap-3 mt-6 mb-8 sm:mt-8 sm:mb-10 sm:grid-cols-4 md:gap-7.5 lg:gap-4 xl:gap-7.5">
        <InfoBox
          count={clicks}
          icon={<Image src={PointerClickIcon} alt="" unoptimized />}
          product={product}
        >
          Number of clicks on your {productName} affiliate link this month
        </InfoBox>
        <InfoBox
          count={signUps}
          icon={<Image src={PencilIcon} alt="" unoptimized />}
          product={product}
        >
          Number of activated {productName} sign-ups this month
        </InfoBox>
        <InfoBox
          count={numberToCurrency(commissionCurrentMonth * 100)}
          icon={<Image src={CalendarMonthIcon} alt="" unoptimized />}
          product={product}
        >
          Commission you’ll get paid for <u>this</u> month
        </InfoBox>
        <InfoBox
          count={numberToCurrency(commissionPreviousMonth * 100)}
          icon={<Image src={CalendarMonthPreviousIcon} alt="" unoptimized />}
          product={product}
        >
          Commission you got paid <u>last</u> month
        </InfoBox>
      </div>
      <ShareLink
        type="affiliate"
        code={code}
        product={product}
        titleClassName="text-white"
      />
    </section>
  );
};

export default AffiliateSignUps;
