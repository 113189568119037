import { ROUTES } from "@/config";
import Link from "next/link";

type Props = {
  title?: string;
};

const ErrorPanel: React.FC<Props> = ({ children, title }) => (
  <div className="w-full max-w-3xl mx-auto">
    <div className="flex p-6 bg-red-100">
      <div className="flex-shrink-0">
        <svg
          className="h-5 w-5 text-red-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          />
        </svg>
      </div>
      <div className="ml-3">
        {title && (
          <h3 className={`font-semibold text-red-800 leading-6 mb-2`}>
            {title}
          </h3>
        )}
        <p className="text-sm text-red-700 leading-5 mb-1.5">{children}</p>
        <p className="text-sm text-red-700 leading-5">
          <Link href={`/${ROUTES.SUBSCRIPTIONS.SLUG}`}>
            <a className="underline hover:no-underline">Back to dashboard</a>
          </Link>
        </p>
      </div>
    </div>
  </div>
);

export default ErrorPanel;
