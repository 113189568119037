import PlanCardItem from "./PlanCardItem";
import React, { ReactNode } from "react";

type PlanCardProps = {
  planName: string;
  iconFillClassName: string;
  description: React.ReactNode;
  buttonPrimary: React.ReactNode;
  image?: ReactNode;
  buttonSecondary?: React.ReactNode;
  children?: React.ReactNode;
};

const PlanCard = ({
  planName,
  buttonPrimary,
  children,
  description,
  image = null,
  iconFillClassName,
  buttonSecondary = null,
}: PlanCardProps) => {
  return (
    <article
      className="flex flex-col flex-1 items-center px-9% pt-11 pb-16 bg-white"
      data-testid={`plan-card-${planName.toLowerCase()}`}
    >
      <div className="flex flex-col items-center text-h3 leading-6">
        <div className="mb-2">
          <Icon fillClassName={iconFillClassName} />
        </div>
        <span className="mb-1 font-semibold">Narrative</span>
        <span className="font-extralight">{planName}</span>
      </div>

      {children ? (
        <dl className="w-full max-w-95 mt-8 text-xs leading-tight">
          {children}
        </dl>
      ) : (
        <div className="mt-6 text-center">
          <p>{description}</p>
          {image && (
            <div className="flex items-start justify-center">{image}</div>
          )}
        </div>
      )}

      <div className="relative w-full pt-6 mt-auto">
        {buttonPrimary}
        {buttonSecondary && (
          <div className="absolute w-full mt-4.5 text-center text-xxxs">
            {buttonSecondary}
          </div>
        )}
      </div>
    </article>
  );
};

type IconProps = {
  fillClassName: string;
};

// Icon
const Icon = ({ fillClassName }: IconProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36">
    <circle cx="18.0258" cy="17.9281" r="17.9281" className={fillClassName} />
    <path
      fill="#FFFFFF"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1328 13.1038L11.1328 25.5155L13.2014 25.5155L13.2014 13.1038L19.4073 13.1038L19.4073 21.3783L21.4759 21.3783L21.4759 13.1038L21.4759 11.0352L19.4073 11.0352L13.2014 11.0352L11.1328 11.0352L11.1328 13.1038ZM25.6132 11.0352L23.5446 11.0352L23.5446 23.4453L17.3387 23.4453L17.3387 15.1724L15.2701 15.1724L15.2701 23.4453H15.2695L15.2695 25.5139H15.2701V25.5155L17.3387 25.5155V25.5139L23.5446 25.5139V25.5155L25.6132 25.5155L25.6132 11.0352Z"
    />
  </svg>
);

PlanCard.displayName = "PlanCard";
PlanCard.Item = PlanCardItem;

export default PlanCard;
