import { LoadingScreen } from "@/components";
import { useQueryParams, useNonAuthRedirect, useAuthViaToken } from "@/hooks";
import { useAccountContext, useAuthContext } from "@/providers";
import { isBrowser } from "@/utils";
import { NextPage } from "next";
import { useRouter } from "next/router";

/**
 * Support client-side conditional redirecting based on the user's authenticated state.
 *
 * @param location - The location to redirect to.
 * @param isAuthRequired - Whether the user should be authenticated for the component to be rendered.
 * @param WrappedComponent - The component that this functionality will be added to.
 */
export default function withAuthRedirect<
  CP = Record<string, unknown>,
  IP = CP
>({
  location,
  isAuthRequired,
  WrappedComponent,
}: {
  location: string;
  isAuthRequired: boolean;
  WrappedComponent: NextPage<CP, IP>;
}): NextPage<CP, IP> {
  const Component: React.FC<any> = (props) => {
    const router = useRouter();
    const query = useQueryParams();
    const doNonAuthRedirect = useNonAuthRedirect();
    const { accountID, isLoading: isLoadingAccountContext } =
      useAccountContext();
    const { isAuthenticated, isLoading: isLoadingAuthContext } =
      useAuthContext();

    const checkedForPassedToken = useAuthViaToken();

    // Loading
    const loadingScreen = <LoadingScreen addLayout />;
    const isLoading =
      !router.isReady || isLoadingAccountContext || isLoadingAuthContext;
    if (isLoading) {
      return loadingScreen;
    }

    // Client side routing
    if (isBrowser() && checkedForPassedToken) {
      // Logout if an 'accountID' query param is set and it does NOT match the user's accountID
      if (accountID && query.accountID && accountID !== query.accountID) {
        delete query.accountID;
        (async () => await doNonAuthRedirect(router.pathname, query))(); // Logout and redirect to login page, then redirect back to initial page if the user login succeeds
        return loadingScreen;
      }

      // The path to redirect to
      let pathname = location;

      // When the authentication state doesn’t match the page requirement
      if (isAuthenticated !== isAuthRequired) {
        // User is authenticated on 'withoutAuth' route
        if (isAuthenticated) {
          // Set path to backPath if it exists
          if (query.backPath) {
            pathname = query.backPath;
            delete query.backPath;
          }
        } else if (isAuthRequired) {
          // Set path to backPath if it exists
          if (query.backPath) {
            pathname = query.backPath;
            delete query.backPath;
          } else {
            // User is NOT authenticated on 'withAuth' route, set backPath to current path
            query.backPath = router.pathname;
          }
        }

        // Format pathname if required
        pathname = pathname?.charAt(0) === "/" ? pathname : `/${pathname}`;

        // Redirect
        (async () => await router.replace({ pathname, query }))();

        return loadingScreen;
      }
    }

    return <WrappedComponent {...props} />;
  };

  return Component;
}
