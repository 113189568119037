import { InviteModal } from "@/containers";
import { useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";

const getProductTitle = ({
  isSubscribedToSelect,
  isSubscribedToPublish,
}: {
  isSubscribedToSelect: boolean;
  isSubscribedToPublish: boolean;
}) => {
  if (isSubscribedToSelect && isSubscribedToPublish)
    return "Select and Publish";
  if (isSubscribedToSelect) return "Select";
  if (isSubscribedToPublish) return "Publish";
  return "";
};

type Props = {
  code: string;
  email: string;
  isSubscribedToSelect: boolean;
  isSubscribedToPublish: boolean;
};

const Invite: React.FC<Props> = ({
  code,
  email,
  isSubscribedToSelect,
  isSubscribedToPublish,
}) => {
  const modalDispatch = useModalDispatch();
  const productTitle = getProductTitle({
    isSubscribedToSelect,
    isSubscribedToPublish,
  });

  const openInviteModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <InviteModal
            {...{
              productTitle,
              code,
              email,
              isSubscribedToSelect,
              isSubscribedToPublish,
            }}
          />
        ),
      },
    });
  };

  return (
    <article className="mt-10 lg:mt-14">
      <h4 className="text-lg font-semibold mb-3">Invite your friends</h4>
      <p className="mb-4 text-14 lg:text-15">
        Let us email an invite to your friends to try Narrative {productTitle}{" "}
        from you.
      </p>
      <Button colour="black" onClick={openInviteModal} className="max-w-60">
        Invite friends
      </Button>
    </article>
  );
};

export default Invite;
