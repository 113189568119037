import type { SelectPlan } from "./types";
import { IPlanObject, IProductObject, ProductStatus } from "@/api";
import { SELECT_PLAN_SLUGS } from "@/config";
import {
  findDefaultFreePlanInPlans,
  findDefaultProPlanInPlans,
} from "@/helpers/plan";
import { createUrl } from "@/utils";

const mergeCMSPlanWithStripePlan = (
  cmsPlans: SelectPlan[],
  stripePlans: IPlanObject[]
) => {
  return cmsPlans
    .map((plan) => {
      let stripePlan = stripePlans.find(
        (stripePlan) => stripePlan.attributes.slug === plan.stripePlanSlug
      );

      if (
        !stripePlan &&
        SELECT_PLAN_SLUGS.allFree.includes(plan.stripePlanSlug)
      ) {
        stripePlan = findDefaultFreePlanInPlans(stripePlans);
      }

      if (
        !stripePlan &&
        SELECT_PLAN_SLUGS.allPro.includes(plan.stripePlanSlug)
      ) {
        stripePlan = findDefaultProPlanInPlans(stripePlans);
      }

      // Should never happen, but just in case there's a CMS data entry error
      if (!stripePlan) return null;

      return {
        ...plan,
        monthlyPrice: stripePlan.attributes["monthly-price"] || 0,
        yearlyPrice: (stripePlan.attributes["yearly-price"] || 0) / 12,
        stripePlanId: stripePlan.id,
      };
    })
    .flatMap((v) => (v ? [v] : []));
};

const getIsProductCancelled = (product?: IProductObject) =>
  product?.attributes.status === ProductStatus.Canceled;

export { mergeCMSPlanWithStripePlan, getIsProductCancelled };
