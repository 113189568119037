import a from "../../../../public/images/sale-pack-bg.webp";
import { CreditPurchasingPack } from "@/api/interfaces/editCreditPacks";
import saleBackgroundImage from "@/images/sale-pack-bg.webp";
import { numberToCurrency } from "@/utils";
import classNames from "classnames";
import Image from "next/image";

type EditPlanPackRadioItemProps = {
  pack: CreditPurchasingPack;
  selected: boolean;
  onSelectPack: (pack: CreditPurchasingPack) => void;
};

function EditPlanPackRadioItem({
  pack,
  selected,
  onSelectPack,
}: EditPlanPackRadioItemProps) {
  const isSale = pack.isSale;

  return (
    <label
      key={pack.id}
      className={classNames(
        `border rounded-sm cursor-pointer flex transition-colors relative`,
        {
          "border-cinder-block": !isSale && !selected,
          "hover:border-stealth-bomber": !isSale && !selected,
          "border-stealth-bomber": isSale || selected,
          "bg-caspar": !isSale && !selected,
        }
      )}
    >
      {isSale && (
        <div className="absolute top-0 right-0 bottom-0 left-0">
          <Image
            src={saleBackgroundImage}
            alt=""
            layout="fill"
            draggable={false}
          />
          <div
            className="absolute top-0 right-0 bottom-0 left-0"
            style={{
              background: `radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)`,
            }}
          />
        </div>
      )}
      <div className="flex col items-center justify-center border-r border-ball-bearing px-2 relative">
        <div
          className={classNames(
            "rounded-full h-4.5 w-4.5 border flex flex-col items-center justify-center",
            {
              "border-stealth-bomber": !isSale,
              "border-white": isSale,
            }
          )}
        >
          <div
            className={classNames("rounded-full transition-opacity h-3 w-3", {
              "opacity-0": !selected,
              "opacity-100": selected,
              "bg-stealth-bomber": !isSale,
              "bg-white": isSale,
            })}
          />
        </div>
      </div>
      <div
        className={classNames("px-4.5 text-left py-4.5 w-full relative", {
          "text-black": !isSale,
          "text-white": isSale,
        })}
      >
        <p
          className={classNames("text-md font-semibold md:text-lg", {
            "mt-4 sm:mt-0": isSale,
          })}
        >
          {getPackName(pack)}
        </p>
        <p className="text-xxs font-bold">
          {`${pack.perCreditAmount}c per image. `}
          <span
            className={classNames("font-normal", {
              "text-white": isSale,
              "text-shadyLady": !isSale,
            })}
          >
            {getCostDescription(pack)}
          </span>
          {isSale && (
            <>
              <br className="block sm:hidden" />
              <span className="font-semi-bold text-jolteon">
                {pack.description}
              </span>
            </>
          )}
        </p>
        <input
          type="radio"
          value={pack.slug}
          name="pack"
          onChange={() => onSelectPack(pack)}
          hidden
        />
      </div>
      {pack.saving > 0 && (
        <div
          className={classNames(
            "py-2 px-3 whitespace-nowrap text-center uppercase text-xxxs font-bold tracking-wider leading-[100%] min-w-[90px] absolute top-0 right-0",
            {
              "bg-coupon-green": !isSale,
              "bg-jolteon": isSale,
              "text-white": !isSale,
              "text-stealth-bomber": isSale,
            }
          )}
        >{`Save ${numberToCurrency(pack.saving)}`}</div>
      )}
    </label>
  );
}

function getPackName(pack: CreditPurchasingPack) {
  if (pack.slug === "edit_payg") {
    return "Individual credits";
  }
  if (pack.slug === "edit_20k_sale") {
    return "20,000 launch sale pack 🎉";
  }
  return `${pack.credits.toLocaleString("en-US")} credit pack`;
}

function getCostDescription(pack: CreditPurchasingPack) {
  if (pack.slug === "edit_payg") {
    return "Charged at time of use.";
  }
  return `${numberToCurrency(pack.unitAmount)} USD Upfront. `;
}

export { getPackName };
export default EditPlanPackRadioItem;
