import { useAccountQuery, useUserQuery } from "@/api";
import { RemoveDetailsModal } from "@/components";
import {
  BillingAddressFields,
  BillingAddressForm,
  IBillingAddressFields,
} from "@/containers";
import { useModalDispatch } from "@/providers";
import {
  Button,
  ButtonColour,
  ButtonAppearance,
  Spinner,
  ModalActionType,
} from "@narrative-software/narrative-web-ui";

type Props = {
  buttonColour?: ButtonColour;
  buttonAppearance?: ButtonAppearance;
  showRemoveButton?: boolean;
  showUpdateMessage?: boolean;
  showUserFields?: boolean;
};

export const BillingAddress: React.FC<Props> = ({
  buttonColour = "black",
  buttonAppearance = "primary",
  showRemoveButton = false,
  showUpdateMessage = false,
  showUserFields = false,
}) => {
  const modalDispatch = useModalDispatch();
  const { data: accountData, isLoading: isLoadingAccount } = useAccountQuery();
  const { data: userData, isLoading: isLoadingUser } = useUserQuery();

  // Loading state
  if (!accountData || !userData || isLoadingAccount || isLoadingUser) {
    return <Spinner />;
  }

  const addressData = accountData.data.attributes.address;
  const ownerData = userData.data.attributes;

  const fieldValues: IBillingAddressFields = {
    firstName: ownerData?.["f-name"] || "",
    lastName: ownerData?.["l-name"] || "",
    line1: addressData?.line1 || "",
    line2: addressData?.line2 || "",
    city: addressData?.city || "",
    state: addressData?.state || "",
    "postal-code": addressData?.["postal-code"] || "",
    country: addressData?.country || "",
  };

  const isAddressData = !!fieldValues.line1;

  const openRemoveModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: <RemoveDetailsModal subject="address" />,
      },
    });
  };

  const openBillingModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <>
            <h3 className="text-h3 font-semibold mb-8 mt-6 sm:mt-0">
              {isAddressData ? "Update" : "Add"} billing address
            </h3>

            {showUpdateMessage && (
              <p className="-mt-2 mb-9">
                {isAddressData ? "Updating" : "Adding"} your details will apply
                to both products.
              </p>
            )}

            <BillingAddressForm
              closeModal={() => modalDispatch({ type: ModalActionType.Close })}
              buttonText={isAddressData ? "Update" : "Add"}
              showUserFields={showUserFields}
            />
          </>
        ),
      },
    });
  };

  return (
    <div className="flex flex-col">
      {isAddressData ? (
        <div className="mb-6 md:mb-10">
          <BillingAddressFields
            disabled
            values={fieldValues}
            showUserFields={showUserFields}
          />
        </div>
      ) : (
        <p className="text-sm -mt-2 mb-6">No billing address has been added.</p>
      )}

      <Button
        colour={buttonColour}
        appearance={buttonAppearance}
        onClick={openBillingModal}
      >
        {isAddressData ? "Update" : "Add"} billing address
      </Button>

      {showRemoveButton && isAddressData ? (
        <div className="mt-4.5 text-11 text-center">
          <button type="button" className="underline" onClick={openRemoveModal}>
            Remove billing address
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default BillingAddress;
