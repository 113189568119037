import { SelectPlanWithPricing } from "../SelectPlanComparison/types";
import { ICouponAttributes } from "@/api";
import { BillingIntervalToggle } from "@/components/SelectPlanComparison/BillingIntervalToggle/BillingIntervalToggle";
import { BILLING_INTERVALS_WITH_LABELS } from "@/components/SelectPlanComparison/BillingIntervalToggle/contants";
import { useBillingIntervalToggle } from "@/components/SelectPlanComparison/BillingIntervalToggle/useBillingIntervalToggle";
import { SelectPlanComparisonItem } from "@/components/SelectPlanComparison/SelectPlanComparisonItem";
import { SelectPlanComparisonLayout } from "@/components/SelectPlanComparison/SelectPlanComparisonLayout";
import { SelectPlanComparisonMobileNav } from "@/components/SelectPlanComparison/SelectPlanComparisonMobileNav";
import { Tooltip } from "@narrative-software/narrative-web-ui";
import { ProductInterval } from "@narrative-software/narrative-web-ui/dist/shared";

type SelectPlanSelectorProps = {
  plans: SelectPlanWithPricing[];
  onSelect: (args: { planID: string; interval: ProductInterval }) => void;
  mode: "upgrade" | "downgrade" | "normal";
  coupon?: ICouponAttributes;
};

const SelectPlanSelector = ({
  plans,
  mode,
  onSelect,
  coupon,
}: SelectPlanSelectorProps) => {
  const [billingInterval, setBillingInterval] = useBillingIntervalToggle();

  return (
    <div className="py-8" data-testid="plan-selector">
      <div className="pb-4">
        <h1 className="text-h2 font-semibold leading-tight text-center lg:text-h1 mb-6 lg:mb-9">
          Choose your <u>Select</u> plan
        </h1>
        <BillingIntervalToggle
          selected={billingInterval}
          eventHandler={setBillingInterval}
          intervals={BILLING_INTERVALS_WITH_LABELS}
        />
      </div>
      <SelectPlanComparisonLayout>
        {plans.map((plan, i) => (
          <SelectPlanComparisonItem
            {...plan}
            key={plan.id}
            position={i}
            billingInterval={billingInterval}
            onClick={onSelect}
            mode={mode}
            coupon={coupon}
          />
        ))}
      </SelectPlanComparisonLayout>
      <SelectPlanComparisonMobileNav
        plans={plans.map(({ planName: name, stripePlanSlug: slug }) => ({
          name,
          slug,
        }))}
      />
      <Tooltip />
    </div>
  );
};

export { SelectPlanSelector };
