import {
  usePurchaseEditCreditsMutation,
  useEditCreditBalanceQuery,
} from "../../../api/hooks";
import { PAYG_PACK_SLUG } from "../../../api/interfaces/editCreditPacks";
import EditPlanPackRadioItem from "./EditPlanPackRadioItem";
import { getPackName } from "./EditPlanPackRadioItem";
import { CreditPurchasingPack } from "@/api/interfaces/editCreditPacks";
import { useAlertDispatch, useModalDispatch } from "@/providers";
import { Input } from "@material-ui/core";
import {
  Button,
  ModalActionType,
  AlertActionType,
} from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import { Formik } from "formik";
import React from "react";
import { FormattedNumber } from "react-intl";
import * as Yup from "yup";

type CreditsPurchaseModalProps = {
  creditPacks: CreditPurchasingPack[];
  preferredPack?: CreditPurchasingPack;
};

const CreditsPurchaseModal = ({
  creditPacks,
  preferredPack,
}: CreditsPurchaseModalProps) => {
  const paygPack = creditPacks.find((pack) => pack.slug === PAYG_PACK_SLUG)!;
  const defaultPackId = preferredPack?.id || paygPack.id;
  const [individualCreditsQuantity, setIndividualCreditsQuantity] =
    React.useState<Maybe<number>>(undefined);
  const { mutateAsync: purchaseCredits } = usePurchaseEditCreditsMutation();
  const modalDispatch = useModalDispatch();
  const { data: { balance: editCreditsBalance } = {}, refetch } =
    useEditCreditBalanceQuery();
  const alertDispatch = useAlertDispatch();

  return (
    <Formik
      initialValues={{
        selectedPackId: defaultPackId,
        quantity: defaultPackId === paygPack.id ? undefined : 1,
      }}
      validationSchema={Yup.object().shape({
        selectedPackId: Yup.string().required(),
        quantity: Yup.number()
          .required("")
          .when("selectedPackId", {
            is: (id: string) => id === paygPack.id,
            then: Yup.number().min(5, "Please choose a minimum of 5 credits"),
          }),
      })}
      onSubmit={async (values) => {
        const preBalance = editCreditsBalance;

        try {
          const result = await purchaseCredits({
            packId: values.selectedPackId,
            quantity: Number(values.quantity!),
          });

          if (result.clientSecret) {
            const pack = creditPacks.find(
              (pack) => pack.id === values.selectedPackId
            )!;

            const checkoutURL = new URL(`${location.origin}/credits-checkout`);
            checkoutURL.search = new URLSearchParams({
              secret: result.clientSecret,
              packName: getPackName(pack),
              unitAmount: String(pack.unitAmount / 100),
              unitCount: String(values.quantity),
              accountPortal: String(true),
            }).toString();
            const link = checkoutURL.toString();

            location.href = link;
            return;
          }
        } catch {
          alertDispatch({
            type: AlertActionType.SetContent,
            payload: {
              type: "error",
              title: "An error occurred while processing your payment.",
            },
          });
          return;
        }

        try {
          while (true) {
            const { data } = await refetch();

            if (!data || data.balance !== preBalance) {
              break;
            }
          }
        } finally {
          alertDispatch({
            type: AlertActionType.SetContent,
            payload: {
              type: "success",
              title: "Credits successfully added to your account.",
            },
          });

          modalDispatch({ type: ModalActionType.Close });
        }
      }}
    >
      {({
        values,
        setValues,
        errors,
        handleSubmit,
        touched,
        validateForm,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
      }) => {
        const selectedPack = creditPacks.find(
          (pack) => pack.id === values.selectedPackId
        )!;

        return (
          <div className="px-8 py-8 lg:px-15 lg:pt-14 lg:pb-16 h-full]">
            <div className="flex h-full flex-col lg:flex-row lg:gap-[60px]">
              <div className="mt-5 w-[90vw] sm:w-[400px]">
                <h2 className="text-h3">Credit Pack</h2>
                <div className="grid grid-cols-1 gap-3 mt-5">
                  {creditPacks.map((pack) => {
                    return (
                      <EditPlanPackRadioItem
                        key={pack.id}
                        pack={pack}
                        selected={pack.id === values.selectedPackId}
                        onSelectPack={(pack) => {
                          if (
                            values.selectedPackId === paygPack.id &&
                            pack.id !== paygPack.id
                          ) {
                            setIndividualCreditsQuantity(values.quantity);
                          }
                          setValues({
                            ...values,
                            selectedPackId: pack.id,
                            quantity:
                              pack.slug === PAYG_PACK_SLUG
                                ? individualCreditsQuantity
                                : 1,
                          });
                        }}
                      />
                    );
                  })}
                  <div className="mt-4"></div>
                </div>
              </div>
              <div className="flex flex-col justify-center h-full w-[90vw] sm:w-[400px]">
                <div>
                  <h2 className="text-h3 mt-5">Summary</h2>
                  {values.selectedPackId === paygPack.id ? (
                    <div className="mt-10">
                      <div>
                        <div className="text-right mr-9 mb-3 text-gumboot text-xxxs">
                          QTY
                        </div>
                        <div className="flex justify-between items-center">
                          <dt className="text-lg font-bold mt-2">
                            Individual credits
                          </dt>
                          <dd>
                            <label>
                              <input
                                type="text"
                                name="quantity"
                                onChange={(e) => {
                                  if (!e.target.value) {
                                    return handleChange(e);
                                  }
                                  const value = Number(e.target.value);
                                  if (
                                    value > 0 &&
                                    Number.isInteger(value) &&
                                    value <= 99999
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                value={values.quantity}
                                onBlur={handleBlur}
                                className="border w-[100px] px-4 py-3 text-center"
                                placeholder="1000"
                              />
                            </label>
                          </dd>
                        </div>
                        <div className="text-right mt-2 text-xxs text-error">
                          {errors.quantity && dirty && touched.quantity && (
                            <div>{errors.quantity}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-14">
                      <div className="flex justify-between">
                        <dt className="text-lg font-bold">
                          <div className="text-center mb-3 text-gumboot text-xxxs">
                            &nbsp;
                          </div>
                          <FormattedNumber value={selectedPack.credits} />{" "}
                          credit pack
                        </dt>
                        <dd>
                          <div className="text-center mb-3 text-gumboot text-xxxs">
                            QTY
                          </div>
                          <div className="flex text-lg font-semibold space-x-5">
                            <button
                              onClick={() => {
                                setValues({
                                  ...values,
                                  quantity: Math.max(
                                    1,
                                    (values.quantity || 0) - 1
                                  ),
                                });
                              }}
                            >
                              -
                            </button>
                            <span className="w-3">{values.quantity}</span>
                            <button
                              onClick={() => {
                                setValues({
                                  ...values,
                                  quantity: (values.quantity || 0) + 1,
                                });
                              }}
                            >
                              +
                            </button>
                          </div>
                        </dd>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-1 flex flex-col justify-end mb-12">
                  <div
                    className={classNames("mb-9", {
                      invisible: !values.quantity || errors.quantity,
                    })}
                  >
                    You will be charged{" "}
                    <FormattedNumber
                      style="currency"
                      currency="USD"
                      trailingZeroDisplay="stripIfInteger"
                      value={
                        ((values.quantity || 0) * selectedPack.unitAmount) / 100
                      }
                    />{" "}
                    USD today.
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        validateForm();
                        handleSubmit();
                      }}
                      isLoading={isSubmitting}
                      showLoader
                      colour="black"
                    >
                      Purchase
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export { CreditsPurchaseModal };
