import { ProductName } from "@/api";
import { FixedAspectRatioContainer } from "@/components";
import { getHexValueByClassName } from "@/utils";
import Image, { StaticImageData } from "next/image";
import React from "react";

type IconColour = "gengar" | "slowpoke" | "ekans";

const Icon: React.FC<{ colour: IconColour }> = ({ colour }) => {
  const hexValue = getHexValueByClassName(colour);
  if (!hexValue) return null;
  return (
    <svg width="55" height="55" viewBox="0 0 55 55">
      <circle cx="27.5" cy="27.5" r="27.5" fill={`#${hexValue}`} />
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9238 20.0964L16.9238 39.1349L20.0969 39.1349L20.0969 20.0964L29.6153 20.0964L29.6153 32.789L32.7884 32.789L32.7884 20.0964H32.7892L32.7892 16.9233L20.0969 16.9233L16.9238 16.9233L16.9238 20.0964ZM39.1335 16.9236L35.9604 16.9236L35.9604 35.9612L26.4426 35.9612L26.4426 23.2706L23.2695 23.2706L23.2695 35.9612L23.2695 39.1342V39.136H26.4426V39.1342L35.9604 39.1342V39.1351L39.1335 39.1351V39.1342H39.1349L39.1349 35.9612H39.1335L39.1335 16.9236Z"
      />
    </svg>
  );
};

type Props = {
  name: ProductName;
  colour: IconColour;
  imageSrc: StaticImageData;
  buttonPrimary: React.ReactNode;
  learnUrl: string;
  className?: string;
};

const ProductCard: React.FC<Props> = ({
  name,
  colour,
  imageSrc,
  children,
  buttonPrimary,
  learnUrl,
  className,
}) => (
  <article className="flex flex-col items-center bg-white text-center">
    <FixedAspectRatioContainer aspectRatio={384 / 219} className="relative">
      <Image
        src={imageSrc}
        alt={name}
        layout="fill"
        placeholder="blur"
        sizes="800px"
        quality={100}
      />
    </FixedAspectRatioContainer>
    <div className="flex flex-col items-center w-full mb-5 text-38 leading-none">
      <div className="relative -mt-8 mb-3.5">
        <Icon {...{ colour }} />
      </div>
      <span className="mb-1 font-semibold">Narrative</span>
      <span className="font-extralight">{name}</span>
    </div>
    <div className="px-9% pb-8 h-full flex flex-col items-between">
      {children && <p>{children}</p>}

      <div className="w-full pt-6 mt-auto">
        {buttonPrimary}
        <div className="w-full mt-6">
          <a
            href={learnUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xxs text-gray-700 underline"
          >
            Learn more about {name}
          </a>
        </div>
      </div>
    </div>
  </article>
);

export default ProductCard;
