import { useIntercom } from "@/hooks/intercom";
import { useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";
import React from "react";

const AssociateGoogleAccountErrorModal = ({ email }: { email: string }) => {
  const modalDispatch = useModalDispatch();
  const showIntercom = useIntercom();
  return (
    <div>
      <h3 className="text-h3 font-semibold mb-6">
        Oops, that didn&apos;t work.
      </h3>
      <p className="mb-4">
        You might have an existing Narrative account associated with the
        selected Google account
        {email ? ` (${email})` : ""}.
      </p>
      <p>
        Please reach out to{" "}
        <button className="underline" onClick={showIntercom}>
          our friendly support team
        </button>{" "}
        if you wish to resolve this.
      </p>
      <div className="mt-8">
        <Button
          colour="black"
          className=""
          onClick={() => {
            modalDispatch({ type: ModalActionType.Close });
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export { AssociateGoogleAccountErrorModal };
