import spinner from "../../..//public/images/spinner.png";
import classNames from "classnames";

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
};

type Props = {
  size?: number;
  progress?: number;
  className?: string;
};

/**
 * Fancy Spinner
 * https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
 */
const FancySpinner: React.FC<Props> = ({ progress, className, size = 50 }) => (
  <div
    className={classNames("relative", className)}
    title="Loading..."
    style={{
      width: `${size}px`,
      height: `${size}px`,
      backgroundImage: `url(${spinner})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    }}
  >
    {progress !== undefined && (
      <p className="absolute top-1/2 transform -translate-y-1/2 w-full font-semibold text-center text-xs text-stealth-bomber">
        {progress}%
      </p>
    )}
    <svg
      width={size}
      height={size}
      viewBox="0 0 75 75"
      fill="none"
      className="absolute top-0 animate-rotate-clockwise"
      style={{
        transformOrigin: "50% 50%",
      }}
    >
      <path
        d={describeArc(37.5, 37.5, 22.5, 0, 90)}
        fill="none"
        stroke="currentColor"
        strokeWidth="12"
      />
    </svg>
    <svg
      width={size}
      height={size}
      viewBox="0 0 75 75"
      fill="none"
      className="absolute top-0 animate-rotate-anticlockwise"
      style={{
        transformOrigin: "50% 50%",
      }}
    >
      <path
        d={describeArc(37.5, 37.5, 37.5, 180, 270)}
        fill="none"
        stroke="currentColor"
        strokeWidth="12"
      />
    </svg>
  </div>
);

export default FancySpinner;
