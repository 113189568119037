import { ImageType } from "@/types";
import classNames from "classnames";
import { Image as DatoCmsImage } from "react-datocms";

type Props = {
  title: string;
  url?: string;
  image?: ImageType;
  className?: string;
};

const ResourceCard: React.FC<Props> = ({ title, url, image, className }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={classNames("block text-center", className)}
  >
    <div className="relative pb-2/3 border border-black bg-cinder-block overflow-hidden">
      {image && (
        <DatoCmsImage
          data={{ alt: title, ...image.responsiveImage }}
          layout="fill"
          lazyLoad
          className="transform transition-transform duration-300 scale-102 hover:scale-105"
          style={{ willChange: "transform" }}
        />
      )}
    </div>
    <div className="p-5">
      <h6 className="text-16 leading-tight">{title}</h6>
    </div>
  </a>
);

export default ResourceCard;
