import { Transition, TransitionEvents } from "@headlessui/react";

type Props = {
  title: React.ReactNode;
  isVisible: boolean;
  subheading?: React.ReactNode;
  form?: React.ReactNode;
  onAfterLeave?: TransitionEvents["afterLeave"];
};

const StepWrapper: React.FC<Props> = ({
  title,
  isVisible,
  subheading,
  form,
  onAfterLeave,
  children,
}) => (
  <Transition
    appear
    show={isVisible}
    className="relative flex flex-col items-center transform transition ease-out"
    enter="duration-500"
    enterFrom="opacity-0 -translate-y-5"
    enterTo="opacity-100 translate-y-0"
    leave="duration-500"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 -translate-y-5"
    afterLeave={onAfterLeave}
  >
    <div className="mb-10 text-center">
      <h1 className="text-h2 font-semibold leading-tight md:text-h1">
        {title}
      </h1>
      {subheading && <p className="mt-5">{subheading}</p>}
    </div>
    {form && (
      <div className="w-full max-w-125 p-6 sm:p-12 md:p-15 bg-off-white">
        {form}
      </div>
    )}
    {children}
  </Transition>
);

export default StepWrapper;
