import { ROUTES, SELECT_DEEP_LINK_URL } from "@/config";
import { getSessionID } from "@/events";
import { AuthHelper } from "@/helpers";
import { useQueryParams } from "@/hooks";
import { useAuthContext } from "@/providers";
import { isBrowser } from "@/utils";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { useState, useEffect } from "react";
import { UrlObject } from "url";

/**
 * Authenticate and redirect
 *
 * If authenticated just redirect to pathname, otherwise add pathname to backPath query param,
 * authenticate and withAuthRedirect will handle the redirect.
 */
export const useAuthRedirect = () => {
  const router = useRouter();
  const { isAuthenticated, setAuthenticated } = useAuthContext();
  return async (pathname: string, query: ParsedUrlQueryInput = {}) => {
    if (isAuthenticated) {
      await router.push({ pathname, query });
    } else {
      await router.replace(
        {
          pathname: router.pathname,
          query: { ...query, backPath: pathname },
        },
        undefined,
        { shallow: true }
      );
      setAuthenticated(true);
    }
  };
};

/**
 * Unauthenticate and redirect
 *
 * If unauthenticated just redirect to pathname, otherwise add pathname to backPath query param,
 * unauthenticate and withAuthRedirect will handle the redirect.
 */
export const useNonAuthRedirect = () => {
  const router = useRouter();
  const { isAuthenticated, setAuthenticated } = useAuthContext();
  return async (pathname: string, query: ParsedUrlQueryInput = {}) => {
    if (!isAuthenticated) {
      await router.push({ pathname, query });
    } else {
      await router.replace(
        {
          pathname: router.pathname,
          query: { ...query, backPath: pathname },
        },
        undefined,
        { shallow: true }
      );
      setAuthenticated(false);
    }
  };
};

/**
 * Redirect the user based on product flow
 */
export const useRedirect = () => {
  const router = useRouter();
  const doAuthRedirect = useAuthRedirect();
  return async ({
    type,
    url,
    query,
  }: {
    type?: string;
    url?: UrlObject | string;
    query?: ParsedUrlQueryInput;
  } = {}) => {
    switch (type) {
      case "app":
        if (!isBrowser()) return;
        const sessionId = getSessionID();
        window.location.href =
          `${SELECT_DEEP_LINK_URL}://restart` +
          (sessionId ? `?sessionId=${sessionId}` : "");
        if (url) await router.push(url);
        return;
      case "select":
        await doAuthRedirect(`/${ROUTES.SELECT.DOWNLOAD.SLUG}`, query);
        return;
      case "publish":
        await doAuthRedirect(`/${ROUTES.PUBLISH.DOWNLOAD.SLUG}`, query);
        return;
      default:
        if (url) await router.push(url);
    }
  };
};

export const useAuthViaToken = () => {
  const query = useQueryParams();
  const router = useRouter();
  const { pathname } = router;
  const { isAuthenticated, setAuthenticated } = useAuthContext();
  const { select_token: selectToken } = query;

  const [checkedForSelectToken, setCheckedForSelectToken] = useState(false);

  useEffect(() => {
    if (selectToken && !isAuthenticated) {
      AuthHelper.setToken(selectToken);
      setAuthenticated(true);
      delete query.select_token;
      router.replace({ pathname, query }, undefined, { shallow: true });
    }
    setCheckedForSelectToken(true);
  }, [selectToken]);

  return checkedForSelectToken;
};
