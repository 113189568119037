import { IGoogleOAuthResponse } from "@/api";
import { ROUTES } from "@/config";
import { useGoogleLogin } from "@/hooks";
import { useModalDispatch } from "@/providers";
import {
  Button,
  AnchorButton,
  ModalActionType,
} from "@narrative-software/narrative-web-ui";
import Link from "next/link";

type GoogleLoginErrorModalProps = {
  onSuccess: (data: IGoogleOAuthResponse) => void;
};

const GoogleLoginErrorModal = ({ onSuccess }: GoogleLoginErrorModalProps) => {
  const modalDispatch = useModalDispatch();
  const { openWindow } = useGoogleLogin(onSuccess);
  return (
    <div>
      <h3 className="text-h3 font-semibold mb-6">
        We don’t recognise that Google account
      </h3>
      <p className="mb-4">
        If you have a Narrative account, try signing in with a different Google
        account, or sign in with your email address. You can connect a Google
        account to your Narrative account after you’ve signed in to Narrative.
      </p>
      <p>If you’re new to Narrative, sign up with your Google account below.</p>
      <div className="mt-8">
        <Button colour="black" className="mb-4" onClick={openWindow}>
          Sign in with a different google account
        </Button>
        <Link href={`/${ROUTES.GET_STARTED.BASE.SLUG}`} passHref>
          <AnchorButton
            colour="gray-600"
            appearance="secondary"
            onClick={() => modalDispatch({ type: ModalActionType.Close })}
          >
            Sign up to Narrative
          </AnchorButton>
        </Link>
      </div>
    </div>
  );
};

export default GoogleLoginErrorModal;
