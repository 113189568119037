import { getSingleQueryParams } from "@/utils";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";

/**
 * Get query params
 */
export const useQueryParams = <
  T extends Record<string, any> = Record<string, any>
>() => {
  const { query } = useRouter();
  return getSingleQueryParams(query) as T;
};

/**
 * Check if a component has mounted
 */
export const useMounted = () => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return isMounted;
};

/**
 * Hook that forces a scroll reset to a particular set of coordinates in the document
 * after `next/router` finishes transitioning to a new page client side.
 */
export const useRouterScroll = ({
  top = 0,
  left = 0,
  behavior = "auto",
}: {
  top?: number;
  left?: number;
  behavior?: ScrollBehavior;
} = {}) => {
  const router = useRouter();

  useEffect(() => {
    // Scroll to given coordinates when router finishes navigating
    // This fixes an inconsistent behaviour between `<Link />` and `next/router`
    const handleRouteChangeComplete = () => {
      window.scrollTo({ top, left, behavior });
    };

    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe from the event
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router, top, left, behavior]);
};

export const useWaitForExternalResource = (url: string, onLoad: () => void) => {
  const onResourceLoad = (event: Event) => {
    if ((event.target as HTMLElement).nodeName === "SCRIPT") {
      const scriptSrc = (event.target as HTMLScriptElement).getAttribute("src");
      if (scriptSrc?.includes(url)) {
        onLoad();
      }
    }
  };
  useEffect(() => {
    document.head.addEventListener("load", onResourceLoad, true);
    return () => {
      document.head.removeEventListener("load", onResourceLoad, true);
    };
  }, []);
};
