import classNames from "classnames";

type Props = {
  aspectRatio: number;
  className?: string;
};

const FixedAspectRatioContainer: React.FC<Props> = ({
  aspectRatio,
  className,
  children,
}) => (
  <div
    className={classNames("relative w-full h-0", className)}
    style={{ paddingBottom: `${100 / aspectRatio}%` }}
  >
    <div className="absolute inset-0">{children}</div>
  </div>
);

export default FixedAspectRatioContainer;
