import { useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";

const SuccessModal: React.FC = () => {
  const modalDispatch = useModalDispatch();

  const handleClose = () => {
    modalDispatch({ type: ModalActionType.Close });
  };

  return (
    <>
      <h3 className="mb-6 text-h3 font-semibold leading-tight">
        Your plan has been updated
      </h3>
      <p className="mb-8">
        Thanks for staying with us. You are now on the parking plan.
      </p>
      <div className="w-full max-w-55 ml-auto">
        <Button colour="black" onClick={handleClose}>
          Okay
        </Button>
      </div>
    </>
  );
};

export default SuccessModal;
