import { MODULE_KEYS } from "./config";
import { formatImageData } from "@/config";
import { ImageType } from "@/types";
import { ThreeUp as UIThreeUp } from "@narrative-software/narrative-web-ui";
import { Image } from "react-datocms";

export interface ThreeUpFragmentType {
  _modelApiKey: typeof MODULE_KEYS.threeUp;
  id: string;
  items: ThreeUpItemFragmentType[];
  heading?: string;
  subheading?: string;
  textAlign?: "left" | "center" | "right";
  textSize?: "base" | "large" | "extra large";
  layout?: "tight" | "loose";
  columns?: 2 | 3;
  hideLine?: boolean;
  disableImageHeight?: boolean;
  isSection?: boolean;
}

export interface ThreeUpItemFragmentType {
  id: string;
  title: string;
  description: string;
  image?: ImageType;
}

// Fragment
export const threeUpFragment = `
  fragment threeUpFragment on ModuleThreeUpRecord {
    _modelApiKey
    id
    heading
    subheading
    textAlign
    textSize
    layout
    columns
    hideLine
    disableImageHeight
    isSection
    items {
      id
      title
      description
      image {
        url
        alt
        width
        height
        responsiveImage(imgixParams: { auto: format }) {
          ...responsiveImageFragment
        }
      }
    }
  }
`;

// Constructor
export const ThreeUp: React.FC<ThreeUpFragmentType> = ({
  id,
  heading,
  subheading,
  textAlign,
  items,
  isSection,
  ...rest
}) => (
  <UIThreeUp
    key={id}
    title={heading}
    subTitle={subheading}
    titleAlign={textAlign}
    {...rest}
  >
    {items.map(({ id: itemId, title, description, image }) => {
      const imageData =
        image && formatImageData({ image, removeBase64: false });
      return (
        <UIThreeUp.Item
          key={itemId}
          title={title}
          imageHeight={imageData?.height || undefined}
          // eslint-disable-next-line jsx-a11y/alt-text
          image={imageData && <Image data={imageData} />}
        >
          {description}
        </UIThreeUp.Item>
      );
    })}
  </UIThreeUp>
);
