import { useCouponMutation, ProductInterval } from "@/api";
import { CouponHelper } from "@/helpers";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  InputProps,
  FormHelperText,
} from "@material-ui/core";
import { Spinner } from "@narrative-software/narrative-web-ui";
import { useEffect, ChangeEvent } from "react";

const id = "coupon-field";

type Props = InputProps & {
  value: string;
  planID: string;
  interval: ProductInterval;
  setValue: (value: string) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  onSuccess?: (coupon: string) => Promise<void>;
  resetCharge?: () => void;
};

export const CouponField: React.FC<Props> = ({
  value,
  planID,
  interval,
  setValue,
  isSuccess,
  onChange,
  onSuccess,
  resetCharge,
  isLoading: isLoadingCharge,
  ...rest
}) => {
  const {
    mutateAsync: getCoupon,
    isLoading: isLoadingCoupon,
    error,
    reset,
  } = useCouponMutation();

  const isLoading = isLoadingCharge || isLoadingCoupon;
  const isError = !!error;

  // Handle change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    reset();
    resetCharge?.();
    onChange?.(event);
  };

  // Handle submit
  const handleSubmit = async () => {
    reset();
    resetCharge?.();

    if (isError) {
      setValue("");
      CouponHelper.deleteCookie();
      await onSuccess?.("");
    } else if (value) {
      try {
        const response = await getCoupon({ planID, interval, coupon: value });
        if (response?.data.attributes.valid) {
          await onSuccess?.(value);
          CouponHelper.setCookie({ code: value, scheme: null });
        }
      } catch {}
    }
  };

  // Get coupon on mount
  useEffect(() => {
    if (planID && interval && value) {
      handleSubmit();
    }
  }, [planID, interval]);

  return (
    <>
      <FormControl error={isError && !isLoading} fullWidth>
        <InputLabel htmlFor={id}>Referral Coupon (optional)</InputLabel>
        <Input
          {...{ id, value, ...rest }}
          type="text"
          fullWidth
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <button
                type="button"
                className="text-11 font-semibold uppercase tracking-wider text-gray-600 hover:text-stealth-bomber focus:outline-none"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Spinner size={4} />
                ) : isError ? (
                  "Remove"
                ) : (
                  "Apply"
                )}
              </button>
            </InputAdornment>
          }
        />
        {isError && !isLoading && (
          <FormHelperText id={`${id}-helper-text`}>
            {error?.response?.status === 404
              ? "Coupon not found"
              : error?.message}
          </FormHelperText>
        )}
      </FormControl>

      {isSuccess && !isLoading ? (
        <div className="mt-5">
          <div className="flex items-center justify-between h-10 px-3.5 bg-coupon-green">
            <span className="text-xxs text-white">
              Coupon successfully applied
            </span>
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
              <path
                fill="white"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11ZM7.78588 3.62858L8.49299 4.33568L5.66502 7.16365L5.66548 7.16411L4.95837 7.87122L4.95791 7.87076L4.95745 7.87122L4.25035 7.16411L4.2508 7.16365L2.83705 5.7499L3.54416 5.04279L4.95791 6.45655L7.78588 3.62858Z"
              />
            </svg>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CouponField;
