import DeleteModal from "./components/DeleteModal";
import DeniedModal from "./components/DeniedModal";
import {
  useProductsQuery,
  useDeleteAccountMutation,
  ProductName,
  ProductStatus,
} from "@/api";
import {
  useAccountContext,
  useModalDispatch,
  useAuthContext,
} from "@/providers";
import { ModalActionType } from "@narrative-software/narrative-web-ui";

export const DeleteAccount: React.FC = () => {
  const { accountID } = useAccountContext();
  const { setAuthenticated } = useAuthContext();
  const { data: productsData, isLoading: isLoadingProducts } =
    useProductsQuery();
  const { mutateAsync: doDeleteAccount } = useDeleteAccountMutation();

  // Modal handlers
  const modalDispatch = useModalDispatch();
  const handleModalClose = () => modalDispatch({ type: ModalActionType.Close });

  // Handle delete click
  const handleDeleteClick = async () => {
    try {
      await doDeleteAccount(accountID);
      handleModalClose();
      setAuthenticated(false);
    } catch (e: any) {
      console.error(`Account deletion error: ${e.message}`);
    }
  };

  // If the account is NOT subscribed to Select or Publish, deletion is allowed
  const existingProduct = productsData?.data.find(
    ({ attributes }) =>
      attributes.name !== ProductName.Save &&
      attributes.status !== ProductStatus.Canceled
  );

  const handleOpenModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: existingProduct ? (
          <DeniedModal
            onClick={handleModalClose}
            productName={existingProduct.attributes.name}
          />
        ) : (
          <DeleteModal onSubmit={handleDeleteClick} />
        ),
      },
    });
  };

  return (
    <button
      type="button"
      className="text-xxxs text-gray-500 hover:text-white focus:text-white tracking-wide focus:outline-none"
      disabled={isLoadingProducts}
      onClick={handleOpenModal}
    >
      Delete account
    </button>
  );
};

export default DeleteAccount;
