import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps,
} from "@material-ui/core";
import { useState } from "react";

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: "left" as const,
  },
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

type Props = SelectProps &
  Pick<TextFieldProps, "helperText"> & {
    options: string[];
    testId: string;
    onChange: (values: string[]) => void;
  };

const MultipleSelectField: React.FC<Props> = ({
  id,
  label,
  options,
  testId,
  onChange,
  helperText,
  error,
  ...rest
}) => {
  const [values, setValues] = useState<string[]>([]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const {
      target: { value },
    } = event;
    const updatedValues =
      typeof value === "string" ? value.split(",") : (value as string[]);
    setValues(updatedValues);
    onChange(updatedValues);
  };

  return (
    <FormControl fullWidth error={error} data-testid={`${testId}-form-control`}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        {...rest}
        value={values}
        renderValue={(selected: any) => selected.join(", ")}
        input={<Input className="text-8" />}
        MenuProps={MenuProps}
        inputProps={{ id, "data-testid": testId }}
        onChange={handleChange}
        multiple
        fullWidth
      >
        {options.map((name) => (
          <MenuItem key={name} value={name} className="h-8">
            <Checkbox color="primary" checked={values.indexOf(name) > -1} />
            <ListItemText
              primary={name}
              className="text-8"
              data-testid={`${testId}-menu-item-${name}`}
            />
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultipleSelectField;
