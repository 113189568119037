import { MODULE_KEYS } from "./config";
import { Accordion as UIAccordion } from "@narrative-software/narrative-web-ui";

export interface AccordionFragmentType {
  _modelApiKey: typeof MODULE_KEYS.accordion;
  id: string;
  accordionId: string;
  items: AccordionItemFragmentType[];
  heading?: string;
  headingSize?: "h1" | "h2" | "h3";
  hasLine?: boolean;
  allowMultipleExpanded?: boolean;
  isSection?: boolean;
}

export interface AccordionItemFragmentType {
  id: string;
  heading: string;
  content: string;
  isDefaultExpanded?: boolean;
}

// Fragment
export const accordionFragment = `
  fragment accordionFragment on ModuleAccordionRecord {
    _modelApiKey
    id
    heading
    headingSize
    accordionId
    hasLine
    isSection
    allowMultipleExpanded
    items {
      id
      heading
      content
      isDefaultExpanded
    }
  }
`;

// Constructor
export const Accordion: React.FC<AccordionFragmentType> = ({
  items,
  accordionId,
  heading = "",
  headingSize = "h3",
  hasLine = false,
  allowMultipleExpanded = false,
}) => (
  <UIAccordion
    id={accordionId}
    title={heading}
    titleSize={headingSize}
    {...{ hasLine, allowMultipleExpanded }}
  >
    {items.map(({ id, heading, content, isDefaultExpanded }) => (
      <UIAccordion.Item
        key={id}
        id={id}
        heading={heading}
        isExpanded={isDefaultExpanded}
      >
        {content}
      </UIAccordion.Item>
    ))}
  </UIAccordion>
);
