import ArrowRightIcon from "@/svg/arrow-right.svg";
import classNames from "classnames";
import Image, { ImageProps } from "next/image";

type Props = {
  title: React.ReactNode;
  icon: ImageProps["src"];
  button?: React.ReactNode;
  iconBackgroundClassName?: string;
};

const Step: React.FC<Props> = ({
  title,
  icon,
  button,
  iconBackgroundClassName,
  children,
}) => (
  <article className="flex flex-col items-center mb-8 text-center sm:w-55 sm:px-2.5 sm:m-0">
    <div
      className={classNames(
        "flex items-center justify-center w-28 h-28 mb-5 rounded-full",
        iconBackgroundClassName,
        {
          "bg-ball-bearing": !iconBackgroundClassName,
        }
      )}
    >
      <Image src={icon} alt="" unoptimized priority />
    </div>
    <h5 className="text-14 font-semibold leading-tight mb-2.5">{title}</h5>
    <p className="text-12">{children}</p>
    {button}
  </article>
);

const ThreeStep: React.FC<{ className?: string }> = ({
  className,
  children,
}) => (
  <article
    className={classNames(
      "relative flex flex-col items-center sm:flex-row sm:items-start sm:justify-around",
      className
    )}
  >
    {children}
    <div className="hidden absolute top-0 left-1/3 mt-8 transform -translate-x-1/2 sm:block">
      <Image src={ArrowRightIcon} alt="" unoptimized priority />
    </div>
    <div className="hidden absolute top-0 left-2/3 mt-8 transform -translate-x-1/2 sm:block">
      <Image src={ArrowRightIcon} alt="" unoptimized priority />
    </div>
  </article>
);

export default Object.assign(ThreeStep, { Step });
