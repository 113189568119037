export enum ErrorCode {
  InternalError = "ns.500",
  Unauthorized = "cg.common.102",
  UserExisting = "bb.user.101",
  CouponInvalid = "bb.coupon.100",
}

export interface IErrorObject {
  code: ErrorCode;
  detail: string;
  id: string;
  meta: Record<string, unknown>;
  source: {
    pointer: string;
  };
  status: number;
  title: string;
}

export interface IErrorResponse {
  data: {
    errors: IErrorObject[];
  };
}
