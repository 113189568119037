import { useLoginMutation } from "@/api";
import { GoogleButton, Or, PasswordField } from "@/components";
import { IS_GOOGLE_AUTH_ENABLED, ROUTES } from "@/config";
import { TOPICS, publishEvent } from "@/events";
import { AuthHelper } from "@/helpers";
import { getPublishInstallIdFromSession } from "@/pages/publish/auth";
import { getSelectInstallIdFromSession } from "@/pages/select/auth";
import TextField from "@material-ui/core/TextField";
import { Button, ButtonColour } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import { Formik, Form, FormikHelpers } from "formik";
import Link from "next/link";
import * as Yup from "yup";

// Types
export interface ILoginFields {
  email: string;
  password: string;
}

// Validation
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required"),
  password: Yup.string().required("Password required"),
});

type Props = {
  onSuccess(
    loginFields?: ILoginFields,
    userId?: string
  ): Promise<boolean | void>;
  email?: string;
  colour?: ButtonColour;
  buttonLabel?: string;
  hideEmail?: boolean;
  onGoogleClick?: () => void;
};

const LoginForm: React.FC<Props> = ({
  onSuccess,
  hideEmail,
  onGoogleClick,
  children,
  email = "",
  colour = "black",
  buttonLabel = "Sign in",
}) => {
  const { mutateAsync: doLogin, isLoading } = useLoginMutation();

  const handleSubmit = async (
    values: ILoginFields,
    actions: FormikHelpers<ILoginFields>
  ) => {
    try {
      const { access_token } = await doLogin(values);
      const userId = AuthHelper.getUserIdFromJwt(access_token);

      // Sign in succeeded event
      publishEvent(TOPICS.signInSucceeded, {
        user_id: userId,
        select_install_id: getSelectInstallIdFromSession() || undefined,
        publish_install_id: getPublishInstallIdFromSession() || undefined,
      });

      await onSuccess(values, userId);
    } catch (e: any) {
      const status = e.response?.status;
      const reason = status ? `status_code_${status}` : e.message;
      // Sign in failed event
      publishEvent(TOPICS.signInFailed, { reason });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ email, password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          dirty,
        }) => (
          <Form noValidate>
            <div className={classNames("mb-5", { hidden: hideEmail })}>
              <TextField
                name="email"
                id="login-email-input"
                label="Email"
                type="email"
                value={values.email}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ "data-testid": "login-email-input" }}
                fullWidth
              />
            </div>

            <div className="mb-1">
              <PasswordField
                name="password"
                id="login-password-input"
                label="Password"
                type="password"
                value={values.password}
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                onChange={handleChange}
                showVisibilityToggle
                onBlur={handleBlur}
                inputProps={{ "data-testid": "login-password-input" }}
                fullWidth
              />
            </div>

            <div className="mb-7 text-right">
              <Link href={`/${ROUTES.FORGOT_PASSWORD.SLUG}`}>
                <a
                  className="text-xxs text-gray-700 underline hover:text-black transition duration-200"
                  data-testid="login-forgot-password-link"
                >
                  Forgot password?
                </a>
              </Link>
            </div>

            <div>
              <Button
                type="submit"
                colour={colour}
                disabled={!dirty}
                isLoading={isSubmitting || isLoading}
                showLoader
                testId="login-submit-button"
              >
                {buttonLabel}
              </Button>

              {IS_GOOGLE_AUTH_ENABLED && onGoogleClick && (
                <>
                  <Or />
                  <GoogleButton onClick={onGoogleClick}>
                    Continue with Google
                  </GoogleButton>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {children}
    </>
  );
};

export default LoginForm;
