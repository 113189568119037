import classNames from "classnames";

type Props = {
  items: Record<string, string>;
  active: string;
  onClick: (key: any) => void | Promise<void>;
};

const ToggleNav: React.FC<Props> = ({ items, active, onClick }) => (
  <ul className="flex justify-center">
    {Object.entries(items).map(([key, value]) => (
      <li key={key}>
        <button
          type="button"
          onClick={() => onClick(key)}
          className={classNames(
            "px-4 py-2 text-xxs font-semibold tracking-wider uppercase cursor-pointer focus:outline-none",
            {
              underline: key === active,
              "text-gray-600": key !== active,
            }
          )}
        >
          {value}
        </button>
      </li>
    ))}
  </ul>
);

export default ToggleNav;
