import classNames from "classnames";

export const SelectPlanComparisonLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div
    className={classNames(
      "grid grid-cols-[80vw_80vw] md:grid-cols-12 gap-3 md:gap-5 pt-2 px-2 md:px-0 scroll-snap-parent"
    )}
  >
    {children}
  </div>
);
