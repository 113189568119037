const SELECT_PRO_PLAN_ID = process.env.NEXT_PUBLIC_SELECT_PRO_PLAN_ID || "";

const PUBLISH_PARKING_PLAN_ID =
  process.env.NEXT_PUBLIC_PUBLISH_PARKING_PLAN_ID || "";
const PUBLISH_TRIAL_PLAN_ID =
  process.env.NEXT_PUBLIC_PUBLISH_TRIAL_PLAN_ID || "";

// Easy to fill out format
const SELECT_PLAN_SLUGS_ATOM = {
  // Current default plan assigned to new users goes here. Add ones you replace to the historical section.
  defaultFree: "select-free-v2",
  defaultPro: "select-pro",

  // Add new entries to the beginning of the array, as it determines fallback order.
  historicalFree: ["select-free"],
  historicalPro: ["select-pro-early-adopter"],
} as const;

// Easy to develop with format
const SELECT_PLAN_SLUGS = {
  defaultFree: SELECT_PLAN_SLUGS_ATOM.defaultFree,
  defaultPro: SELECT_PLAN_SLUGS_ATOM.defaultPro,
  allFree: [
    SELECT_PLAN_SLUGS_ATOM.defaultFree,
    ...SELECT_PLAN_SLUGS_ATOM.historicalFree,
  ],
  allPro: [
    SELECT_PLAN_SLUGS_ATOM.defaultPro,
    ...SELECT_PLAN_SLUGS_ATOM.historicalPro,
  ],
  all: [
    SELECT_PLAN_SLUGS_ATOM.defaultFree,
    SELECT_PLAN_SLUGS_ATOM.defaultPro,
    ...SELECT_PLAN_SLUGS_ATOM.historicalFree,
    ...SELECT_PLAN_SLUGS_ATOM.historicalPro,
  ],
} as const;

export {
  SELECT_PLAN_SLUGS,
  SELECT_PRO_PLAN_ID,
  PUBLISH_PARKING_PLAN_ID,
  PUBLISH_TRIAL_PLAN_ID,
};
