import { ActiveCampaignHelper } from "@/helpers";
import { useAlertDispatch, useModalDispatch } from "@/providers";
import { Remove } from "@/svg";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  ModalActionType,
  AlertActionType,
} from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import { Formik, Form, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const MIN_FIELD_COUNT = 1;
const MAX_FIELD_COUNT = 5;

const getProductFieldTitle = ({
  isSubscribedToSelect,
  isSubscribedToPublish,
}: {
  isSubscribedToSelect: boolean;
  isSubscribedToPublish: boolean;
}) => {
  if (isSubscribedToSelect && isSubscribedToPublish)
    return "invited_by_select_and_publish_referrer";
  if (isSubscribedToSelect) return "invited_by_select_referrer";
  if (isSubscribedToPublish) return "invited_by_publish_referrer";
  return "";
};

type Fields = {
  email1: string;
};

const initialValues: Fields = {
  email1: "",
};

const hiddenInputs = {
  u: "18",
  f: "18",
  s: "",
  c: "0",
  m: "0",
  act: "sub",
  v: "2",
};

type Props = {
  productTitle: string;
  code: string;
  email: string;
  isSubscribedToSelect: boolean;
  isSubscribedToPublish: boolean;
};

const InviteModal: React.FC<Props> = ({
  productTitle,
  code,
  email,
  isSubscribedToSelect,
  isSubscribedToPublish,
}) => {
  const alertDispatch = useAlertDispatch();
  const modalDispatch = useModalDispatch();

  // Fields state
  const [fields, setFields] = useState(initialValues);
  const fieldKeys = Object.keys(fields);
  const fieldCount = fieldKeys.length;

  // Validation
  const schema = fieldKeys.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: Yup.string()
        .email("Invalid email")
        .required("Email required"),
    }),
    {}
  );
  const validationSchema = Yup.object().shape(schema);

  // Close modal
  const closeModal = () => modalDispatch({ type: ModalActionType.Close });

  // Add field
  const addField = () => {
    setFields((prevState: any) => {
      const fieldCount = Object.keys(prevState).length;
      if (fieldCount >= MAX_FIELD_COUNT) return prevState;
      const newState = { ...prevState };
      newState[`email${fieldCount + 1}`] = "";
      return newState;
    });
  };

  // Remove field
  const removeField = () => {
    setFields((prevState: any) => {
      const fieldCount = Object.keys(prevState).length;
      if (fieldCount <= MIN_FIELD_COUNT) return prevState;
      const newState = { ...prevState };
      delete newState[`email${fieldCount}`];
      return newState;
    });
  };

  // Handle submit
  const handleSubmit = async (
    values: Fields,
    actions: FormikHelpers<Fields>
  ) => {
    try {
      // Loop through each email invite
      for (const referreeEmail of Object.values(values)) {
        // Send form
        const formResponse = await ActiveCampaignHelper.sendForm({
          ...hiddenInputs,
          email: referreeEmail,
        });
        if (!formResponse.ok) throw new Error(formResponse.statusText);

        // Get custom fields
        const fields = await ActiveCampaignHelper.fetchCustomFields();
        if (!fields) throw new Error("Failed to fetch AC fields");

        // Get field
        const productFieldTitle = getProductFieldTitle({
          isSubscribedToSelect,
          isSubscribedToPublish,
        });
        const productField = ActiveCampaignHelper.getCustomFieldByTitle(
          productFieldTitle,
          fields
        );
        const referrerCodeField = ActiveCampaignHelper.getCustomFieldByTitle(
          "invited_by_referrer_code",
          fields
        );
        if (!productField || !referrerCodeField)
          throw new Error("Failed to find AC field");

        // Create or update contact
        const fieldValues = [
          { field: productField.id, value: email },
          { field: referrerCodeField.id, value: code },
        ];
        const contactResponse =
          await ActiveCampaignHelper.createOrUpdateContact(
            referreeEmail,
            fieldValues
          );
        if (!contactResponse.ok) throw new Error(contactResponse.statusText);
      }

      closeModal();
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "success",
          title: `Email${fieldCount === 1 ? "" : "s"} sent successfully!`,
        },
      });
    } catch (error: any) {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "error",
          title: `There was an error submitting your form (${error.message}).`,
        },
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <h3 className="mb-6 text-h3 font-semibold leading-tight">
        Want to invite friends to {productTitle}?
      </h3>
      <p className="mb-6">
        Enter their email and we’ll send them an invite from for you.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          dirty,
          isValid,
        }) => (
          <Form noValidate>
            {fieldKeys.map((k, i) => {
              const key = k as keyof typeof fields;
              const fieldIndex = i + 1;
              const isFirstField = i === 0;
              const isLastField = fieldIndex === fieldKeys.length;
              return (
                <div
                  key={key}
                  className={classNames("relative", { "mt-3": !isFirstField })}
                >
                  <TextField
                    name={key}
                    type="email"
                    label={`Friend’s email ${fieldIndex}`}
                    value={values[key]}
                    error={touched[key] && !!errors[key]}
                    helperText={touched[key] && errors[key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      "data-testid": `invite-friends-email-input-${fieldIndex}`,
                    }}
                    fullWidth
                  />
                  {!isFirstField && isLastField && (
                    <button
                      type="button"
                      className="absolute top-0 right-0 p-1 mt-5 transform transition-transform duration-150 focus:outline-none hover:scale-115"
                      onClick={removeField}
                    >
                      <Remove />
                    </button>
                  )}
                </div>
              );
            })}

            {fieldCount < MAX_FIELD_COUNT && (
              <div className="mt-3.5">
                <button
                  type="button"
                  className="text-12 underline focus:outline-none hover:no-underline"
                  onClick={addField}
                >
                  + Add another email
                </button>
              </div>
            )}

            <p className="mt-6 text-12 text-gumboot">
              Don’t worry, we won’t spam your friends, we will only send one
              email to each address inviting them to {productTitle} from you.
              Also, we’ll never give or sell this information to third parties.
            </p>

            <div className="flex space-x-2.5 mt-6 sm:max-w-80 sm:ml-auto">
              <Button
                type="button"
                appearance="secondary"
                colour="gray-600"
                disabled={isSubmitting}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colour="black"
                disabled={!dirty || !isValid}
                isLoading={isSubmitting}
                showLoader
                testId="invite-friends-submit-button"
              >
                Invite
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InviteModal;
