// DatoCMS modal API keys from the '_modelApiKey' property
export const MODULE_KEYS = {
  accordion: "module_accordion",
  resource: "module_resource",
  threeUp: "module_three_up",
};

// Responsive image
// See: https://www.datocms.com/blog/offer-responsive-progressive-lqip-images-in-2020
export const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }
`;

// Meta tags
export const metaTagsFragment = `
  fragment metaTagsFragment on Tag {
    attributes
    content
    tag
  }
`;
