const EyeOpen: React.FC = () => (
  <svg
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9991 8.44455C12.3492 8.44455 13.4436 7.35014 13.4436 6.00011C13.4436 5.47095 13.2754 4.98106 12.9897 4.58095C13.0603 4.72212 13.1 4.88142 13.1 5.05C13.1 5.6299 12.6299 6.1 12.05 6.1C11.4701 6.1 11 5.6299 11 5.05C11 4.4701 11.4701 4 12.05 4C12.2562 4 12.4484 4.05942 12.6107 4.16206C12.1804 3.78454 11.6165 3.55566 10.9991 3.55566C9.6491 3.55566 8.55469 4.65008 8.55469 6.00011C8.55469 7.35014 9.6491 8.44455 10.9991 8.44455Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 12C5 12 0 6 0 6C0 6 5 0 11 0C17 0 22 6 22 6C22 6 17 12 11 12ZM6.32194 9.75743C5.42592 9.30034 4.59322 8.75741 3.85 8.2C2.89643 7.48482 2.11969 6.76798 1.58211 6.23039C1.50087 6.14916 1.42523 6.07215 1.35539 6C1.42523 5.92786 1.50087 5.85084 1.58211 5.76961C2.11969 5.23202 2.89643 4.51518 3.85 3.8C4.59322 3.24259 5.42592 2.69966 6.32193 2.24257C5.49492 3.27091 5 4.57766 5 6C5 7.42234 5.49492 8.72909 6.32194 9.75743ZM15.6781 9.75743C16.5741 9.30034 17.4068 8.75741 18.15 8.2C19.1036 7.48482 19.8803 6.76798 20.4179 6.23039C20.4991 6.14916 20.5748 6.07215 20.6446 6C20.5748 5.92786 20.4991 5.85084 20.4179 5.76961C19.8803 5.23202 19.1036 4.51518 18.15 3.8C17.4068 3.24259 16.5741 2.69966 15.6781 2.24257C16.5051 3.27091 17 4.57766 17 6C17 7.42234 16.5051 8.72909 15.6781 9.75743ZM11 1C8.23858 1 6 3.23858 6 6C6 8.76142 8.23858 11 11 11C13.7614 11 16 8.76142 16 6C16 3.23858 13.7614 1 11 1ZM12.9897 4.58095C13.0603 4.72212 13.1 4.88142 13.1 5.05C13.1 5.46982 12.8536 5.83209 12.4976 6.00011C12.4798 6.0085 12.4617 6.01641 12.4434 6.02382C12.3219 6.07295 12.1891 6.1 12.05 6.1C11.4701 6.1 11 5.6299 11 5.05C11 4.8733 11.0436 4.70679 11.1208 4.56067C11.2967 4.22726 11.6468 4 12.05 4C12.2562 4 12.4484 4.05942 12.6107 4.16206C12.4426 4.01457 12.2541 3.88978 12.05 3.79245C11.7317 3.64064 11.3753 3.55566 10.9991 3.55566C10.8686 3.55566 10.7404 3.5659 10.6154 3.58561C9.44767 3.76972 8.55469 4.78063 8.55469 6.00011C8.55469 7.35014 9.6491 8.44455 10.9991 8.44455C12.1334 8.44455 13.0873 7.67194 13.3632 6.62426C13.4156 6.42502 13.4436 6.21583 13.4436 6.00011C13.4436 5.66313 13.3754 5.34208 13.2521 5.05C13.1817 4.88342 13.0935 4.72626 12.9897 4.58095ZM11.9389 7.09704C11.6863 7.31367 11.358 7.44455 10.9991 7.44455C10.2014 7.44455 9.55469 6.79785 9.55469 6.00011C9.55469 5.58896 9.72647 5.21794 10.0022 4.95489C10.0007 4.98641 10 5.01812 10 5.05C10 6.14492 10.8584 7.03935 11.9389 7.09704Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeOpen;
