import { ProductName } from "@/api";

type Props = {
  product: ProductName;
};

const ReferralCompleteIcon: React.FC<Props> = ({ product }) => (
  <svg width="117" height="80" viewBox="0 0 117 80" fill="none">
    <path
      d="M74.0591 39.2105C74.0591 48.0241 66.9142 55.169 58.1006 55.169C49.287 55.169 42.1421 48.0241 42.1421 39.2105C42.1421 30.3968 49.287 23.252 58.1006 23.252C66.9142 23.252 74.0591 30.3968 74.0591 39.2105Z"
      fill={product === ProductName.Select ? "#4F429C" : "#F16370"}
    />
    <path
      d="M4.10309 50.197C4.09523 49.383 5.01125 48.9012 5.67752 49.3688L8.5946 51.4162C8.85468 51.5987 9.18604 51.6468 9.48727 51.5457L12.8659 50.4117C13.6376 50.1527 14.3789 50.875 14.1401 51.6532L13.0943 55.0602C13.0011 55.3639 13.0578 55.6939 13.247 55.9492L15.3695 58.812C15.8543 59.4659 15.3965 60.3942 14.5826 60.4075L11.0192 60.4657C10.7015 60.4709 10.4052 60.6268 10.2209 60.8856L8.15405 63.789C7.68198 64.4521 6.65769 64.3035 6.39352 63.5335L5.23696 60.1626C5.13384 59.862 4.89403 59.6284 4.59091 59.5331L1.191 58.4646C0.414451 58.2205 0.23926 57.2004 0.88989 56.7113L3.73848 54.5696C3.99245 54.3787 4.14056 54.0784 4.13749 53.7607L4.10309 50.197Z"
      fill={product === ProductName.Select ? "#4F429C" : "#31256F"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0371 56.0784C18.2063 55.5527 18.7697 55.2638 19.2954 55.433C25.6878 57.4913 29.0265 59.3947 33.6139 62.9627C34.0499 63.3018 34.1284 63.9301 33.7893 64.366C33.4503 64.802 32.822 64.8805 32.386 64.5414C27.9735 61.1095 24.8412 59.3199 18.6824 57.3368C18.1567 57.1675 17.8678 56.6041 18.0371 56.0784Z"
      fill={product === ProductName.Select ? "#4F429C" : "#31256F"}
    />
    <path
      d="M16.9746 27.0495C17.6337 26.5718 18.557 27.0396 18.5615 27.8536L18.5694 29.2705C18.5712 29.5882 18.7239 29.8862 18.9807 30.0733L20.1262 30.9074C20.7842 31.3866 20.6245 32.4092 19.8518 32.6651L18.5067 33.1105C18.205 33.2104 17.9688 33.4476 17.8703 33.7497L17.4309 35.0968C17.1786 35.8707 16.1566 36.0349 15.6745 35.3791L14.8353 34.2374C14.6471 33.9814 14.3484 33.8301 14.0306 33.8297L12.6137 33.8281C11.7997 33.8272 11.3277 32.9061 11.8025 32.2449L12.6289 31.0939C12.8143 30.8358 12.8659 30.505 12.7681 30.2027L12.3317 28.8546C12.081 28.0802 12.8112 27.3467 13.5868 27.5939L14.9368 28.0242C15.2395 28.1207 15.5701 28.0676 15.8274 27.8811L16.9746 27.0495Z"
      fill={product === ProductName.Select ? "#B44893" : "#4F429C"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.375 34.4114C21.7132 33.9748 22.3413 33.895 22.7779 34.2333C30.1674 39.9578 35.2018 46.6223 38.278 52.7661C38.5253 53.2599 38.3254 53.8607 37.8316 54.1079C37.3377 54.3552 36.7369 54.1553 36.4897 53.6615C33.5424 47.7754 28.6968 41.3485 21.5531 35.8143C21.1165 35.4761 21.0367 34.848 21.375 34.4114Z"
      fill={product === ProductName.Select ? "#B44893" : "#4F429C"}
    />
    <path
      d="M96.2711 27.8293C96.4226 27.0296 97.4152 26.7363 97.9771 27.3252L98.9552 28.3505C99.1746 28.5804 99.4901 28.6923 99.8053 28.6521L101.211 28.4728C102.018 28.3698 102.604 29.2232 102.217 29.9396L101.545 31.1867C101.394 31.4663 101.385 31.801 101.52 32.0883L102.125 33.3697C102.473 34.1058 101.842 34.9265 101.041 34.7803L99.6475 34.5258C99.3349 34.4687 99.0138 34.5636 98.7825 34.7814L97.7507 35.7526C97.158 36.3106 96.1826 35.9644 96.0742 35.1577L95.8855 33.7533C95.8432 33.4384 95.6537 33.1623 95.3751 33.0096L94.1326 32.3285C93.4188 31.9372 93.4466 30.9026 94.1804 30.5502L95.4577 29.9367C95.7441 29.7992 95.9481 29.5337 96.0073 29.2215L96.2711 27.8293Z"
      fill="#F77B58"
    />
    <path
      d="M109.975 47.8682C110.466 47.2194 111.486 47.3982 111.727 48.1757L112.489 50.632C112.583 50.9355 112.816 51.1761 113.116 51.2803L115.546 52.1238C116.315 52.3907 116.46 53.4155 115.795 53.8852L113.694 55.3692C113.435 55.5525 113.278 55.8483 113.272 56.1659L113.22 58.7373C113.204 59.5511 112.274 60.0056 111.622 59.5185L109.562 57.9793C109.307 57.7891 108.977 57.7312 108.673 57.8234L106.212 58.5691C105.433 58.8052 104.713 58.0612 104.975 57.2905L105.802 54.8552C105.904 54.5544 105.857 54.2229 105.676 53.9621L104.206 51.8517C103.741 51.1837 104.226 50.2694 105.04 50.2802L107.612 50.3144C107.929 50.3186 108.23 50.1716 108.422 49.9183L109.975 47.8682Z"
      fill="#FCB76F"
    />
    <path
      d="M52.5646 5.12164C52.0178 4.51866 52.382 3.54986 53.1906 3.45647L55.4239 3.19856C55.7395 3.1621 56.0191 2.97781 56.177 2.70207L57.2941 0.75116C57.6986 0.0447698 58.7325 0.0917959 59.0712 0.831987L60.0066 2.87625C60.1388 3.16519 60.4005 3.37409 60.7115 3.43905L62.9121 3.89862C63.7089 4.06503 63.9837 5.0629 63.3844 5.61375L61.7293 7.13508C61.4953 7.35011 61.3775 7.66351 61.4118 7.9794L61.6548 10.2143C61.7428 11.0236 60.8786 11.5933 60.1696 11.1935L58.2112 10.0895C57.9344 9.93345 57.5999 9.91824 57.3101 10.0485L55.2597 10.9702C54.5172 11.3039 53.7084 10.6582 53.8694 9.86025L54.3143 7.65658C54.3771 7.34512 54.2882 7.02232 54.0748 6.78694L52.5646 5.12164Z"
      fill="#F16370"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58 12.998C58.5523 12.998 59 13.4458 59 13.998L59 18.998C59 19.5503 58.5523 19.998 58 19.998C57.4477 19.998 57 19.5503 57 18.998L57 13.998C57 13.4458 57.4477 12.998 58 12.998Z"
      fill="#F16370"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.4895 55.3924C99.6222 55.9285 99.2953 56.4707 98.7592 56.6034C94.397 57.6837 89.0962 60.5927 86.2592 63.9026C85.8998 64.3219 85.2685 64.3705 84.8492 64.011C84.4298 63.6516 84.3813 63.0203 84.7407 62.601C87.9037 58.9108 93.6029 55.8199 98.2784 54.6621C98.8145 54.5293 99.3567 54.8563 99.4895 55.3924Z"
      fill="#FCB76F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.529 35.1252C93.7814 35.6164 93.5878 36.2192 93.0966 36.4717C86.2366 39.997 81.3581 46.7711 77.8944 53.6985C77.6474 54.1925 77.0467 54.3927 76.5528 54.1457C76.0588 53.8987 75.8586 53.298 76.1055 52.8041C79.6419 45.7314 84.7633 38.5055 92.1825 34.6928C92.6737 34.4404 93.2765 34.6339 93.529 35.1252Z"
      fill="#F16370"
    />
    <path
      d="M70 15.752C70 16.5804 69.3284 17.252 68.5 17.252C67.6716 17.252 67 16.5804 67 15.752C67 14.9235 67.6716 14.252 68.5 14.252C69.3284 14.252 70 14.9235 70 15.752Z"
      fill="#FCB76F"
    />
    <path
      d="M19 68.752C19 69.5804 18.3284 70.252 17.5 70.252C16.6716 70.252 16 69.5804 16 68.752C16 67.9235 16.6716 67.252 17.5 67.252C18.3284 67.252 19 67.9235 19 68.752Z"
      fill="#FCB76F"
    />
    <path
      d="M83 26.7529C83 27.5814 82.3284 28.2529 81.5 28.2529C80.6716 28.2529 80 27.5814 80 26.7529C80 25.9245 80.6716 25.2529 81.5 25.2529C82.3284 25.2529 83 25.9245 83 26.7529Z"
      fill="#FCB76F"
    />
    <path
      d="M102 43.752C102 44.5804 101.328 45.252 100.5 45.252C99.6716 45.252 99 44.5804 99 43.752C99 42.9235 99.6716 42.252 100.5 42.252C101.328 42.252 102 42.9235 102 43.752Z"
      fill="#FCB76F"
    />
    <path
      d="M39 23.7529C39 24.5814 38.3284 25.2529 37.5 25.2529C36.6716 25.2529 36 24.5814 36 23.7529C36 22.9245 36.6716 22.2529 37.5 22.2529C38.3284 22.2529 39 22.9245 39 23.7529Z"
      fill="#FCB76F"
    />
    <path
      d="M21 45.752C21 46.5804 20.3284 47.252 19.5 47.252C18.6716 47.252 18 46.5804 18 45.752C18 44.9235 18.6716 44.252 19.5 44.252C20.3284 44.252 21 44.9235 21 45.752Z"
      fill="#FCB76F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.2414 35.2409L56.7539 45.2913L50.8378 39.6216L52.2216 38.1776L56.7539 42.5212L65.8575 33.7969L67.2414 35.2409Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.7732 54.1314C69.7863 51.844 74.0591 46.0262 74.0591 39.2105C74.0591 30.3968 66.9142 23.252 58.1006 23.252C49.287 23.252 42.1421 30.3968 42.1421 39.2105C42.1421 45.7644 46.0929 51.3955 51.7428 53.8522C39.8262 56.35 30.8774 66.9187 30.8774 79.5776H32.8774C32.8774 66.1656 43.75 55.293 57.162 55.293C70.5741 55.293 81.4466 66.1656 81.4466 79.5776H83.4466C83.4466 67.3444 75.0896 57.0631 63.7732 54.1314ZM72.0591 39.2105C72.0591 46.9195 65.8097 53.169 58.1006 53.169C50.3915 53.169 44.1421 46.9195 44.1421 39.2105C44.1421 31.5014 50.3915 25.252 58.1006 25.252C65.8097 25.252 72.0591 31.5014 72.0591 39.2105Z"
      fill="currentColor"
    />
  </svg>
);

export default ReferralCompleteIcon;
