const PAYG_PACK_SLUG = "edit_payg" as const;
const EDIT_20K_SALE = "edit_20k_sale" as const;
type PackSlug = typeof PAYG_PACK_SLUG | typeof EDIT_20K_SALE | `${number}_edit`;

type CreditPurchasingPack = {
  id: Uuid;
  credits: number;
  currency: string;
  description: string;
  insertedAt: IsoDate;
  name: string;
  productName: string;
  slug: PackSlug;
  status: string;
  perCreditAmount: number;
  unitAmount: number;
  updatedAt: IsoDate;
  saving: number;
  isSale: boolean;
};

type ServicesCreditPurchasingPacksResponse = {
  data: ServicesCreditPurchasingPack[];
};

type ServicesCreditPurchasingPack = {
  attributes: {
    credits: number;
    currency: string;
    description: string;
    id: string;
    "inserted-at": IsoDate;
    name: string;
    "product-name": string;
    slug: string;
    status: string;
    "unit-amount": number;
    "updated-at": IsoDate;
    "is-sale": boolean;
  };
};

function deserializeCreditPurchasingPacks(
  packs: ServicesCreditPurchasingPacksResponse
): CreditPurchasingPack[] {
  const deserializedPacks = packs.data.map(deserializeCreditPurchasingPack);
  const maxPerCreditAmount = deserializedPacks.reduce(
    (min, pack) => Math.max(min, pack.perCreditAmount),
    -Infinity
  );
  return deserializedPacks
    .map((pack) => ({
      ...pack,
      saving: Math.floor(
        (maxPerCreditAmount - pack.perCreditAmount) * pack.credits
      ),
    }))
    .sort((a, b) => a.unitAmount - b.unitAmount);
}

function deserializeCreditPurchasingPack({
  attributes: {
    credits,
    currency,
    description,
    id,
    "inserted-at": insertedAt,
    name,
    "product-name": productName,
    slug,
    status,
    "unit-amount": unitAmount,
    "updated-at": updatedAt,
    "is-sale": isSale,
  },
}: ServicesCreditPurchasingPack): Omit<CreditPurchasingPack, "saving"> {
  return {
    credits,
    currency,
    description,
    id,
    insertedAt,
    name,
    productName,
    slug: slug as PackSlug,
    status,
    perCreditAmount: unitAmount / credits,
    unitAmount,
    updatedAt,
    isSale,
  };
}

export type {
  ServicesCreditPurchasingPacksResponse,
  ServicesCreditPurchasingPack,
  CreditPurchasingPack,
};
export {
  deserializeCreditPurchasingPacks,
  deserializeCreditPurchasingPack,
  PAYG_PACK_SLUG,
};
