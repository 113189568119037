import { deserializeCreditBalance } from "./interfaces/creditBalance";
import {
  CreditsPurchaseParams,
  deserializeCreditPurchase,
} from "./interfaces/creditPurchase";
import { deserializeCreditPurchasingPacks } from "./interfaces/editCreditPacks";
import { GET_EDIT_CREDITS_BALANCE_KEY, GET_HAS_EDIT_KEY } from "./keys";
import {
  Api,
  GET_ACCOUNT_KEY,
  GET_ACCOUNTS_KEY,
  GET_USER_KEY,
  GET_PLANS_KEY,
  GET_GRANDFATHERED_PLANS_KEY,
  GET_SELECT_FREE_PROJECT_THRESHOLD_KEY,
  GET_PRODUCTS_KEY,
  GET_REWARDS_KEY,
  GET_COUPON_KEY,
  GET_PROJECTS_PUBLISH_KEY,
  PREVIEW_CHARGE_SIGN_UP_KEY,
  PREVIEW_CHARGE_NEW_KEY,
  PREVIEW_CHARGE_EXISTING_KEY,
  GET_AFFILIATE_CLICKS_KEY,
  GET_AFFILIATE_SIGN_UPS_KEY,
  ProductInterval,
  PlanStatus,
  ILoginResponse,
  IGetAccountResponse,
  IGetAccountsResponse,
  ICreateAccountResponse,
  IUserResponse,
  IGetPlansResponse,
  IProductResponse,
  IGetProductsResponse,
  IGetRewardsResponse,
  ICouponResponse,
  IPreviewChargeResponse,
  ICreateAccountRequest,
  IUpdateAccountRequest,
  IGetProjectsResponse,
  CreateProductParameters,
  UpdateProductParameters,
  DeleteProductParameters,
  IAffiliateResponse,
  IAffiliateClicksResponse,
  IAffiliateSignUpsResponse,
  IGoogleOAuthAssociateResponse,
  GET_EDIT_CREDIT_PACKS_KEY,
} from "@/api";
import { AssociateGoogleAccountErrorModal } from "@/components/Modal/AssociateGoogleAccountErrorModal";
import {
  IForgotPasswordFields,
  ILoginFields,
  IResetPasswordFields,
  IUserDetailsFields,
} from "@/containers";
import { AuthHelper } from "@/helpers";
import {
  useAccountContext,
  useIsAuthenticated,
  useAlertDispatch,
  useModalDispatch,
} from "@/providers";
import { Product } from "@/types";
import {
  AlertActionType,
  ModalActionType,
} from "@narrative-software/narrative-web-ui";
import { AxiosError } from "axios";
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  useQueryClient,
  QueryClient,
  QueryKey,
} from "react-query";

export type QueryOptions<T> = UseQueryOptions<T, AxiosError<T>>;
export type MutationOptions<T, P> = UseMutationOptions<T, AxiosError<T>, P>;

const updateProductsQueryData = async ({
  accountID,
  response,
  queryClient,
}: {
  accountID: string;
  response: IProductResponse;
  queryClient: QueryClient;
}) => {
  const productsData = queryClient.getQueryData<IGetProductsResponse>([
    GET_PRODUCTS_KEY,
    accountID,
  ]);
  const index = productsData?.data?.findIndex(
    ({ id }) => id === response?.data.id
  );
  if (productsData && index) {
    productsData.data[index] = response.data;
    queryClient.setQueryData([GET_PRODUCTS_KEY, accountID], productsData);
  } else {
    await queryClient.invalidateQueries([GET_PRODUCTS_KEY, accountID]); // Refetch products data
  }
};

/**
 * Login
 */
export const useLoginMutation = (
  config?: MutationOptions<ILoginResponse, ILoginFields>
) => {
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.login, {
    onSuccess: (response) => {
      if (response.access_token && response.refresh_token) {
        AuthHelper.initialize(response.access_token, response.refresh_token);
      } else {
        alertDispatch({
          type: AlertActionType.SetContent,
          payload: {
            type: "error",
            title: "Token not received",
          },
        });
      }
    },
    ...config,
  });
};

/**
 * Get accounts
 */
export const useAccountsQuery = (
  { include }: { include?: string[] } = {},
  config?: QueryOptions<IGetAccountsResponse>
) => {
  const queryClient = useQueryClient();
  return useQuery(
    GET_ACCOUNTS_KEY as QueryKey,
    () => Api.getAccounts(include),
    {
      onSuccess: (response) => {
        if (response?.data) {
          const accountData = response.data[0];
          queryClient.setQueryData([GET_ACCOUNT_KEY, accountData.id], {
            data: accountData,
          });
        }
      },
      ...config,
    }
  );
};

/**
 * Get account
 */
export const useAccountQuery = (
  { id, include }: { id?: string; include?: string[] } = {},
  config?: QueryOptions<IGetAccountResponse>
) => {
  const queryClient = useQueryClient();
  const { accountID: accountContextID } = useAccountContext();
  const accountID = id || accountContextID; // If no 'id' is passed, use the accountID from context
  return useQuery(
    [GET_ACCOUNT_KEY, accountID] as QueryKey,
    () => Api.getAccount(accountID, include),
    {
      enabled: !!accountID, // Query will not execute until accountID exists
      onSuccess: (response) => {
        // If products are included in the request
        if (response?.included?.length) {
          const productsData = response.included.filter(
            (item) => item.type === "products"
          );
          if (productsData?.length) {
            queryClient.setQueryData([GET_PRODUCTS_KEY, accountID], {
              data: productsData,
            });
          }
        }
      },
      ...config,
    }
  );
};

/**
 * Create account
 */
export const useCreateAccountMutation = (
  config?: MutationOptions<ICreateAccountResponse, ICreateAccountRequest>
) => {
  const queryClient = useQueryClient();
  return useMutation(Api.createAccount, {
    onSuccess: (response) => {
      if (response?.data) {
        queryClient.setQueryData([GET_ACCOUNT_KEY, response.data.id], {
          data: response.data,
        });
      }
    },
    ...config,
  });
};

/**
 * Update account
 */
export const useUpdateAccountMutation = (
  {
    successMessage,
    showSuccess = true,
  }: { successMessage?: string; showSuccess?: boolean } = {},
  config?: MutationOptions<IGetAccountResponse, IUpdateAccountRequest>
) => {
  const queryClient = useQueryClient();
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.updateAccount, {
    onSuccess: (response) => {
      if (response?.data) {
        queryClient.setQueryData([GET_ACCOUNT_KEY, response.data.id], {
          data: response.data,
        });
        if (showSuccess) {
          alertDispatch({
            type: AlertActionType.SetContent,
            payload: {
              type: "success",
              title: successMessage || "Account updated successfully!",
            },
          });
        }
      }
    },
    ...config,
  });
};

/**
 * Delete account
 */
export const useDeleteAccountMutation = (
  config?: MutationOptions<void, string>
) => {
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.deleteAccount, {
    onSuccess: () => {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "success",
          title: "Account deleted successfully!",
        },
      });
    },
    ...config,
  });
};

/**
 * Get user
 */
export const useUserQuery = (
  { include }: { include?: string[] } = {},
  config?: QueryOptions<IUserResponse>
) => {
  const queryClient = useQueryClient();
  const { setAccountID } = useAccountContext();
  const isAuthenticated = useIsAuthenticated();
  return useQuery(GET_USER_KEY as QueryKey, () => Api.getUser(include), {
    enabled: isAuthenticated,
    onSuccess: (response) => {
      // If accounts are included in the request
      if (response?.included && response?.included.length) {
        const accounts = response.included.filter(
          (item) => item?.type === "accounts"
        );
        if (accounts?.length) {
          queryClient.setQueryData(GET_ACCOUNTS_KEY, { data: accounts });
          const accountID = accounts[0]?.id;
          if (accountID) {
            setAccountID(accountID);
            queryClient.setQueryData([GET_ACCOUNT_KEY, accountID], {
              data: accounts[0],
            });
          }
        }
      }
    },
    ...config,
  });
};

/**
 * Update user
 */
export const useUpdateUserMutation = (
  {
    successMessage,
    showSuccess = true,
  }: { successMessage?: string; showSuccess?: boolean } = {},
  config?: MutationOptions<IUserResponse, IUserDetailsFields & { id: string }>
) => {
  const queryClient = useQueryClient();
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.updateUser, {
    onSuccess: (response) => {
      if (response?.data) {
        queryClient.setQueryData(GET_USER_KEY, response);
        if (showSuccess) {
          alertDispatch({
            type: AlertActionType.SetContent,
            payload: {
              type: "success",
              title: successMessage || "Account details updated successfully!",
            },
          });
        }
      }
    },
    ...config,
  });
};

/**
 * Disconnect user google account
 */
export const useDisconnectUserGoogleAccount = ({
  successMessage,
  showSuccess = true,
}: { successMessage?: string; showSuccess?: boolean } = {}) => {
  const queryClient = useQueryClient();
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.disconnectUserGoogleAccount, {
    onSuccess: (response) => {
      if (response?.data) {
        queryClient.setQueryData(GET_USER_KEY, response);
        if (showSuccess) {
          alertDispatch({
            type: AlertActionType.SetContent,
            payload: {
              type: "success",
              title: successMessage || "Account details updated successfully!",
            },
          });
        }
      }
    },
  });
};

/**
 * Associate user google account
 */
export const useAssociateUserGoogleAccount = (
  {
    successMessage,
    showSuccess = true,
    onSuccess,
  }: {
    successMessage?: string;
    showSuccess?: boolean;
    onSuccess?: (response: IUserResponse) => void;
  } = {},
  config?: MutationOptions<IUserResponse, IGoogleOAuthAssociateResponse>
) => {
  const queryClient = useQueryClient();
  const alertDispatch = useAlertDispatch();
  const modalDispatch = useModalDispatch();
  return useMutation(Api.associateUserGoogleAccount, {
    onSuccess: (response) => {
      if (response?.data) {
        queryClient.setQueryData(GET_USER_KEY, response);
        if (showSuccess) {
          alertDispatch({
            type: AlertActionType.SetContent,
            payload: {
              type: "success",
              title:
                successMessage || "Google account associated successfully!",
            },
          });
        }
        if (onSuccess) onSuccess(response);
      }
    },
    onError: (error) => {
      let email = "";
      try {
        email = JSON.parse(error?.config?.data).data.attributes.email;
      } catch (e) {
        // No problem, email was optional for display message.
      }

      modalDispatch({
        type: ModalActionType.SetContent,
        payload: {
          children: <AssociateGoogleAccountErrorModal email={email} />,
        },
      });
    },
    ...config,
  });
};

/**
 * Get plans
 */
export const usePlansQuery = (config?: QueryOptions<IGetPlansResponse>) => {
  return useQuery(GET_PLANS_KEY as QueryKey, Api.getPlans, config);
};

/**
 * Get grandfathered plans
 */
export const useGrandFatheredPlansQuery = () => {
  return useQuery(GET_GRANDFATHERED_PLANS_KEY as QueryKey, (context) =>
    Api.getPlans(context, PlanStatus.Grandfathered)
  );
};

/**
 * Get Packs
 */
export const useEditCreditPacksQuery = () => {
  return useQuery(GET_EDIT_CREDIT_PACKS_KEY, async () => {
    const result = await Api.getEditCreditPacks();
    return deserializeCreditPurchasingPacks(result);
  });
};

/**
 * Get Edit Credits Balance
 */
export const useEditCreditBalanceQuery = () => {
  const { accountID } = useAccountContext();

  return useQuery(
    [GET_EDIT_CREDITS_BALANCE_KEY, accountID],
    async () => {
      const result = await Api.getEditCreditBalance(accountID);
      return deserializeCreditBalance(result);
    },
    {
      enabled: !!accountID,
      refetchInterval: 5_000,
    }
  );
};

/**
 * Purchase Edit Credtis
 */
export const usePurchaseEditCreditsMutation = () => {
  const { accountID } = useAccountContext();

  return useMutation(async (args: Omit<CreditsPurchaseParams, "accountId">) => {
    const result = await Api.purchaseEditCredits({
      accountId: accountID,
      ...args,
    });
    return deserializeCreditPurchase(result);
  });
};

/**
 * Get products
 */
export const useProductsQuery = (
  { accountID: accountId }: { accountID?: string } = {},
  config?: QueryOptions<IGetProductsResponse>
) => {
  const { accountID: accountContextID } = useAccountContext();
  const accountID = accountId || accountContextID; // If no 'accountID' is passed, use the accountID from context
  return useQuery(
    [GET_PRODUCTS_KEY, accountID] as QueryKey,
    () => Api.getProducts(accountID),
    {
      enabled: !!accountID, // Query will not execute until accountID exists
      ...config,
    }
  );
};

/**
 * Create products
 */
export const useCreateProductMutation = (
  { showSuccess = true }: { showSuccess?: boolean } = {},
  config?: MutationOptions<IProductResponse, CreateProductParameters>
) => {
  const queryClient = useQueryClient();
  const alertDispatch = useAlertDispatch();
  const { accountID } = useAccountContext();
  return useMutation(Api.createProduct, {
    onSuccess: async (response) => {
      await updateProductsQueryData({ accountID, response, queryClient });
      if (showSuccess) {
        alertDispatch({
          type: AlertActionType.SetContent,
          payload: {
            type: "success",
            title: "Subscription added successfully!",
          },
        });
      }
    },
    ...config,
  });
};

/**
 * Update products
 */
export const useUpdateProductMutation = (
  { showSuccess = true }: { showSuccess?: boolean } = {},
  config?: MutationOptions<IProductResponse, UpdateProductParameters>
) => {
  const queryClient = useQueryClient();
  const alertDispatch = useAlertDispatch();
  const { accountID } = useAccountContext();
  return useMutation(Api.updateProduct, {
    onSuccess: async (response) => {
      await updateProductsQueryData({ accountID, response, queryClient });
      if (showSuccess) {
        alertDispatch({
          type: AlertActionType.SetContent,
          payload: {
            type: "success",
            title: "Subscription updated successfully!",
          },
        });
      }
    },
    ...config,
  });
};

/**
 * Delete product
 */
export const useDeleteProductMutation = (
  config?: MutationOptions<IProductResponse, DeleteProductParameters>
) => {
  const queryClient = useQueryClient();
  const { accountID } = useAccountContext();
  return useMutation(Api.deleteProduct, {
    onSuccess: async (response) => {
      await updateProductsQueryData({ accountID, response, queryClient });
    },
    ...config,
  });
};

/**
 * Get Publish projects
 */
export const useProjectsPublishQuery = (
  { productID }: { productID: string },
  config?: QueryOptions<IGetProjectsResponse>
) => {
  return useQuery(
    [GET_PROJECTS_PUBLISH_KEY, productID] as QueryKey,
    () => Api.getProjectsPublish(productID),
    {
      enabled: !!productID, // Query will not execute until projectID exists
      ...config,
    }
  );
};

/**
 * Get Affiliate details
 */
export const useAffiliate = (
  accountId?: string,
  config?: QueryOptions<IAffiliateResponse>
) => {
  const { accountID: accountContextID } = useAccountContext();
  const accountID = accountId || accountContextID; // If no 'accountID' is passed, use the accountID from context
  return useQuery(
    [GET_AFFILIATE_CLICKS_KEY, accountID] as QueryKey,
    () => Api.getAffiliate(accountID),
    {
      enabled: !!accountID, // Query will not execute until projectID exists
      ...config,
    }
  );
};

/**
 * Get Affiliate clicks
 */
export const useAffiliateClicks = (
  {
    accountId,
    product,
    startDate,
    endDate,
  }: {
    accountId?: string;
    product?: Product;
    startDate?: string;
    endDate?: string;
  } = {},
  config?: QueryOptions<IAffiliateClicksResponse>
) => {
  const { accountID: accountContextID } = useAccountContext();
  const accountID = accountId || accountContextID; // If no 'accountID' is passed, use the accountID from context
  return useQuery(
    [
      GET_AFFILIATE_CLICKS_KEY,
      accountID,
      product,
      startDate,
      endDate,
    ] as QueryKey,
    () => Api.getAffiliateClicks({ accountID, product, startDate, endDate }),
    {
      enabled: !!accountID, // Query will not execute until projectID exists
      ...config,
    }
  );
};

/**
 * Get Affiliate sign ups
 */
export const useAffiliateSignUps = (
  {
    accountId,
    product,
    startDate,
    endDate,
  }: {
    accountId?: string;
    product?: Product;
    startDate?: string;
    endDate?: string;
  } = {},
  config?: QueryOptions<IAffiliateSignUpsResponse>
) => {
  const { accountID: accountContextID } = useAccountContext();
  const accountID = accountId || accountContextID; // If no 'accountID' is passed, use the accountID from context
  return useQuery(
    [
      GET_AFFILIATE_SIGN_UPS_KEY,
      accountID,
      product,
      startDate,
      endDate,
    ] as QueryKey,
    () => Api.getAffiliateSignUps({ accountID, product, startDate, endDate }),
    {
      enabled: !!accountID, // Query will not execute until projectID exists
      ...config,
    }
  );
};

/**
 * Forgot password
 */
export const useForgotPasswordMutation = (
  config?: MutationOptions<void, IForgotPasswordFields>
) => {
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.forgotPassword, {
    onSuccess: () => {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "info",
          title:
            "You will receive an email shortly with a link to create a new password.",
        },
      });
    },
    ...config,
  });
};

/**
 * Reset password
 */
export const useResetPasswordMutation = (
  config?: MutationOptions<
    IUserResponse,
    IResetPasswordFields & { code: string }
  >
) => {
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.resetPassword, {
    onSuccess: () => {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "success",
          title: "Password updated successfully!",
        },
      });
    },
    ...config,
  });
};

/**
 * Verify email
 */
export const useVerifyEmailMutation = (
  config?: MutationOptions<void, string>
) => {
  const alertDispatch = useAlertDispatch();
  return useMutation(Api.verifyEmail, {
    onSuccess: () => {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "info",
          title:
            "You will receive an email shortly with a link to verify your email address.",
        },
      });
    },
    ...config,
  });
};

/**
 * Get rewards
 */
export const useRewardsQuery = (
  { accountId }: { accountId?: string } = {},
  config?: QueryOptions<IGetRewardsResponse>
) => {
  const { accountID: accountContextID } = useAccountContext();
  const accountID = accountId || accountContextID; // If no 'accountID' is passed, use the accountID from context
  return useQuery(
    [GET_REWARDS_KEY, accountID] as QueryKey,
    () => Api.getRewards(accountID),
    {
      enabled: !!accountID, // Query will not execute until accountID exists
      ...config,
    }
  );
};

/**
 * Get coupon mutation
 */
export const useCouponMutation = (
  config?: MutationOptions<
    ICouponResponse,
    { interval: ProductInterval; coupon: string; planID?: string }
  >
) => {
  const queryClient = useQueryClient();
  return useMutation(Api.getCoupon, {
    onSuccess: (response) => {
      if (response?.data.attributes.valid) {
        queryClient.setQueryData(GET_COUPON_KEY, response);
      } else {
        throw new Error("Invalid coupon");
      }
    },
    ...config,
  });
};

/**
 * Preview charge mutation
 */
export const usePreviewChargeMutation = (
  { isExistingProduct }: { isExistingProduct?: boolean } = {},
  config?: MutationOptions<
    IPreviewChargeResponse,
    {
      planID: string;
      interval: ProductInterval;
      coupon?: string;
      accountID?: string;
      productID?: string;
    }
  >
) => {
  const queryClient = useQueryClient();
  const { accountID } = useAccountContext();
  const { previewChargeNew, previewChargeExisting, previewChargeSignUp } = Api;

  let key: string;
  let request: any;

  if (accountID) {
    if (isExistingProduct) {
      key = PREVIEW_CHARGE_EXISTING_KEY;
      request = previewChargeExisting;
    } else {
      key = PREVIEW_CHARGE_NEW_KEY;
      request = previewChargeNew;
    }
  } else {
    key = PREVIEW_CHARGE_SIGN_UP_KEY;
    request = previewChargeSignUp;
  }

  return useMutation(request, {
    onSuccess: async (response) => {
      if (response?.data) {
        queryClient.setQueryData(key, response);
      }
    },
    ...config,
  });
};
