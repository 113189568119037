import { Element } from "domhandler";
import parse, { domToReact, attributesToProps } from "html-react-parser";
import Link from "next/link";
import React from "react";

/**
 * Strip paragraph tags from string
 */
export const stripParagraphTags = (string: string) =>
  string.replace(/<\/?p[^>]*>/g, "");

/**
 * Parse and replace same site links (anchors tags with href="https://account.narrative.so...") with Next.js <Link> element
 */
export const replaceLinksInHtmlString = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.name === "a" &&
        domNode.attribs?.href
      ) {
        const url = new URL(domNode.attribs.href);
        const { hostname, pathname, search, hash } = url;
        if (hostname === "account.narrative.so") {
          const { href, ...props } = domNode.attribs;
          return (
            <Link href={{ pathname, search, hash }}>
              <a {...attributesToProps(props)}>
                {domToReact(domNode.children)}
              </a>
            </Link>
          );
        }
      }
    },
  });
