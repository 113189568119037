import classNames from "classnames";

type Props = {
  backgroundClassName?: string;
};

const Or: React.FC<Props> = ({ backgroundClassName = "bg-off-white" }) => (
  <div className="relative my-7.5 border-t border-cinder-block">
    <div
      className={classNames(
        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 text-10 font-semibold uppercase text-shady-lady tracking-wider",
        backgroundClassName
      )}
    >
      Or
    </div>
  </div>
);

export default Or;
