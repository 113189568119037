import { IS_PRODUCTION } from "@/config";
import { PageLoad, Metadata } from "@/events";
import { queryClient } from "@/providers";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const JWT_KEY = "narrative-session-token";
const REFRESH_KEY = "narrative-refresh-token";

// Cookie life span in days
const LIFE_SPAN = 90;

const AuthHelper = {
  initialize(accessToken: string, refreshToken: string) {
    AuthHelper.setToken(accessToken);
    AuthHelper.setRefreshToken(refreshToken);
  },

  getToken() {
    return Cookies.get(JWT_KEY);
  },

  getRefreshToken() {
    return Cookies.get(REFRESH_KEY);
  },

  setToken(token: string) {
    if (IS_PRODUCTION) {
      Cookies.set(JWT_KEY, token, {
        expires: LIFE_SPAN,
        domain: ".narrative.so",
      });
    } else {
      Cookies.set(JWT_KEY, token, { expires: LIFE_SPAN });
    }
  },

  setRefreshToken(token: string) {
    Cookies.set(REFRESH_KEY, token);
  },

  deleteTokens() {
    if (IS_PRODUCTION) {
      Cookies.remove(JWT_KEY, { domain: ".narrative.so" });
    } else {
      Cookies.remove(JWT_KEY);
    }
    Cookies.remove(REFRESH_KEY);
  },

  isToken() {
    return !!this.getToken();
  },

  getAuthHeader() {
    return { Authorization: `Bearer ${this.getToken()}` };
  },

  getUserId() {
    const jwt = this.getToken();
    if (jwt) {
      return this.getUserIdFromJwt(jwt);
    }
  },

  getUserIdFromJwt(jwt: string) {
    const jwtDecoded = jwt_decode<{ sub: string }>(jwt);
    return jwtDecoded.sub.split(":").pop();
  },

  logout() {
    this.deleteTokens();
    PageLoad.id = "";
    Metadata.metadata = null;
    queryClient.clear();
  },
};

export default AuthHelper;
