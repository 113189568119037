import withAuthRedirect from "./withAuthRedirect";
import { ROUTES } from "@/config";
import { NextPage } from "next";

/**
 * Require the user to be authenticated in order to render the component.
 * If the user isn't authenticated, forward to the given URL.
 */
export default function withAuth<P>(
  WrappedComponent: NextPage<P>,
  location = `/${ROUTES.LOGIN.SLUG}`
) {
  return withAuthRedirect<P>({
    WrappedComponent,
    location,
    isAuthRequired: true,
  });
}
